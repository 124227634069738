import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyHtmlValueCheck'
})
export class EmptyHtmlValueCheckPipe implements PipeTransform {

  formattedValue: string;

  transform(value: any, condition?: boolean): string {
    if (condition) {
      this.formattedValue = value ? value.replace(/(<([^>]+)>)/gi, '').replace(/\s/g, '') : '';
      return this.formattedValue === '' ? null : value;
    }
    this.formattedValue = value ? value.replace(/(<([^>]+)>)/gi, '').replace(/\s/g, '') : '';
    return this.formattedValue === '' ? '-' : value;
  }

}
