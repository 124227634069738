import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';
import { DefaultValuesService } from './default-values.service';

@Injectable({ providedIn: 'root' })
export class SchoolSetupGuard implements CanActivate {
    constructor(
        private router: Router,
        private defaultValueService: DefaultValuesService,
        private locationStrategy: LocationStrategy
    ) { }

    canActivate(): boolean {
        if (this.defaultValueService.getSchoolSetupProgress()) {
          this.router.navigate(['/school', 'error', '404']);
          return false;
        }
        return true;
      }
}