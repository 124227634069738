import { extension } from "showdown";
import { CommonField } from "./common-field.model";
import { PermissionGroup } from "./roll-based-access.model";

export class GetMoodleVersionInfoModel {
    wsToken: string;
    moodlewsrestformat: string = 'json';
    wsfunction: string = 'core_webservice_get_site_info';
}

export class MoodleSSOModel {
    ssoToken: string;
    moodlewsrestformat: string = 'json';
    wsfunction: string = 'auth_userkey_request_login_url';
    user: string;
}

export class GetMoodleConfigModel extends CommonField {
    moodleConfigMast: {
        schoolId: number,
        tenantId: string,
        moodleUrl?: string,
        wsToken?: string,
        ssoToken?: string,
        moodleSync?: boolean
    };
}

export class ModifyMoodleConfigModel extends CommonField {
    moodleConfigMast: {
        tenantId: string,
        schoolId: number,
        moodleId?: number,
        moodleUrl: string,
        wsToken: string,
        ssoToken: string,
        moodleSync?: boolean,
        schoolSync?: boolean,
        createdBy?: any,
        createdOn?: Date
        updatedBy?: any,
        updatedOn?: Date
    };
    schoolSyncName?: string;
    schoolAcronym?: string;
    moodleException?: any;
}

export class AddUserInMoodle extends CommonField {
    tenantId: string;
    schoolId: number;
    email: string;
    firstname: string;
    lastname: string;
    username: string;
    password: string;
    idnumber: number | string;
    auth: string;
    // roleid: number;
    archeType: string;
    createdBy: string;
}

export class AddCoursesInMoodle extends CommonField {
    tenantId: string;
    schoolId: number;
    courseId: number;
    courseSectionId: number;
    coursesectionName: string;
    coursesectionShortName?: string;
    createdBy: string;
}

export class GetUsersMoodleCourses extends CommonField {
    tenantId: string;
    schoolId: number;
    userId: number;
}

export class GetEnrolledUsersMoodleCourses extends CommonField {
    tenantId: string;
    schoolId: number;
    courseId: number;
}

export class GetMoodleFinalGrades extends CommonField {
    tenantId: string;
    schoolId: number;
    courseSectionMoodleId: number;
}

export class AddMoodleAssignments extends CommonField {
    tenantId: string;
    schoolId: number;
    courseSectionMoodleId: number;
    courseSectionId: number;
    academicYear: number | string;
    staffId: number;
    markingPeriodStartDate: string;
    markingPeriodEndDate: string;
    createdBy: string;
}

export class GetMoodlUserGradeReport extends CommonField {
    tenantId: string;
    schoolId: number;
    courseSectionMoodleId: number;
    courseSectionId: number;
    academicYear: number | string;
    staffId: number;
    markingPeriodStartDate: string;
    markingPeriodEndDate: string;
    createdBy: string;
}

export class GetMoodleUsersRoleModel extends CommonField {
    tenantId: string;
    schoolId: number;
    archeType: string;
    roles?: any;
    moodleException?: any;
    constructor() {
        super();
        this.archeType = "";
    }
}

export class GetMoodleUsersInfoModel extends CommonField {
    tenantId: string;
    schoolId: number;
    archeType: string;
    createdBy: string;
    moodleException: any;
    userInfoViews: [];
    duplicates: [];
}

export class GetMoodleCourseInfoModel extends CommonField {
    createdBy: string;
    startdate: string;
    moodleException: any;
    courseInfoList: [];
    courseSectionErrors: [];
}

export class GetMoodleUserSchedulingModel extends CommonField {
    createdBy: string;
    archeType: string;
    moodleException: any;
    userSchedullingInfoList: [];
    userSchedullingErrors: [];
}

export class GetUserCategoriesAndFieldsModel extends CommonField {
    tenantId: string;
    schoolId: number;
    module: string;
    userInfoCategoryList?: MoodleCategoryAndFieldsListModel[];
    moodleException?: any;
    customFieldList?: CustomFieldModel[];
}

export class CustomFieldModel {
    tenantId: string;
    schoolId: number;
    fieldId: number;
    module: string;
    type: string;
    search: boolean;
    fieldName:string;
    title: string;
    isSystemWideField: boolean;
    sortOrder: number;
    selectOptions: string;
    categoryId: number;
    systemField: boolean;
    required: boolean;
    defaultSelection: string;
    hide: boolean;
    createdBy: string;
    createdOn: string;
    updatedOn: string;
    updatedBy: string;
    customFieldCode: string;
    customFieldsValue: [];
    customFieldMoodleShortName: string;
    fieldsCategory: any;
    schoolMaster: any;
    applicationFormFields: [];
}

export class MoodleCategoryAndFieldsListModel {
    caegoryId: number;
    categoryName: string;
    categoryFields: MoodleFieldsListModel[]
}

export class MoodleFieldsListModel {
    fieldId: number;
    fieldShortName: string;
    fieldName: string;
    fieldLocked: number;
}

export class EditCustomFieldMoodleMappingModel extends CommonField {
    tenantId: string;
    schoolId: number;
    module: string;
    customFieldList: CustomFieldModel[];
}

export class GetMoodlePageUrlModel extends CommonField {
    userEmail: string;
    courseSectionMoodleId: number;
    moodleResultViewModel?: MoodleResultViewModel;
}

export class MoodleResultViewModel {
    loginurl: string;
    debuginfo;
}