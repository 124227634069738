import { CommonField } from "./common-field.model";

export class Notifications {
    public _failure: boolean;
    public _message: string;
    public _tenantName: string;
    public featureReleaseList: [];
    public releaseDetailId?: number
    constructor() {
    }
}

export class GeneralNotifications extends CommonField {
    tenantId: string;
    schoolId: number | string;
    userId: number | string;
    profileType: string;
    pageResult: PageResultModel;
    constructor() {
        super();
        this.pageResult = new PageResultModel();
    }
}

export class PageResultModel {
    pageNumber: number;
    pageSize: number;
}

export class UpdateNotificationModel extends CommonField {
    tenantId: string;
    schoolId: string | number;
    userId: number | string;
    profileType: string;
    notificationId?: number;
    notificationList?: NotificationListModel[];
    constructor(){
        super();
        this.notificationList = [new NotificationListModel];
    }
}

export class NotificationListModel {
    isDeleted: boolean;
    isPinned: boolean;
}
