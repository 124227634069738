<div *ngIf="items>0" [ngClass.gt-sm]="['mr-6']" class="card overflow-auto" fxLayout="row" fxLayout.lt-md="column">
  <div class="p-gutter" fxFlex="400px" fxFlex.lt-md="auto">
    <!-- <h3 class="body-2 m-0">FEATURES</h3> -->

    <div class="mt-4" gdColumns="1fr 1fr 1fr" gdGap="16px">
      <ng-container *ngFor="let feature of features">
        <a (click)="close(); schoolService.setSchoolId(undefined); defaultValueService.setSchoolID(undefined)"
        *ngIf="feature?.isActive"
        [routerLink]="feature.route"
        [state]="stateObj"
        class="text-dark p-3 text-center hover:bg-hover hover:text-primary trans-ease-out rounded block no-underline">
       <ic-icon [icon]="feature.icon" class="text-primary" size="32px"></ic-icon>
       <div class="body-1 mt-2">{{ feature.label | translate}}</div>
     </a>
      </ng-container>

    </div>
    <div *ngIf="defaultValueService.getUserMembershipType() === 'Super Administrator' || defaultValueService.getUserMembershipType() === 'School Administrator'">
      <h5 class="separator-title"><span>Data Import</span></h5>
      <div class="grid grid-cols-3 gap-4 mt-4">
          <a href="school/tools/student-bulk-data-import" class="text-dark p-3 text-center hover:bg-hover hover:text-primary trans-ease-out rounded block no-underline"><ic-icon [icon]="icStudents" class="text-primary" size="32px"></ic-icon><div class="body-1 mt-2">{{'studentBulkDataImport' | translate}}</div></a>
          <a href="school/tools/staff-bulk-data-import" class="text-dark p-3 text-center hover:bg-hover hover:text-primary trans-ease-out rounded block no-underline"><ic-icon [icon]="icStaff" class="text-primary" size="32px"></ic-icon><div class="body-1 mt-2">{{'staffBulkDataImport' | translate}}</div></a>
      </div>
    </div>
  </div>

  <!-- <div class="p-gutter" fxFlex="350px" fxFlex.lt-md="auto">
    <h3 class="body-2 m-0">PAGES</h3>

    <div class="mt-6" gdColumns="1fr 1fr" gdGap="16px 48px">
      <a (click)="close()"
         *ngFor="let page of pages"
         [routerLink]="page.route"
         class="text-dark body-1 no-underline hover:text-primary trans-ease-out">{{ page.label }}</a>
    </div>
  </div> -->
</div>

