import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DefaultValuesService } from '../common/default-values.service';
import { GeneralNotifications, Notifications, UpdateNotificationModel } from '../models/notifications.model';
import { ConnectUserInSignalRModel } from '../models/signalr-connection-info.model';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  showNotificationsPanel = new Subject();
  apiUrl: string = environment.apiURL;
  httpOptions: { headers: any; };
  tenant: any;

  constructor(private http: HttpClient, private defaultValuesService: DefaultValuesService) {
    this.httpOptions = {
      headers: new HttpHeaders({
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
      })
    }
  }

  GetAllNotifications(obj: Notifications) {
    obj= this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName +"/Common/getAllReleasedFeature";
    return this.http.post<Notifications>(apiurl, obj, this.httpOptions)
  }

  getAllGeneralNotifications(obj: GeneralNotifications) {
    obj= this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName +"/Notification/getAllNotification";
    return this.http.post<GeneralNotifications>(apiurl, obj, this.httpOptions)
  }  

  updateNotification(obj: UpdateNotificationModel) {
    obj= this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName +"/Notification/updateNotification";
    return this.http.post<UpdateNotificationModel>(apiurl, obj, this.httpOptions)
  }

  connectUserInSignalR(obj: ConnectUserInSignalRModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/User/connectUserInSignalR";
    return this.http.post<ConnectUserInSignalRModel>(apiurl, obj, this.httpOptions)
  }

}
