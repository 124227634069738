<form [formGroup]="form">
    <div mat-dialog-title class="flex justify-between items-center">
        <div class="md:flex justify-between items-start flex-auto">
            <h4 class="font-normal text-xl m-0">
                {{ "composeMessage" | translate }}
            </h4>
            <div class="mr-4 text-sm font-normal" *ngIf="membershipType !== profiles.Student && membershipType !== this.profiles.Parent">
                <mat-slide-toggle class="align-middle" color="primary" formControlName="sendAsEmail" (change)="sendEmailToggleChange()">{{ 'sendThisAsEmailAlso' | translate }}</mat-slide-toggle>
            </div>
        </div>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>
    
    <mat-dialog-content class="mb-1">
    
        <div class="relative">
            <div class="text-primary font-semibold absolute z-10 right-4 top-4">
                <span class="cursor-pointer" (click)="toggleCcDropdown()">{{ 'cc' | translate }}</span><span class="mx-1">/</span><span class="cursor-pointer" (click)="toggleBccDropdown()">{{ 'bcc' | translate }}</span>
            </div>
            <!-- <div class="search-wrapper flex flex-wrap">
                <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                    <p>Basketball Players 2022</p>
                    <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                </div>
                <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                    <p>Grade 9 Students</p>
                    <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                </div>
                <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                    <p>Grade 9 Parents</p>
                    <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                </div>
                <div class="border border-normal rounded-full px-3 py-1 inline-flex items-center mr-3 mb-2">
                    <p>Michel Johnson</p>
                    <button class="ml-3"><mat-icon [icIcon]="icCancel" class="w-auto h-auto text-base align-middle"></mat-icon></button>
                </div>
                <input type="text" placeholder="{{ 'searchRecipients' | translate }}" class="mr-2 mb-2 text-sm" (click)="onSearchRecipient()">
            </div> -->
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'to' | translate }}</mat-label>
                <mat-chip-list #toChipList>
                    <mat-chip *ngFor="let toRecipient of composeMessageDefaultData[0].recipientInfoViewModels" [selectable]="selectable" [removable]="removable"
                        (removed)="removeRecipientData(toRecipient, 'isTo')">
                        {{toRecipient.title}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input type="text" [formControl]="toSearchCtrl" placeholder="{{ 'searchRecipients' | translate }}"
                        [matChipInputFor]="toChipList" #toChipListInput [matAutocomplete]="toAuto"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
                </mat-chip-list>
                <mat-autocomplete #toAuto="matAutocomplete" (optionSelected)="addToUserValue($event)">
                    <mat-option *ngFor="let user of toUserList" [value]="user">
                        {{user.firstGivenName}} {{user.middleName ? ' '+user.middleName+' ': ' '}} {{user.lastFamilyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    
        <!-- <div class="flex mb-4" *ngIf="groupList.length && membershipType !== profiles.Student && membershipType !== profiles.Parent">
            <p class="font-semibold ">{{ 'groups' | translate }}:</p>
            <div class="flex flex-wrap ml-2" *ngFor="let group of groupList">
                <p class="text-blue underline cursor-pointer mr-2" (click)="addRecipientData(group, 'groupList', 'isTo')">{{group.formattedName}}</p>
            </div>
        </div> -->
    
        <div class="relative" *ngIf="showCc">
            
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'cc' | translate }}</mat-label>
                <mat-chip-list #ccChipList>
                    <mat-chip *ngFor="let ccRecipient of composeMessageDefaultData[1].recipientInfoViewModels" [selectable]="selectable" [removable]="removable"
                        (removed)="removeRecipientData(ccRecipient, 'isCC')">
                        {{ccRecipient.title}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input type="text" [formControl]="ccSearchCtrl" placeholder="{{ 'searchRecipients' | translate }}"
                        [matChipInputFor]="ccChipList" #ccChipListInput [matAutocomplete]="ccAuto"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
                </mat-chip-list>
                <mat-autocomplete #ccAuto="matAutocomplete" (optionSelected)="addCCUserValue($event)">
                    <mat-option *ngFor="let user of ccUserList" [value]="user">
                        {{user.firstGivenName}} {{user.middleName ? ' '+user.middleName+' ': ' '}} {{user.lastFamilyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    
        <!-- <div class="flex mb-4" *ngIf="showCc">
            <p *ngIf="groupList.length" class="font-semibold ">{{ 'groups' | translate }}:</p>
            <div class="flex flex-wrap ml-2" *ngFor="let group of groupList">
                <p class="text-blue underline cursor-pointer mr-2" (click)="addRecipientData(group, 'groupList', 'isCC')">{{group.formattedName}}</p>
            </div>
        </div> -->
    
        <div class="relative" *ngIf="showBcc">            
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>{{ 'bcc' | translate }}</mat-label>
                <mat-chip-list #bccChipList>
                    <mat-chip *ngFor="let bccRecipient of composeMessageDefaultData[2].recipientInfoViewModels" [selectable]="selectable" [removable]="removable"
                        (removed)="removeRecipientData(bccRecipient, 'isBcc')">
                        {{bccRecipient.title}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input type="text" [formControl]="bccSearchCtrl" placeholder="{{ 'searchRecipients' | translate }}"
                        [matChipInputFor]="bccChipList" #bccChipListInput [matAutocomplete]="bccAuto"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
                </mat-chip-list>
                <mat-autocomplete #bccAuto="matAutocomplete" (optionSelected)="addBccUserValue($event)">
                    <mat-option *ngFor="let user of bccUserList" [value]="user">
                        {{user.firstGivenName}} {{user.middleName ? ' '+user.middleName+' ': ' '}} {{user.lastFamilyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    
        <!-- <div class="flex mb-4" *ngIf="showBcc">
            <p *ngIf="groupList.length" class="font-semibold ">{{ 'groups' | translate }}:</p>
            <div class="flex flex-wrap ml-2" *ngFor="let group of groupList">
                <p class="text-blue underline cursor-pointer mr-2" (click)="addRecipientData(group, 'groupList', 'isBcc')">{{group.formattedName}}</p>
            </div>
        </div> -->
          
        
        <mat-form-field appearance="outline" color="primary" class="w-full">
            <mat-label>{{ 'subject' | translate }}</mat-label>
            <input formControlName="subject" matInput>
            <mat-error *ngIf="form.get('subject').hasError('maxlength')">{{defaultValuesService.maxLengthValidationErrMsg(form.controls.subject.errors?.maxlength.requiredLength, form.controls.subject.errors?.maxlength.actualLength)}}</mat-error>
        </mat-form-field>
        
        <div class="mb-4 border border-normal rounded">
            <quill-editor formControlName="body" (onEditorChanged)="changedEditor($event)"
                placeholder="{{ 'insertTextHere' | translate }}" [styles]="{ height: '200px' }">
            </quill-editor>
        </div>
    
        <div class="px-4 py-2 border-dashed border-gray border rounded">
            <div class="mb-2">
                <p class="flex items-center"><mat-icon class="align-middel mr-2">attachment</mat-icon>{{ 'attachments' | translate }}</p>
            </div>
            <ngx-dropzone (change)="onUpload($event)" class="flex flex-wrap border-0 h-auto  overflow-hidden pb-2"
                [accept]="acceptedFileTypes" multiple="true">
                <ngx-dropzone-label class="sm:flex" *ngIf="files?.length === 0">
                    <img src="assets/img/icons/upload.svg" alt="Upload file icon" width="40px"
                        class="m-auto flex-shrink-0 cursor-pointer">
                    <p class="ml-4 align-middle sm:text-left mt-4 sm:mt-0">
                        <span class="text-base font-medium mb-0 inline-block text-black">{{
                            'uploadFileFromYourLocalDrive' | translate }}</span>
                        <br>
                        <a class="text-gray text-sm">{{ 'clickHereToSelectaFileOrDropTheFileHere' |
                            translate }}</a>
                    </p>
                </ngx-dropzone-label>
            
                <div class="inline-block border border-gray-light rounded px-2 py-1 mr-2 mb-2" *ngFor="let f of files">
                    <a (click)="$event.stopPropagation()">
                        <div class="flex items-center truncate">
                            <div>
                                <img [src]="'assets/img/file-' + f.extensionName + '.svg'" onError="this.src='assets/img/file-others.svg'"
                                    alt="Excel Icon" width="20" class="inline-block mr-2 disabled"><span class="w-20 text-sm truncate">{{f.title}}</span>
                            </div>
                            <div class="cursor-pointer pl-1" (click)="onRemove(f); $event.preventDefault(); $event.stopPropagation()">
                                <mat-icon [icIcon]="icCancel" class="align-middle w-auto h-auto pl-2 text-lg"></mat-icon>
                            </div>
                        </div>
                    </a>
                </div>
            </ngx-dropzone>
        </div>
    
    </mat-dialog-content>
    <mat-dialog-actions class="py-4 flex justify-end">
        <button mat-dialog-close mat-flat-button>{{ 'cancel' | translate }}</button>
        <button color="primary" mat-flat-button type="button" class="text-white ml-2" (click)="addComposeMessage()">{{ 'submit' |translate }}</button>
    </mat-dialog-actions>
</form>