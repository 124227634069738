import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { EncryptionTokens } from 'src/app/enums/security.enum';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  encryptionTokens = EncryptionTokens;
  
constructor() { }

zohoCrypto(){
  return CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(16));
}

//The set method is use for encrypt the value.
encrypt(value){
  const keySize = 256;
    const keys = this.encryptionTokens.encryptionKey;
    const salt = CryptoJS.lib.WordArray.random(16); const key = CryptoJS.PBKDF2(keys, salt, {
    keySize: keySize/32,
    iterations: 100
    }); const iv = CryptoJS.lib.WordArray.random(128/8);
    let encrypted = CryptoJS.AES.encrypt(value, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });


  
   const result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));


  return result;
}

decrypt (cipherText) {
  const transitmessage = CryptoJS.enc.Base64.parse(cipherText);

  
  const keySize = 256;
  const keys = this.encryptionTokens.encryptionKey;

  var salt = CryptoJS.enc.Hex.parse(transitmessage.slice(0, 32));

  
  var iv = CryptoJS.enc.Hex.parse(transitmessage.slice(32, 64))
  var encrypted = transitmessage.slice(64);
  
  var key = CryptoJS.PBKDF2(keys, salt, {
      keySize: keySize/32,
      iterations: 100
    });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
    iv: iv, 
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
    
  })

  // return decrypted.toString(CryptoJS.enc.Utf8);
}

// function encrypt (msg, pass) {
//   var salt = CryptoJS.lib.WordArray.random(128/8);
  
//   var key = CryptoJS.PBKDF2(pass, salt, {
//       keySize: keySize/32,
//       iterations: iterations
//     });

//   var iv = CryptoJS.lib.WordArray.random(128/8);
  
//   var encrypted = CryptoJS.AES.encrypt(msg, key, { 
//     iv: iv, 
//     padding: CryptoJS.pad.Pkcs7,
//     mode: CryptoJS.mode.CBC
    
//   });
  
//   // salt, iv will be hex 32 in length
//   // append them to the ciphertext for use  in decryption
//   var transitmessage = salt.toString()+ iv.toString() + encrypted.toString();
//   return transitmessage;
// }

//The get method is use for decrypt the value.
// decrypt(value){
//   // const keys = environment.encryptionKey;
//   // const iv= environment.encryptioniv;
  

//   // const bytes  = CryptoJS.AES.decrypt(value.toString(), keys);
//   // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//   // return decryptedData;

//   let _key = environment.encryptionKey
//   let _iv = CryptoJS.enc.Utf8.parse(value)

//   var decrpted = CryptoJS.AES.decrypt(value, _key, {
//     iv: _iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   })

//   return decrpted
// }

dataEncrypt(value) {

  const key = CryptoJS.enc.Utf8.parse(this.encryptionTokens.dataEncryptionKey);
  const iv = CryptoJS.enc.Utf8.parse(this.encryptionTokens.dataEncryptionKey)

  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  return encrypted.toString();
}

dataDecrypt(value) {
  if(value){
    const key = CryptoJS.enc.Utf8.parse(this.encryptionTokens.dataEncryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(this.encryptionTokens.dataEncryptionKey);
    const decrypted = CryptoJS.AES.decrypt(value?.toString(), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

jsonEncrypt(ciphertext){
  if(ciphertext){
    const key = CryptoJS.enc.Utf8.parse(this.encryptionTokens.jsonEncryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(this.encryptionTokens.jsonEncryptionKey)
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(ciphertext), key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted;
  }
}

jsonDecrypt(ciphertext){
  if(ciphertext){
    var key = CryptoJS.enc.Utf8.parse(this.encryptionTokens.jsonEncryptionKey);                             // Convert into WordArray (using Utf8)
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);   // Use zero vector as IV
    var decrypted = CryptoJS.AES.decrypt(ciphertext, key, {iv: iv}); // By default: CBC, PKCS7 
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
}
