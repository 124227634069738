import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import { ProfilesTypes } from 'src/app/enums/profiles.enum';
import { DefaultValuesService } from '../default-values.service';
import icClose from '@iconify/icons-ic/twotone-close';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-plan-upgrade',
  templateUrl: './plan-upgrade.component.html',
  styleUrls: ['./plan-upgrade.component.scss']
})
export class PlanUpgradeComponent implements OnInit {
  icCheckCircle = icCheckCircle;
  icClose = icClose;
  profiles = ProfilesTypes;
  membership;

  constructor(
    public translateService: TranslateService,
    private dialogRef: MatDialogRef<PlanUpgradeComponent>,
    private router: Router,
    public defaultValuesService: DefaultValuesService
  ) { }

  ngOnInit(): void {
    this.membership = this.defaultValuesService.getUserMembershipType();
  }

  upgradeNow() {
    this.onClose();
    this.router.navigate(['/school/my-subscription']);
  }

  onClose() {
    this.dialogRef.close();
  }

}
