<!DOCTYPE html>
<html>
  <head>
    <META HTTP-EQUIV='content-type' CONTENT='text/html; charset=UTF-8'>
    <script src='https://d17nz991552y2g.cloudfront.net/app/js/jqueryandencoder.ffa5afd5124fbedceea9.js'></script>
    <script>
      function trimBoth(str) {
        return jQuery.trim(str);
      }

      function setAllDependancyFieldsMapping() {
        var mapDependancyLabels = getMapDependenySelectValues(jQuery("[id='property(module)']").val(), "JSON_MAP_DEP_LABELS");
        if (mapDependancyLabels) {
          for (var i = 0; i < mapDependancyLabels.length; i++) {
            var label = mapDependancyLabels[i];
            var obj = document.forms['zsWebToCase_748995000000441001'][label];
            if (obj) {
              setDependent(obj, true);
            }
          }
        }
      }

      function getMapDependenySelectValues(module, key) {
        var dependencyObj = jQuery.parseJSON(jQuery("[id='dependent_field_values_" + module + "']").val());
        if (dependencyObj == undefined) {
          return dependencyObj;
        }
        return dependencyObj[key];
      }

      function setDependent(obj, isload) {
        var name = obj.id || (obj[0] && obj[0].id) || "";
        var module = jQuery("[id='property(module)']").val();
        var val = "";
        var myObject = getMapDependenySelectValues(module, "JSON_VALUES");
        if (myObject != undefined) {
          val = myObject[name];
        }
        var mySelObject = getMapDependenySelectValues(module, "JSON_SELECT_VALUES");
        if (val != null && val != "" && val != "null" && mySelObject) {
          var fields = val;
          for (var i in fields) {
            if (fields.hasOwnProperty(i)) {
              var isDependent = false;
              var label = i;
              var values = fields[i];
              if (label.indexOf(")") > -1) {
                label = label.replace(/\)/g, '_____');
              }
              if (label.indexOf("(") > -1) {
                label = label.replace(/\(/g, '____');
              }
              if (label.indexOf(".") > -1) {
                label = label.replace(/\./g, '___');
              }
              var depObj = document.forms['zsWebToCase_748995000000441001'][label];
              if (depObj && depObj.options) {
                var mapValues = "";
                var selected_val = depObj.value;
                var depLen = depObj.options.length - 1;
                for (var n = depLen; n >= 0; n--) {
                  if (depObj.options[n].selected) {
                    if (mapValues == "") {
                      mapValues = depObj.options[n].value;
                    } else {
                      mapValues = mapValues + ";;;" + depObj.options[n].value;
                    }
                  }
                }
                depObj.value = "";
                var selectValues = mySelObject[label];
                for (var k in values) {
                  var rat = k;
                  if (rat == "-None-") {
                    rat = "";
                  }
                  var parentValues = mySelObject[name];
                  if (rat == trimBoth(obj.value)) {
                    isDependent = true;
                    depObj.length = 0;
                    var depvalues = values[k];
                    var depLen = depvalues.length - 1;
                    for (var j = 0; j <= depLen; j++) {
                      var optionElement = document.createElement("OPTION");
                      var displayValue = depvalues[j];
                      var actualValue = displayValue;
                      if (actualValue == "-None-") {
                        optionElement.value = "";
                        displayValue = "-None-";
                      } else {
                        optionElement.value = actualValue;
                      }
                      optionElement.text = displayValue;
                      if (mapValues != undefined) {
                        var mapValue = mapValues.split(";;;");
                        var len = mapValue.length;
                        for (var p = 0; p < len; p++) {
                          if (actualValue == mapValue[p]) {
                            optionElement.selected = true;
                          }
                        }
                      }
                      depObj.options.add(optionElement);
                    }
                  }
                }
                if (!isDependent) {
                  depObj.length = 0;
                  var len = selectValues.length;
                  for (var j = 0; j < len; j++) {
                    var actualValue = selectValues[j];
                    var optionElement = document.createElement("OPTION");
                    if (actualValue == "-None-") {
                      optionElement.value = "";
                    } else {
                      optionElement.value = selectValues[j];
                    }
                    optionElement.text = selectValues[j];
                    depObj.options.add(optionElement);
                  }
                  depObj.value = selected_val;
                }
                if (!isload) {
                  setDependent(depObj, false);
                }
                var jdepObj = jQuery(depObj);
                if (jdepObj.hasClass('select2-offscreen')) {
                  jdepObj.select2("val", jdepObj.val());
                }
              }
            }
          }
        }
      }
      var zctt = function() {
        var tt, mw = 400,
          top = 10,
          left = 0,
          doctt = document;
        var ieb = doctt.all ? true : false;
        return {
          showtt: function(cont, wid) {
            if (tt == null) {
              tt = doctt.createElement('div');
              tt.setAttribute('id', 'tooltip-zc');
              doctt.body.appendChild(tt);
              doctt.onmousemove = this.setpos;
              doctt.onclick = this.hidett;
            }
            tt.style.display = 'block';
            tt.innerHTML = cont;
            tt.style.width = wid ? wid + 'px' : 'auto';
            if (!wid && ieb) {
              tt.style.width = tt.offsetWidth;
            }
            if (tt.offsetWidth > mw) {
              tt.style.width = mw + 'px'
            }
            h = parseInt(tt.offsetHeight) + top;
            w = parseInt(tt.offsetWidth) + left;
          },
          hidett: function() {
            tt.style.display = 'none';
          },
          setpos: function(e) {
            var u = ieb ? event.clientY + doctt.body.scrollTop : e.pageY;
            var l = ieb ? event.clientX + doctt.body.scrollLeft : e.pageX;
            var cw = doctt.body.clientWidth;
            var ch = doctt.body.clientHeight;
            if (l < 0) {
              tt.style.left = left + 'px';
              tt.style.right = '';
            } else if ((l + w + left) > cw) {
              tt.style.left = '';
              tt.style.right = ((cw - l) + left) + 'px';
            } else {
              tt.style.right = '';
              tt.style.left = (l + left) + 'px';
            }
            if (u < 0) {
              tt.style.top = top + 'px';
              tt.style.bottom = '';
            } else if ((u + h + left) > ch) {
              tt.style.top = '';
              tt.style.bottom = ((ch - u) + top) + 'px';
            } else {
              tt.style.bottom = '';
              tt.style.top = (u + top) + 'px';
            }
          }
        };
      }();
      var zsWebFormMandatoryFields = new Array("First Name", "Contact Name", "Email", "openSIS URL", "Subject", "Description");
      var zsFieldsDisplayLabelArray = new Array("First Name", "Last Name", "Email", "openSIS URL", "Reason for Cancellation", "Comments");

      function zsValidateMandatoryFields() {
        var name = '';
        var email = '';
        var isError = 0;
        for (var index = 0; index < zsWebFormMandatoryFields.length; index++) {
          isError = 0;
          var fieldObject = document.forms['zsWebToCase_748995000000441001'][zsWebFormMandatoryFields[index]];
          if (fieldObject) {
            if (((fieldObject.value).replace(/^\s+|\s+$/g, '')).length == 0) {
              alert(zsFieldsDisplayLabelArray[index] + ' cannot be empty ');
              fieldObject.focus();
              isError = 1;
              return false;
            } else {
              if (fieldObject.name == 'Email') {
                if (!fieldObject.value.match(/^([\w_][\w\-_.+\'&]*)@(?=.{4,256}$)(([\w]+)([\-_]*[\w])*[\.])+[a-zA-Z]{2,22}$/)) {
                  isError = 1;
                  alert('Enter a valid email-Id');
                  fieldObject.focus();
                  return false;
                }
              }
            }
            if (fieldObject.nodeName == 'SELECT') {
              if (fieldObject.options[fieldObject.selectedIndex].value == '-None-') {
                alert(zsFieldsDisplayLabelArray[index] + ' cannot be none');
                fieldObject.focus();
                isError = 1;
                return false;
              }
            }
            if (fieldObject.type == 'checkbox') {
              if (fieldObject.checked == false) {
                alert('Please accept ' + zsFieldsDisplayLabelArray[index]);
                fieldObject.focus();
                isError = 1;
                return false;
              }
            }
          }
        }
        if (isError == 0) {
          document.getElementById('zsSubmitButton_748995000000441001').setAttribute('disabled', 'disabled');
        }
      }
      document.addEventListener('readystatechange', function() {
        if (document.readyState === 'complete' && window.zsRegenerateCaptcha) {
          zsRegenerateCaptcha();
        }
        setAllDependancyFieldsMapping();
        document.getElementById('zsSubmitButton_748995000000441001').removeAttribute('disabled');
      });

      function zsResetWebForm(webFormId) {
        document.forms['zsWebToCase_' + webFormId].reset();
        document.getElementById('zsSubmitButton_748995000000441001').removeAttribute('disabled');
        setAllDependancyFieldsMapping();
      }
    </script>
  </head>
  <body>
    <div id='zohoSupportWebToCase' class="zoho-cancel-subscription-wrapper" align='center'>
      <div class="zoho-cancel-subscription-form shadow">
        <div class="form-header">
          <img src="assets/img/opensis_logo.png">
        </div>
        <div class="form-body">
          <form name='zsWebToCase_748995000000441001' id='zsWebToCase_748995000000441001' action='https://help.opensis.com/support/WebToCase' method='POST' onSubmit='return zsValidateMandatoryFields()' enctype='multipart/form-data'>
            <input type='hidden' name='xnQsjsdp' value='edbsnfe4ced82b749f6949c7d33dd5e621237' />
            <input type='hidden' name='xmIwtLD' value='edbsn6ca0ae60cc716477bd68952b43864e1c3e55d2e23956d7a6061252ad7c2e43f4' />
            <input type='hidden' name='xJdfEaS' value='' />
            <input type='hidden' name='actionType' value='Q2FzZXM=' />
            <input type="hidden" id="property(module)" value="Cases" />
            <input type="hidden" id="dependent_field_values_Cases" value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&#x5d;&#x7d;" />
            <input type='hidden' name='returnURL' value='https&#x3a;&#x2f;&#x2f;opensis.com' />
            <h3 class="text-center font-bold text-green">Hello! {{ defaultValueService.getTenantData().firstName }} {{ defaultValueService.getTenantData().lastName }}</h3>
            <h1 class="text-center mb-4">We're sad to see you go</h1>
            <p class="mb-3">All data related to the email address <span class="font-bold text-blue">{{ defaultValueService.getTenantData().workEmailId }}</span> and the subdomain <span class="font-bold text-blue">{{ websiteUrl }}</span> will be permanently deleted when you cancel your subscription.</p>
            <p class="mb-6">Would you mind sharing your feedback and the reason for your departure? It will help us make improvements to our system. I look forward to seeing you again in the future.</p>
            <input type='hidden' maxlength='120' name='First Name' [value]="defaultValueService.getTenantData().firstName" class='manfieldbdr' />
            <input type='hidden' maxlength='120' name='Contact Name' [value]="defaultValueService.getTenantData().lastName" class='manfieldbdr' />
            <input type='hidden' maxlength='120' name='Email' [value]="defaultValueService.getTenantData().workEmailId" class='manfieldbdr' />
            <input type='hidden' maxlength='120' name='openSIS URL' [value]="websiteUrl" class='manfieldbdr' />
            <div class="mb-6">
              <label class="text-left block mb-2 font-bold">Reason for Cancellation</label>
              <input type='text' maxlength='255' name='Subject' value='' class='manfieldbdr' required />
            </div>
            <div class="mb-6">
              <label class="text-left block mb-2 font-bold">Comments</label>
              <textarea name='Description' maxlength='3000' width='250' height='250' class='manfieldbdr'></textarea>
            </div>
            <div class="text-center"><input type='submit' id="zsSubmitButton_748995000000441001" class="bg-red text-white px-6 py-3 rounded cursor-pointer" value='Submit'> &nbsp; &nbsp; <input type='reset' class="bg-white text-gray px-6 py-3 rounded border border-gray-light cursor-pointer" value='Reset' onclick="zsResetWebForm('748995000000441001')"></div>
          </form>
        </div>
      </div>
    </div>
</body>
</html>