<div class="md:flex absolute bg-white z-10 w-full border border-normal shadow-md left-0 rounded">
    <div class="md:w-1/3 p-4">
        <ng-container *ngIf="membershipType === profiles.SuperAdmin || membershipType === profiles.SchoolAdmin || membershipType === profiles.AdminAssitant">
            <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Students in this School', 1)">
                <mat-icon [icIcon]="icSchool" class="align-middle mr-2"></mat-icon><span>{{ 'allStudentsInThisSchool' | translate }}</span>
            </div>
            <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Staff in this school', 2)">
                <mat-icon [icIcon]="icGroup" class="align-middle mr-2"></mat-icon><span>{{ 'allStaffInThisSchool' | translate }}</span>
            </div>
            <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Teachers in this School', 3)">
                <mat-icon [icIcon]="icPersonOutline" class="align-middle mr-2"></mat-icon><span>{{ 'allTeachersInThisSchool' | translate }}</span>
            </div>
            <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Parents in this school', 4)">
                <mat-icon [icIcon]="icEscalatorWarning" class="align-middle mr-2"></mat-icon><span>{{ 'allParentsInThisSchool' | translate }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="membershipType === profiles.Teacher || membershipType === profiles.HomeroomTeacher">
            <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Students associated with me', 5)">
                <mat-icon [icIcon]="icSchool" class="align-middle mr-2"></mat-icon><span>{{ 'allStudentsAssociatedWithMe' | translate }}</span>
            </div>
            <div class="flex cursor-pointer mb-3" (click)="sendAllInThisSchoolValue('All Parents associated with me', 6)">
                <mat-icon [icIcon]="icEscalatorWarning" class="align-middle mr-2"></mat-icon><span>{{ 'allParentsAssociatedWithMe' | translate }}</span>
            </div>
        </ng-container>
    </div>
    <div class="md:w-2/3 md:border-l md:border-normal">
        <mat-divider class="block md:hidden mx-4"></mat-divider>
        <div class="p-4" *ngIf="!isAllUserSearched">
            <ng-container *ngIf="membershipType === profiles.SuperAdmin || membershipType === profiles.SchoolAdmin || membershipType === profiles.AdminAssitant">
                <div>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="currentView">
                        <mat-radio-button [value]="'byGradeLevel'"  name="currentView" class="mr-6 mb-2" color="primary">{{ 'searchByGradeLevel' | translate }}</mat-radio-button>
                        <mat-radio-button [value]="'byCourseSection'"  name="currentView" class="mr-6 mb-2" color="primary">{{ 'searchByCourseSection' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="currentView === 'byGradeLevel'">
                    <div class="md:flex my-4">
                        <div class="mr-2">
                            <mat-form-field appearance="outline" class="w-full">
                                <mat-label>{{ 'gradeLevel' | translate }}</mat-label>
                                <mat-select [(ngModel)]="gradeLevelData.id">
                                    <mat-option *ngFor="let gradeLevel of gradeLevelList" [value]='gradeLevel.gradeId' (click)="selectGradeLevel(gradeLevel)">{{gradeLevel.title}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="flex md:flex-col md:ml-2">
                            <mat-checkbox class="mb-1 mr-6 md:mr-0" color="primary" [disabled]="allStudentsDisabled" [(ngModel)]="gradeLevelData.allStudents">{{ 'allStudents' | translate }}</mat-checkbox>
                            <mat-checkbox class="mb-1" color="primary" [disabled]="allParentsDisabled" [(ngModel)]="gradeLevelData.allParents">{{ 'allParents' | translate }}</mat-checkbox>
                        </div>
                    </div>
                </div>

                <div *ngIf="currentView === 'byCourseSection'">
                    <div class="my-4">
                        <div class="lg:flex flex-wrap">
                            <div class="mr-2">
                                <mat-form-field appearance="outline" class="w-full">
                                    <mat-label>{{ 'course' | translate }}</mat-label>
                                    <mat-select [(ngModel)]="selectedCourse" (selectionChange)="selectedCourseSection = 0; selectedCourseAndCourseSection()">
                                        <mat-option *ngFor="let course of courseList; let i = index" [value]="i">{{course.course.courseTitle}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="mr-2">
                                <mat-form-field appearance="outline" class="w-full">
                                    <mat-label>{{ 'courseSection' | translate }}</mat-label>
                                    <mat-select [(ngModel)]="selectedCourseSection" (selectionChange)="selectedCourseAndCourseSection()">
                                        <ng-container *ngIf="selectedCourse !== '' && selectedCourse !== undefined && selectedCourse !== null">
                                            <mat-option *ngFor="let courseSection of courseList[selectedCourse]?.course?.courseSection; let i = index;" [value]="i">{{courseSection.courseSectionName}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="lg:flex flex-wrap">
                            <mat-checkbox class="mb-2 mr-6" color="primary" [disabled]="allStudentsDisabled" [(ngModel)]="courseSectionData.allStudents">{{ 'allStudents' | translate }}</mat-checkbox>
                            <mat-checkbox class="mb-2 mr-6" color="primary" [disabled]="allParentsDisabled" [(ngModel)]="courseSectionData.allParents">{{ 'allParents' | translate }}</mat-checkbox>
                            <mat-checkbox class="mb-2 mr-6" color="primary" [disabled]="allTeachersDisabled" [(ngModel)]="courseSectionData.allTeacher">{{ 'allTeacher' | translate }}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="membershipType === profiles.Teacher || membershipType === profiles.HomeroomTeacher">
                <div class="mb-4">
                    <div class="lg:flex flex-wrap">
                        <div class="mr-2">
                            <mat-form-field appearance="outline" class="w-full">
                                <mat-label>{{ 'course' | translate }}</mat-label>
                                <mat-select [(ngModel)]="selectedCourse" (selectionChange)="selectedCourseSection = 0; selectedCourseAndCourseSection()">
                                    <mat-option *ngFor="let course of courseList; let i = index" [value]="i">{{course.course.courseTitle}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="mr-2">
                            <mat-form-field appearance="outline" class="w-full">
                                <mat-label>{{ 'courseSection' | translate }}</mat-label>
                                <mat-select [(ngModel)]="selectedCourseSection" (selectionChange)="selectedCourseAndCourseSection()">
                                    <ng-container *ngIf="selectedCourse !== '' && selectedCourse !== undefined && selectedCourse !== null">
                                        <mat-option *ngFor="let courseSection of courseList[selectedCourse]?.course?.courseSection; let i = index;" [value]="i">{{courseSection.courseSectionName}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <mat-checkbox class="mb-2 mr-6" color="primary" [(ngModel)]="courseSectionData.allStudents">{{ 'allStudents' | translate }}</mat-checkbox>
                        <mat-checkbox class="mb-2 mr-6" color="primary" [(ngModel)]="courseSectionData.allParents">{{ 'allParents' | translate }}</mat-checkbox>
                        <mat-checkbox class="mb-2 mr-6" color="primary" [(ngModel)]="courseSectionData.allTeacher">{{ 'allTeacher' | translate }}</mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <button mat-flat-button (click)="onCancel()">{{ 'cancel' | translate }}</button>
                <button color="primary" mat-flat-button type="button" class="text-white ml-2" (click)="addData()">{{ 'add' |translate }}</button>
            </div>
        </div>

        <!-- Start Search By Name -->
        <div class="h-48 overflow-y-auto p-4" *ngIf="isAllUserSearched">
            <div class="flex flex-col">
                <div class="flex justify-between items-center p-1 cursor-pointer" [class.disabled]="user.isDisabled" [class.text-gray-light]="user.isDisabled" *ngFor="let user of userList" (click)="sendUserValue(user)">
                    <div class="flex items-center">
                        <div class="w-8 h-8 overflow-hidden rounded-full mr-2">
                            <img *ngIf="user.thumbnailPhoto" [src]="'data:image/jpeg;base64,' + user.thumbnailPhoto" alt="Profile Photo" class="rounded-full">
                            <p *ngIf="!user.thumbnailPhoto" class="text-white text-center p-2 leading-tight" [ngClass]="user.bgColor">{{user.firstNameChar}}</p>
                        </div>
                        <p>{{user.firstGivenName}}{{user.middleName ? ' '+user.middleName+' ': ' '}}{{user.lastFamilyName}}</p>
                    </div>
                    <div>{{user.userProfile}}</div>
                </div>
            </div>
        </div>
        <!-- End Search By Name -->
    </div>
</div>