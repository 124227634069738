<div mat-dialog-title class="flex justify-between items-start mb-4">
    <div>
        <h2 class="headline text-xl font-medium mb-2">{{data[0].releaseTitle}}</h2>
        <p>{{data[0].releaseDate | date:defaultValuesService.getSchoolDateFormat()}}</p>
    </div>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</div>

<mat-dialog-content class="pt-4">
    <div class="mb-6 whitespace-pre-wrap" [innerHTML]="data[0].releaseDetail"></div>
</mat-dialog-content>