import { CommonField } from "./common-field.model";

export class AddCommGroupModel extends CommonField {
    commGroupMaster: CommGroupMasterModel;
    constructor() {
        super();
        this.commGroupMaster = new CommGroupMasterModel();
    }
}

export class UpdateCommGroupModel extends CommonField {
    commGroupMaster: CommGroupMasterModel;
    constructor() {
        super();
        this.commGroupMaster = new CommGroupMasterModel();
    }
}

export class DeleteCommGroupModel extends CommonField {
    commGroupMaster: CommGroupMasterModel;
    constructor() {
        super();
        this.commGroupMaster = new CommGroupMasterModel();
    }
}

export class CommGroupMasterModel {
    tenantId: string;
    schoolId: number;
    groupId: number;
    groupName: string;
    formattedGroupName: string; // For frontend use
    isActive: boolean;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
}

export class AddUserInGroupModel extends CommonField {
    commUserGroups: any[];
    constructor() {
        super();
        this.commUserGroups = [];
    }
}

export class GetCommMenuModel extends CommonField {
    commGroupMasters: CommGroupMasterModel[];
    constructor() {
        super();
    }
}

export class AllUsersFromGroupModel extends CommonField {
    commUserGroups: any[];
    groupId: number;
    totalCount: number;
    pageNumber: number;
    pageSize: number;
    studentIds: any[];
    parentIds: any[];
    staffIds: any[];
    constructor() {
        super();
        this.pageNumber = 1;
        this.pageSize = 10;
    }
}

export class RemoveUserFromGroupModel extends CommonField {
    commUserGroups: any[];
    constructor() {
        super();
        this.commUserGroups = [];
    }
}

export class AllUsersFromSchoolModel extends CommonField {
    allUserViewList: any[];
    filterParams: filterParams[];
    studentIds?: number[];
    studentWithParentId?: any[];
    constructor() {
        super();
        this.filterParams = [];
    }
}

export class filterParams {
    columnName: string;
    filterValue: string;
    filterOption: number;
    constructor() {
        this.columnName = "FirstGivenName";
        this.filterOption = 1;
        this.filterValue = null;
    }
}

export class AddComposeMessageModel extends CommonField {
    commMessage: CommMessageModel;
    composeMessageViewModel: any[];
    sendAsEmail: boolean;
    constructor() {
        super();
        this.commMessage = new CommMessageModel();
        this.composeMessageViewModel = [];
    }
}

export class CommMessageModel {
    tenantId: string;
    schoolId: number;
    messageId: number;
    parentMessageId: number;
    creatorId: number;
    profileType: string;
    subject: string;
    messageBody: string;
    createdBy: string;
    commAttachments: any[];
    constructor() {
        this.commAttachments = [];
    }
}

export class CommAttachmentsModel {
    attachmentName: string;
    attachmentType: string;
    attachment: string;
}

export class ComposeMessageViewModel {
    recipientInfoViewModel: RecipientInfoViewModel[];
    isTo: boolean;
    isCC: boolean;
    isBcc: boolean;
    constructor() {
        this.recipientInfoViewModel = [];
    }
}

export class RecipientInfoViewModel {
    type: string;
    id: number
    courseSectionId: string;
    title: string;
    isStudent: boolean;
    isTeacher: boolean;
    isParent: boolean;
}

export class GetMessagingInboxModel extends CommonField {
    messageingInboxViewModels: MessageingInboxViewModels[];
    userId: number;
    profileType: string;
    constructor() {
        super();
    }
}

export class MessageingInboxViewModels {
    senderFirstName: string;
    senderMiddleName: string;
    senderLastName: string;
    senderThumbnailPhoto: string;
    messageId: number;
    createDate: string;
    subject: string;
    messageBody: string;
    isRead: boolean;
}

export class ViewInboxMessageModel extends CommonField {
    messageingInboxViewModels: any[];
    messageId: number;
    userId: number;
    profileType: string;
    constructor() {
        super();
    }
}

export class GetSentMessagingModel extends CommonField {
    messageingInboxViewModels: any[];
    userId: number;
    profileType: string;
    constructor() {
        super();
    }
}
//=====================================>
export class AddCommunicationSettingsModel extends CommonField {
    public commSchoolSettingEmail: AddCommSchoolSettingEmail;
    public _failure: boolean;
    public _message: string;
    constructor() {
        super();
        this.commSchoolSettingEmail = new AddCommSchoolSettingEmail();
    } 
}

export class ViewCommunicationSettingsModel extends CommonField {
    public commSchoolSettingEmail: ViewCommSchoolSettingEmail;
    public _failure: boolean;
    public _message: string;
    constructor() {
        super();
        this.commSchoolSettingEmail = new ViewCommSchoolSettingEmail();
    } 
}

export class EditCommunicationSettingsModel extends CommonField {
    public commSchoolSettingEmail: EditCommSchoolSettingEmail;
    public _failure: boolean;
    public _message: string;
    public isRequest: boolean;
    constructor() {
        super();
        this.commSchoolSettingEmail = new EditCommSchoolSettingEmail();
    } 
}

class AddCommSchoolSettingEmail {
    id: number;
    apiEndpoint: string;
    apiKey: string;
    apiKeyId: string;
    apiProviderName: string;
    apiPassword: string;
    apiUsername: string;
    authType: string;
    notificationSender: string;
    notificationSenderName: string;
    enableEmailNotification: boolean | string;
    errorNotificationRecipients: string;
    adminNotificationRecipients: string;
    supportNotificationRecipients: string;
    isSecureConnection: boolean | string;
    isSmtp: boolean | string;
    port: number;
    smtpHost: string;
    username: string;
    password: string;
    schoolId: number;
    tenantId: string;
    staffId: number;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
    constructor() {
        this.apiEndpoint = null;
        this.apiKey = null;
        this.apiKeyId = null;
        this.apiProviderName = null;
        this.apiPassword = null;
        this.apiUsername = null;
        this.authType = null;
        this.notificationSender = null;
        this.notificationSenderName = null;
        this.enableEmailNotification = null;
        this.errorNotificationRecipients = null;
        this.adminNotificationRecipients = null;
        this.supportNotificationRecipients = null;
        this.isSecureConnection = true;
        this.isSmtp = true;
        this.port = null;
        this.smtpHost = null;
        this.username = null;
        this.password = null;
        this.schoolId = null;
        this.staffId = null;
    }
}

class ViewCommSchoolSettingEmail {
    id: number;
    schoolId: number;
    tenantId: string;
    staffId: number;
}

class EditCommSchoolSettingEmail {
    id: number;
    apiEndpoint: string;
    apiKey: string;
    apiKeyId: string;
    apiProviderName: string;
    apiPassword: string;
    apiUsername: string;
    authType: string;
    notificationSender: string;
    notificationSenderName: string;
    enableEmailNotification: boolean | string;
    errorNotificationRecipients: string;
    adminNotificationRecipients: string;
    supportNotificationRecipients: string;
    isSecureConnection: boolean | string;
    isSmtp: boolean | string;
    port: number;
    smtpHost: string;
    username: string;
    password: string;
    schoolId: number;
    tenantId: string;
    staffId: number;
    updatedBy: string;
    updatedOn: string;
    createdBy: string;
    createdOn: string;
}

// Activity
export class AddCommActivityModel extends CommonField {
    public commActivity: CommActivity;
    public reminderOnPreviousDay: boolean;
    public reminderOnThatDay: boolean;
    constructor() {
        super();
        this.commActivity = new CommActivity();
    } 
}
export class AllActivityListModel extends CommonField {
    public tenantId : string;
    public schoolId : number;
    public initiatorId : string;
    public studentId : number;
}

export class EditCommActivityModel extends CommonField {
    public commActivity: CommActivity;
    public reminderOnPreviousDay: boolean;
    public reminderOnThatDay: boolean;
    constructor() {
        super();
        this.commActivity = new CommActivity();
    } 
}

export class DeleteCommActivityModel extends CommonField {
    public commActivity: CommActivity;
    constructor() {
        super();
        this.commActivity = new CommActivity();
    } 
}

class CommActivity {
    tenantId : string;
    schoolId : number;
    activityId : number;
    initiatorId : number
    profileType : string;
    activityDate : string;
    activityTime : string;
    notes : string;
    activityType : string;
    callNature : string;
    meetingPurpose : string;
    isScheduled : boolean | string;
    reminderDate : string;
    createdBy : string;
    updatedBy : string;
    public commActivityParticipants : CommActivityParticipants[]
}

class CommActivityParticipants {
    tenantId : string;
    schoolId : number;
    activityId : number;
    initiatorId : number;
    id : number;
    participantStudentId : number;
    participantParentId : string;
    participantStaffId : string;
    participantGroupId : string;
    participantName : string;
    createdBy : string;
}

export class UnreadMessageCountModel extends CommonField {
    userId: number | string;
    profileType: string;
    constructor() {
        super();
    }
}