import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';
import icLocalPhone from '@iconify/icons-ic/twotone-local-phone';
import icGroups from '@iconify/icons-ic/twotone-groups';
import icStickyNote from '@iconify/icons-ic/twotone-sticky-note-2';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icCancel from '@iconify/icons-ic/twotone-cancel';
import icSchool from '@iconify/icons-ic/twotone-school';
import icGroup from '@iconify/icons-ic/twotone-group';
import icPersonOutline from '@iconify/icons-ic/twotone-person-outline';
import icEscalatorWarning from '@iconify/icons-ic/twotone-escalator-warning';
import icRestore from '@iconify/icons-ic/twotone-restore';
import icInfo from '@iconify/icons-ic/round-info';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefaultValuesService } from 'src/app/common/default-values.service';
import { uniqueColors } from 'src/app/common/static-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AddCommActivityModel, AddComposeMessageModel, AllActivityListModel, AllUsersFromSchoolModel, DeleteCommActivityModel, EditCommActivityModel } from 'src/app/models/communication.model';
import { GetAllCourseListModel } from 'src/app/models/course-manager.model';
import { GetAllGradeLevelsModel } from 'src/app/models/grade-level.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { MY_FORMATS } from '../../shared/format-datepicker';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SharedFunction } from '../../shared/shared-function';
import { ProfilesTypes } from 'src/app/enums/profiles.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'vex-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class AddActivityComponent implements OnInit, AfterViewInit, OnDestroy {

  icClose = icClose;
  icLocalPhone = icLocalPhone;
  icGroups = icGroups;
  icStickyNote = icStickyNote;
  icEdit = icEdit;
  icCancel = icCancel;
  icSchool = icSchool;
  icGroup = icGroup;
  icPersonOutline = icPersonOutline;
  icEscalatorWarning = icEscalatorWarning;
  icRestore = icRestore;
  icInfo = icInfo;
  currentTab: string = 'notes';
  participantList = true;
  showParticipant = false;
  serchDetails:boolean = false;
  formNotes: FormGroup;
  formPhone: FormGroup;
  formMeeting: FormGroup;
  addCommActivityModel: AddCommActivityModel = new AddCommActivityModel();
  allActivityListModel: AllActivityListModel = new AllActivityListModel();
  editCommActivityModel: EditCommActivityModel = new EditCommActivityModel();
  deleteCommActivityModel: DeleteCommActivityModel = new DeleteCommActivityModel();
  gradeLevelList = [];
  courseList = [];
  toRecipientList = [];
  isAllUserSearched: boolean = false;
  toSearchCtrl: FormControl;
  participantControl: FormControl;
  userList = [];
  groupList = [];
  selectedCourse;
  selectedCourseSection;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('toChipList') toChipList: ElementRef<HTMLInputElement>;
  @ViewChild('parentToChipList') parentToChipList: ElementRef<HTMLInputElement>;
  getAllGradeLevelsModel: GetAllGradeLevelsModel = new GetAllGradeLevelsModel();
  getAllCourseListModel: GetAllCourseListModel = new GetAllCourseListModel();
  addComposeMessageModel: AddComposeMessageModel = new AddComposeMessageModel();
  allUsersFromSchoolModel: AllUsersFromSchoolModel = new AllUsersFromSchoolModel();
  searchToInput: any;
  composeMessageDefaultData = [
    {
      recipientInfoViewModels: this.toRecipientList,
      isTo: true
    },
  ];
  allUsersFromSchoolSubject$: Subject<void> = new Subject();
  isLoading: boolean = false;
  historyViewable: boolean = false;
  viewHistory: boolean = false;
  studentFullName: string;
  studentActivityListFetched: boolean = false;
  profiles = ProfilesTypes;
  studentActivityData: MatTableDataSource<any>;
  studentActivityHistoryNotes = [];
  studentActivityHistoryPhoneCalls = [];
  studentActivityHistoryMeetings = [];
  pageSize = 10;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns = [
    { label: 'activities', property: 'activities', type: 'text', visible: true },
    { label: 'date', property: 'date', type: 'text', visible: true }
  ];
  cannotEdit: boolean = false;
  parentsList = [];
  selectedParents = [];
  parentSearchCtrl: FormControl;
  searchingFor: string = "studentId";
  studentWithParentId = [];
  
  constructor(
    private dialogRef: MatDialogRef<AddActivityComponent>,
    public translateService:TranslateService,
    private communicationService: CommunicationService,
    private snackbar: MatSnackBar,
    public defaultValuesService: DefaultValuesService,
    private commonFunction: SharedFunction,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.formInit();
    if (this.data.action == 'add') {
      this.modifyGroupListData();
      this.addParticipantList();
    } else if (this.data.action == 'edit') {
        this.currentTab = this.data?.selectedStudents.activityType == 'Notes' ? 'notes': this.data?.selectedStudents.activityType == 'Phone' ? 'phoneCall' : 'meeting';
        this.patchStudentData();
        this.addParticipantForEdit();

        if (this.data?.selectedStudents.initiatorId != this.defaultValuesService.getUserId()) {
          this.cannotEdit = true;
        }
    } else if (this.data.action == 'addOneActivityType') {
      this.currentTab = this.data?.activityType == 'notes' ? 'notes': this.data?.activityType == 'phone' ? 'phoneCall' : 'meeting';
      this.addParticipantForOneActivityType();
    }

    this.historyViewable = this.data?.historyViewable;
    this.studentFullName = this.data?.selectedStudents[0]?.title;

    this.toSearchCtrl = new FormControl();
    this.participantControl = new FormControl();
    this.parentSearchCtrl = new FormControl();
    
    this.studentActivityData = new MatTableDataSource([]);

    if (this.cannotEdit === true) {
      this.makeFormFieldsDisabled();
    }
  }

  ngAfterViewInit(): void {
    // For searching
    this.toSearchCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((term) => {      
      if (term?.trim().length > 0) {
        this.searchingFor = "studentId";
        this.serchDetails = true;
        this.searchToInput = term;

        this.isAllUserSearched = this.searchToInput?.trim().length >= 3 ? true : false;
        this.userList = [];
        if (this.searchToInput?.trim().length >= 3) {
          let filterParams = [
            {
              columnName: "FirstGivenName",
              filterOption: 1,
              filterValue: this.searchToInput,
              joinCondition : "and"
            },
            {
              columnName: "profiletype",
              filterOption: 11,
              filterValue: "Student"
            }
          ];
          Object.assign(this.allUsersFromSchoolModel, { filterParams: filterParams });
          Object.assign(this.allUsersFromSchoolModel, { studentIds: [] });
          this.allUsersFromSchoolSubject$.next();
        }
      } else {
        this.userList = [];
      }
    });

    this.parentSearchCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((term) => {      
      if (term?.trim().length > 0) {
        this.searchingFor = "parentId";
        this.parentsList = [];
        if (term?.trim().length >= 3) {
          let filterParams = [
            {
              columnName: "FirstGivenName",
              filterOption: 1,
              filterValue: term,
              joinCondition : "and"
            },
            {
              columnName: "profiletype",
              filterOption: 11,
              filterValue: "Parent"
            }
          ];
          Object.assign(this.allUsersFromSchoolModel, { filterParams: filterParams });

          let currentStudentIds = [];
          this.composeMessageDefaultData[0].recipientInfoViewModels.forEach(oneStu => {
            currentStudentIds.push(oneStu.participantStudentId);
          });

          Object.assign(this.allUsersFromSchoolModel, { studentIds: currentStudentIds });

          this.allUsersFromSchoolSubject$.next();
        }
      } else {
        this.parentsList = [];
      }
    });

    this.allUsersFromSchool();

    this.studentActivityData.paginator = this.paginator;
  }

formInit() {
  this.formNotes = new FormGroup({
    date: new FormControl(new Date(), Validators.required),
    time: new FormControl(new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow())), Validators.required),
    notes: new FormControl('', Validators.required),
    // participants: new FormControl('', Validators.required),
  });

  this.formPhone = new FormGroup({
    date: new FormControl(new Date(), Validators.required),
    time: new FormControl(new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow())), Validators.required),
    natureOfCall: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    notes: new FormControl('', Validators.required),
    onThatDayCheck: new FormControl(false),
    onPreviousDayCheck: new FormControl(false)
    // participants: new FormControl('', Validators.required),
  });

  this.formMeeting = new FormGroup({
    date: new FormControl(new Date(), Validators.required),
    time: new FormControl(new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow())), Validators.required),
    purpose: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    meetingAgenda: new FormControl('', Validators.required),
    onThatDayCheck: new FormControl(false),
    onPreviousDayCheck: new FormControl(false)
    // participants: new FormControl('', Validators.required),
  });
}
  makeFormFieldsDisabled() {
    this.formNotes.get('date')?.disable();
    this.formNotes.get('time')?.disable();
    this.formNotes.get('notes')?.disable();

    this.formPhone.get('date')?.disable();
    this.formPhone.get('time')?.disable();
    this.formPhone.get('natureOfCall')?.disable();
    this.formPhone.get('notes')?.disable();
    this.formPhone.get('onThatDayCheck')?.disable();
    this.formPhone.get('onPreviousDayCheck')?.disable();

    this.formMeeting.get('date')?.disable();
    this.formMeeting.get('time')?.disable();
    this.formMeeting.get('purpose')?.disable();
    this.formMeeting.get('meetingAgenda')?.disable();
    this.formMeeting.get('onThatDayCheck')?.disable();
    this.formMeeting.get('onPreviousDayCheck')?.disable();
  }

getTimeNow() {
  let timeStr = '';
  let temptimeStr = new Date().toLocaleTimeString()
  if (temptimeStr.length == 10) {
    timeStr = temptimeStr.slice(0, 4) + temptimeStr.slice(7);
    return timeStr
  } else {
    timeStr = temptimeStr.slice(0, 5) + temptimeStr.slice(8);
    return timeStr;
  }
}

modifyGroupListData() {
    this.groupList = this.data.groupList;
    if (this.groupList.length) {
      const lastIndex = this.groupList.length - 1;
      this.groupList.map((group, index) => {
        group.formattedName = index === lastIndex ? group.groupName : `${group.groupName},`;
        group.id = group.groupId;
        group.title = group.groupName;
        group.type = 'groupId';
      });
  }
}

addParticipantList() { 
  this.composeMessageDefaultData[0].recipientInfoViewModels = [];
  if (this.data?.selectedStudents.length>0) {
    this.data?.selectedStudents.map(item => {
      const middleName = item.middleName ? ' ' + item.middleName + ' ' : ' ';
      item.title = item.firstGivenName + middleName + item.lastFamilyName;
      this.composeMessageDefaultData[0].recipientInfoViewModels.push({
        id: 0,
        type: 'studentId',
        participantStudentId: item.studentId,
        title: item.title,
        isStudent: true,
        initiatorId: this.defaultValuesService.getUserId(),
        createdBy: this.defaultValuesService.getUserGuidId()
      });
    });
  }
}

addParticipantForEdit() {
  this.composeMessageDefaultData[0].recipientInfoViewModels = [];
  this.data?.selectedStudents.commActivityParticipants.map(item => {
    if (item.participantStudentId) {
      this.composeMessageDefaultData[0].recipientInfoViewModels.push({
        id: item.id,
        type: 'studentId',
        participantStudentId: item.participantStudentId,
        title: item.participantName,
        isStudent: true,
        initiatorId: item.initiatorId,
        createdBy: item.createdBy
      });
    }
    else if (item.participantParentId) {
      this.selectedParents.push({
        id: item.id,
        type: 'parentId',
        participantParentId: item.participantParentId,
        title: item.participantName,
        initiatorId: item.initiatorId,
        createdBy: item.createdBy
      });
    }
  });
}

addParticipantForOneActivityType() {
  this.composeMessageDefaultData[0].recipientInfoViewModels = [];
  const middleName = this.data?.selectedStudents?.middleName ? ' ' + this.data?.selectedStudents?.middleName + ' ' : ' ';
  // const middleName = this.data?.selectedStudents?.middleName !== null ? ' ' + this.data?.selectedStudents?.middleName + ' ' : ' ';
  let studentName = this.data?.selectedStudents?.firstGivenName + middleName + this.data?.selectedStudents?.lastFamilyName;
  studentName = studentName.replace('null ','');
  this.composeMessageDefaultData[0].recipientInfoViewModels.push({
    id: 0,
    type: 'studentId',
    participantStudentId: this.data?.studentId,
    title: studentName,
    isStudent: true,
    initiatorId: this.defaultValuesService.getUserId(),
    createdBy: this.defaultValuesService.getUserGuidId()
  });
}

patchStudentData() {    
  if (this.data?.selectedStudents.activityType == 'Notes') {
    this.formNotes.patchValue({
      date: this.data?.selectedStudents.activityDate,
      time: new Date("1900-01-01T" + this.timeToUTC(this.data?.selectedStudents.activityTime)),
      notes: this.data?.selectedStudents.notes,
    })
    this.currentTab = 'notes';
  } else if (this.data?.selectedStudents.activityType == 'Phone') {
    this.formPhone.patchValue({
      date: this.data?.selectedStudents.activityDate,
      time: new Date("1900-01-01T" + this.timeToUTC(this.data?.selectedStudents.activityTime)),
      natureOfCall: this.data?.selectedStudents.callNature,
      notes: this.data?.selectedStudents.notes,
      onThatDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) === this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false,
      onPreviousDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) > this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false
    })
    this.currentTab = 'phoneCall';
  } else if (this.data?.selectedStudents.activityType == 'Meeting') {
    this.formMeeting.patchValue({
      date: this.data?.selectedStudents.activityDate,
      time: new Date("1900-01-01T" + this.timeToUTC(this.data?.selectedStudents.activityTime)),
      purpose: this.data?.selectedStudents.meetingPurpose,
      meetingAgenda: this.data?.selectedStudents.notes,
      onThatDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) === this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false,
      onPreviousDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) > this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false
    })
    this.currentTab = 'meeting';
  }
}

timeToUTC(hourMin) {
  let timeStr = '';
  let amPm = hourMin.split(" ");
  let hhMMstr = amPm[0].split(":");
  if (amPm[1] == 'AM' || amPm[1] == 'am') {
    timeStr = (hhMMstr[0] == 12 ? '00:' + hhMMstr[1] : hourMin.slice(0,-3)) + ':00';
  } else {
    let temp = +hhMMstr[0] + 12;
    timeStr = temp.toString() + ':' + hhMMstr[1] + ':00'
  }
  return timeStr;
}

  changeTab(status) {
    this.currentTab = status;
    this.formNotes.reset();
    this.formPhone.reset();
    this.formMeeting.reset();
    if (this.data.action == 'add' || this.data.action == 'addOneActivityType') {
      if (this.currentTab == 'notes') {
        this.formNotes.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        })
      } else if (this.currentTab == 'phoneCall') {
        this.formPhone.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        })
      } else if (this.currentTab == 'meeting') {
        this.formMeeting.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        })
      }
    } else if (this.data.action == 'edit') {
      if (this.data?.selectedStudents.activityType == 'Notes') {
        this.formNotes.patchValue({
          date: this.data?.selectedStudents.activityDate,
          time: new Date("1900-01-01T" + this.timeToUTC(this.data?.selectedStudents.activityTime)),
          notes: this.data?.selectedStudents.notes,
        })
        this.formPhone.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        });
        this.formMeeting.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        })
      }

      if (this.data?.selectedStudents.activityType == 'Phone') {
        this.formNotes.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        });
        this.formPhone.patchValue({
          date: this.data?.selectedStudents.activityDate,
          time: new Date("1900-01-01T" + this.timeToUTC(this.data?.selectedStudents.activityTime)),
          natureOfCall: this.data?.selectedStudents.callNature,
          notes: this.data?.selectedStudents.notes,
          onThatDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) === this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false,
          onPreviousDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) > this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false
        });
        this.formMeeting.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        });
      }

      if (this.data?.selectedStudents.activityType == 'Meeting') {
        this.formNotes.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        });
        this.formPhone.patchValue({
          date: new Date(),
          time: new Date("1900-01-01T" + this.timeToUTC(this.getTimeNow()))
        });
        this.formMeeting.patchValue({
          date: this.data?.selectedStudents.activityDate,
          time: new Date("1900-01-01T" + this.timeToUTC(this.data?.selectedStudents.activityTime)),
          purpose: this.data?.selectedStudents.meetingPurpose,
          meetingAgenda: this.data?.selectedStudents.notes,
          onThatDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) === this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false,
          onPreviousDayCheck: this.data?.selectedStudents?.reminderDate ? this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.activityDate) > this.commonFunction.formatDateSaveWithoutTime(this.data?.selectedStudents?.reminderDate) : false
        });
      }
    }

    // For Viewing History
    if (this.viewHistory) {
      this.setHistoryForCurrentTab();
    }
  }

  onSearchRecipient() {
    this.serchDetails = !this.serchDetails;
  }

  addParticipants() {
    this.participantList = false;
    this.showParticipant = true;
  }

  addRecipientData(data, source, sourceType) {
    const sourceTypeIndex = this.composeMessageDefaultData.findIndex(item => item[sourceType]);
    if (this.composeMessageDefaultData[sourceTypeIndex].recipientInfoViewModels.findIndex(item => (item.participantStudentId === data.id && item.type === data.type)) === -1) {
      this.composeMessageDefaultData[sourceTypeIndex].recipientInfoViewModels.push({
        type: data.type,
        id: 0,
        participantStudentId: data.id,
        title: data.title,
        initiatorId: this.defaultValuesService.getUserId(),
        createdBy: this.defaultValuesService.getUserGuidId()
      });
      sourceTypeIndex === 0 ? this.toSearchCtrl.setValue('') : '';
      sourceTypeIndex === 0 ? this.serchDetails = false : '';
    }
  }

  removeRecipientData(data, sourceType) {
    const sourceTypeIndex = this.composeMessageDefaultData.findIndex(item => item[sourceType]);
    const index = this.composeMessageDefaultData[sourceTypeIndex].recipientInfoViewModels.findIndex(item => (item.participantStudentId === data.participantStudentId && item.type === data.type));
    if (index !== -1) {
      this.composeMessageDefaultData[sourceTypeIndex].recipientInfoViewModels.splice(index, 1);
    }
    this.toChipList.nativeElement.value = '';
    this.toSearchCtrl.setValue(null);
    this.userList = [];

    // Removing parents of the student who is getting removed
    let stuIdx = this.studentWithParentId.findIndex(item => item.studentId == data.participantStudentId);
    if (stuIdx !== -1) {
      let parentsOfThisStudent = this.studentWithParentId[stuIdx].parentIds;
      if (parentsOfThisStudent) {
        parentsOfThisStudent.forEach(parentId => {
          const parentIdx = this.selectedParents.findIndex(item => (item.participantParentId === parentId));
          if (parentIdx !== -1) {
            this.selectedParents.splice(parentIdx, 1);
          }
        });
      }
    }
  }

  removeParent(data) {
    const index = this.selectedParents.findIndex(item => (item.participantParentId === data.id));
    if (index !== -1) {
      this.selectedParents.splice(index, 1);
    }

    this.parentToChipList.nativeElement.value = '';
    this.parentSearchCtrl.setValue(null);
    this.parentsList = [];
  }

  allUsersFromSchool() {
    this.allUsersFromSchoolSubject$.pipe(switchMap(() => this.communicationService.allUsersFromSchool(this.allUsersFromSchoolModel))).subscribe(res => {
      if (res) {
        if (res._failure) {
          this.userList = [];
          this.parentsList = [];
          if (res.allUserViewList === null) {
            this.snackbar.open(res._message, '', {
              duration: 10000
            });
          }
        } else {
          res.allUserViewList.map((item: any) => {
            item.firstNameChar = item.firstGivenName.trim().charAt(0).toUpperCase();
            item.bgColor = uniqueColors[Math.floor(Math.random() * 9)].backgroundColor;
            item.id = item.userId;
            const middleName = item.middleName ? ' ' + item.middleName + ' ' : ' ';
            item.title = item.firstGivenName + middleName + item.lastFamilyName;
            item.type = this.searchingFor; // 'studentId';
          });
          if (this.searchingFor == 'studentId') {
            this.userList = res.allUserViewList;
          }
          else if (this.searchingFor == 'parentId') {
            this.parentsList = res.allUserViewList;
            this.studentWithParentId = res.studentWithParentId;
          }
        }
      } else {
        this.snackbar.open(this.defaultValuesService.getHttpError(), '', {
          duration: 10000
        });
      }
    });
  }

  getAllSelectedTitle() {
    let titleStr = '';
    this.composeMessageDefaultData[0].recipientInfoViewModels.map(item=>{
      titleStr += item.title + ', ';
    })
    titleStr = titleStr.substring(0, titleStr.length - 2);
    return titleStr;
  }

  reminderCheck(event: MatCheckboxChange, source: string) {
    if (this.currentTab === 'phoneCall') {
      if (event.checked) {
        if (source === 'onThatDay') {
          this.formPhone.controls.onThatDayCheck.patchValue(true);
          this.formPhone.controls.onPreviousDayCheck.patchValue(false);
        } else {
          this.formPhone.controls.onPreviousDayCheck.patchValue(true);
          this.formPhone.controls.onThatDayCheck.patchValue(false);
        }
      } else {
        this.formPhone.controls.onThatDayCheck.patchValue(false);
        this.formPhone.controls.onPreviousDayCheck.patchValue(false);
      }
    } else if (this.currentTab === 'meeting') {
      if (event.checked) {
        if (source === 'onThatDay') {
          this.formMeeting.controls.onThatDayCheck.patchValue(true);
          this.formMeeting.controls.onPreviousDayCheck.patchValue(false);
        } else {
          this.formMeeting.controls.onPreviousDayCheck.patchValue(true);
          this.formMeeting.controls.onThatDayCheck.patchValue(false);
        }
      } else {
        this.formMeeting.controls.onThatDayCheck.patchValue(false);
        this.formMeeting.controls.onPreviousDayCheck.patchValue(false);
      }
    }
  }

  onSubmit() {
  if(this.composeMessageDefaultData[0].recipientInfoViewModels.length<1) {
    this.snackbar.open(this.defaultValuesService.translateKey('addParticipants'), '', {
      duration: 5000
    });
  } else {
    // Add Activity   
    if (this.data.action == 'add' || this.data.action == 'addOneActivityType') {
      if (this.formNotes.valid && this.composeMessageDefaultData[0].recipientInfoViewModels.length>0) {
        this.addCommActivityModel = new AddCommActivityModel();
        this.addCommActivityModel.commActivity.activityId = 0;
        this.addCommActivityModel.commActivity.activityType = "Notes";
        this.addCommActivityModel.commActivity.isScheduled = true;
        this.addCommActivityModel.commActivity.callNature = null;
        this.addCommActivityModel.commActivity.meetingPurpose = null;
        this.addCommActivityModel.commActivity.reminderDate = null;
        this.addCommActivityModel.commActivity.activityDate = this.commonFunction.formatDateSaveWithoutTime(this.formNotes.value.date);
        this.addCommActivityModel.commActivity.activityTime = moment(this.formNotes.value.time).format('LT').toString();
        this.addCommActivityModel.commActivity.notes = this.formNotes.value.notes;
        let tempArr = [];

        // for Students
        this.composeMessageDefaultData[0].recipientInfoViewModels.map(studentData=>{
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: 0,
            initiatorId: this.defaultValuesService.getUserId(),
            id: 0,
            participantStudentId: studentData.participantStudentId,
            participantName: studentData.title,
            participantParentId: null,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // for Parents
        this.selectedParents.map(parentData => {
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: 0,
            initiatorId: this.defaultValuesService.getUserId(),
            id: 0,
            participantStudentId: null,
            participantName: parentData.title,
            participantParentId: parentData.participantParentId,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: this.defaultValuesService.getUserGuidId()
          });
        })

        this.addCommActivityModel.commActivity.commActivityParticipants = tempArr;
        this.isLoading = true;
        this.communicationService.addCommActivity(this.addCommActivityModel).subscribe(res=>{
          this.isLoading = false;
          if (res) {
            if (res._failure) {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
            } else {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
              this.dialogRef.close('submited');
            }
          } else {
            this.snackbar.open(res._message, '', {
              duration: 5000
            });
          }
        })
      } else if (this.formPhone.valid && this.composeMessageDefaultData[0].recipientInfoViewModels.length>0) {
        this.addCommActivityModel = new AddCommActivityModel();
        this.addCommActivityModel.commActivity.activityId = 0;
        this.addCommActivityModel.commActivity.activityType = "Phone";
        this.addCommActivityModel.commActivity.isScheduled = true;
        this.addCommActivityModel.commActivity.callNature = this.formPhone.value.natureOfCall;
        this.addCommActivityModel.reminderOnThatDay = this.formPhone.value.onThatDayCheck;
        this.addCommActivityModel.reminderOnPreviousDay = this.formPhone.value.onPreviousDayCheck;
        this.addCommActivityModel.commActivity.meetingPurpose = null;
        this.addCommActivityModel.commActivity.reminderDate = null;
        this.addCommActivityModel.commActivity.activityDate = this.commonFunction.formatDateSaveWithoutTime(this.formPhone.value.date);
        this.addCommActivityModel.commActivity.activityTime = moment(this.formPhone.value.time).format('LT').toString();
        this.addCommActivityModel.commActivity.notes = this.formPhone.value.notes;
        let tempArr = [];

        // for Students
        this.composeMessageDefaultData[0].recipientInfoViewModels.map(studentData=>{
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: 0,
            initiatorId: this.defaultValuesService.getUserId(),
            id: 0,
            participantStudentId: studentData.participantStudentId,
            participantName: studentData.title,
            participantParentId: null,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // for Parents
        this.selectedParents.map(parentData => {
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: 0,
            initiatorId: this.defaultValuesService.getUserId(),
            id: 0,
            participantStudentId: null,
            participantName: parentData.title,
            participantParentId: parentData.participantParentId,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: this.defaultValuesService.getUserGuidId()
          });
        })

        this.addCommActivityModel.commActivity.commActivityParticipants = tempArr;
        this.isLoading = true;
        this.communicationService.addCommActivity(this.addCommActivityModel).subscribe(res=>{
          this.isLoading = false;
          if (res) {
            if (res._failure) {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
            } else {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
              this.dialogRef.close('submited');
            }
          } else {
            this.snackbar.open(res._message, '', {
              duration: 5000
            });
          }
        })
      } else if (this.formMeeting.valid && this.composeMessageDefaultData[0].recipientInfoViewModels.length>0) {
        this.addCommActivityModel = new AddCommActivityModel();
        this.addCommActivityModel.commActivity.activityId = 0;
        this.addCommActivityModel.commActivity.activityType = "Meeting";
        this.addCommActivityModel.commActivity.isScheduled = true;
        this.addCommActivityModel.commActivity.callNature = null;
        this.addCommActivityModel.commActivity.meetingPurpose = this.formMeeting.value.purpose;
        this.addCommActivityModel.reminderOnThatDay = this.formMeeting.value.onThatDayCheck;
        this.addCommActivityModel.reminderOnPreviousDay = this.formMeeting.value.onPreviousDayCheck;
        this.addCommActivityModel.commActivity.reminderDate = null;
        this.addCommActivityModel.commActivity.activityDate = this.commonFunction.formatDateSaveWithoutTime(this.formMeeting.value.date);
        this.addCommActivityModel.commActivity.activityTime = moment(this.formMeeting.value.time).format('LT').toString();
        this.addCommActivityModel.commActivity.notes = this.formMeeting.value.meetingAgenda;
        let tempArr = [];

        // for Students
        this.composeMessageDefaultData[0].recipientInfoViewModels.map(studentData=>{
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: 0,
            initiatorId: this.defaultValuesService.getUserId(),
            id: 0,
            participantStudentId: studentData.participantStudentId,
            participantName: studentData.title,
            participantParentId: null,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // for Parents
        this.selectedParents.map(parentData => {
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: 0,
            initiatorId: this.defaultValuesService.getUserId(),
            id: 0,
            participantStudentId: null,
            participantName: parentData.title,
            participantParentId: parentData.participantParentId,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: this.defaultValuesService.getUserGuidId()
          });
        })

        this.addCommActivityModel.commActivity.commActivityParticipants = tempArr;
        this.isLoading = true;
        this.communicationService.addCommActivity(this.addCommActivityModel).subscribe(res=>{
          this.isLoading = false;
          if (res) {
            if (res._failure) {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
            } else {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
              this.dialogRef.close('submited');
            }
          } else {
            this.snackbar.open(res._message, '', {
              duration: 5000
            });
          }
        })
      } else {
        this.formNotes.markAllAsTouched();
        this.formPhone.markAllAsTouched();
        this.formMeeting.markAllAsTouched();
      }
      // Edit & Update Activity Data
    } else if (this.data.action == 'edit') {
      if (this.currentTab == 'notes' && (this.formNotes.valid || this.formNotes.disabled) && this.composeMessageDefaultData[0].recipientInfoViewModels.length>0) {
        this.editCommActivityModel = new EditCommActivityModel();
        this.editCommActivityModel.commActivity.activityId = this.data.selectedStudents.activityId;
        this.editCommActivityModel.commActivity.activityType = "Notes";
        this.editCommActivityModel.commActivity.isScheduled = false;
        this.editCommActivityModel.commActivity.callNature = null;
        this.editCommActivityModel.commActivity.meetingPurpose = null;
        this.editCommActivityModel.commActivity.reminderDate = null;
        this.editCommActivityModel.commActivity.activityDate = this.commonFunction.formatDateSaveWithoutTime(this.formNotes.value.date);
        this.editCommActivityModel.commActivity.activityTime = moment(this.formNotes.value.time).format('LT').toString();
        this.editCommActivityModel.commActivity.notes = this.formNotes.value.notes;
        let tempArr = [];

        // for Students
        this.composeMessageDefaultData[0].recipientInfoViewModels.map(studentData=>{
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: this.data.selectedStudents.activityId,
            initiatorId: studentData.initiatorId,
            id: studentData.id,
            participantStudentId: studentData.participantStudentId,
            participantParentId: null,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: studentData?.createdBy,
            updatedBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // for Parents
        this.selectedParents.map(parentData => {
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: this.data.selectedStudents.activityId,
            initiatorId: parentData.initiatorId,
            id: parentData.id,
            participantStudentId: null,
            participantName: parentData.title,
            participantParentId: parentData.participantParentId,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: parentData?.createdBy,
            updatedBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // if (this.composeMessageDefaultData[0].recipientInfoViewModels.length == 1) {
        //   this.editCommActivityModel.commActivity.commActivityParticipants = [];
        // } else if (this.composeMessageDefaultData[0].recipientInfoViewModels.length > 1) {
          this.editCommActivityModel.commActivity.commActivityParticipants = tempArr;
        // }
        this.isLoading = true;
        this.communicationService.editCommActivity(this.editCommActivityModel).subscribe(res=>{
          this.isLoading = false;
          if (res) {
            if (res._failure) {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
            } else {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
              this.dialogRef.close('submited');
            }
          } else {
            this.snackbar.open(res._message, '', {
              duration: 5000
            });
          }
        })
      } else if (this.currentTab == 'phoneCall' && (this.formPhone.valid || this.formPhone.disabled) && this.composeMessageDefaultData[0].recipientInfoViewModels.length>0) {
        this.editCommActivityModel = new EditCommActivityModel();
        this.editCommActivityModel.commActivity.activityId = this.data.selectedStudents.activityId;
        this.editCommActivityModel.commActivity.activityType = "Phone";
        this.editCommActivityModel.commActivity.isScheduled = false;
        this.editCommActivityModel.commActivity.callNature = this.formPhone.value.natureOfCall;
        this.editCommActivityModel.reminderOnThatDay = this.formPhone.value.onThatDayCheck;
        this.editCommActivityModel.reminderOnPreviousDay = this.formPhone.value.onPreviousDayCheck;
        this.editCommActivityModel.commActivity.meetingPurpose = null;
        this.editCommActivityModel.commActivity.reminderDate = null;
        this.editCommActivityModel.commActivity.activityDate = this.commonFunction.formatDateSaveWithoutTime(this.formPhone.value.date);
        this.editCommActivityModel.commActivity.activityTime = moment(this.formPhone.value.time).format('LT').toString();
        this.editCommActivityModel.commActivity.notes = this.formPhone.value.notes;
        let tempArr = [];

        // for Students
        this.composeMessageDefaultData[0].recipientInfoViewModels.map(studentData=>{
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: this.data.selectedStudents.activityId,
            initiatorId: studentData.initiatorId,
            id: studentData.id,
            participantStudentId: studentData.participantStudentId,
            participantParentId: null,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: studentData?.createdBy,
            updatedBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // for Parents
        this.selectedParents.map(parentData => {
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: this.data.selectedStudents.activityId,
            initiatorId: parentData.initiatorId,
            id: parentData.id,
            participantStudentId: null,
            participantName: parentData.title,
            participantParentId: parentData.participantParentId,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: parentData?.createdBy,
            updatedBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // if (this.composeMessageDefaultData[0].recipientInfoViewModels.length == 1) {
        //   this.editCommActivityModel.commActivity.commActivityParticipants = [];
        // } else if (this.composeMessageDefaultData[0].recipientInfoViewModels.length > 1) {
          this.editCommActivityModel.commActivity.commActivityParticipants = tempArr;
        // }
        this.isLoading = true;
        this.communicationService.editCommActivity(this.editCommActivityModel).subscribe(res=>{
          this.isLoading = false;
          if (res) {
            if (res._failure) {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
            } else {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
              this.dialogRef.close('submited');
            }
          } else {
            this.snackbar.open(res._message, '', {
              duration: 5000
            });
          }
        })
      } else if (this.currentTab == 'meeting' && (this.formMeeting.valid || this.formMeeting.disabled) && this.composeMessageDefaultData[0].recipientInfoViewModels.length>0) {
        this.editCommActivityModel = new EditCommActivityModel();
        this.editCommActivityModel.commActivity.activityId = this.data.selectedStudents.activityId;
        this.editCommActivityModel.commActivity.activityType = "Meeting";
        this.editCommActivityModel.commActivity.isScheduled = false;
        this.editCommActivityModel.commActivity.callNature = null;
        this.editCommActivityModel.commActivity.meetingPurpose = this.formMeeting.value.purpose;
        this.editCommActivityModel.reminderOnThatDay = this.formMeeting.value.onThatDayCheck;
        this.editCommActivityModel.reminderOnPreviousDay = this.formMeeting.value.onPreviousDayCheck;
        this.editCommActivityModel.commActivity.reminderDate = null;
        this.editCommActivityModel.commActivity.activityDate = this.commonFunction.formatDateSaveWithoutTime(this.formMeeting.value.date);
        this.editCommActivityModel.commActivity.activityTime = moment(this.formMeeting.value.time).format('LT').toString();
        this.editCommActivityModel.commActivity.notes = this.formMeeting.value.meetingAgenda;
        let tempArr = [];

        // for Students
        this.composeMessageDefaultData[0].recipientInfoViewModels.map(studentData=>{
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: this.data.selectedStudents.activityId,
            initiatorId: studentData.initiatorId,
            id: studentData.id,
            participantStudentId: studentData.participantStudentId,
            participantParentId: null,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: studentData?.createdBy,
            updatedBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // for Parents
        this.selectedParents.map(parentData => {
          tempArr.push({
            tenantId: this.defaultValuesService.getTenantID(),
            schoolId: this.defaultValuesService.getSchoolID(),
            activityId: this.data.selectedStudents.activityId,
            initiatorId: parentData.initiatorId,
            id: parentData.id,
            participantStudentId: null,
            participantName: parentData.title,
            participantParentId: parentData.participantParentId,
            participantStaffId: null,
            participantGroupId: null,
            createdBy: parentData?.createdBy,
            updatedBy: this.defaultValuesService.getUserGuidId()
          });
        })

        // if (this.composeMessageDefaultData[0].recipientInfoViewModels.length == 1) {
        //   this.editCommActivityModel.commActivity.commActivityParticipants = [];
        // } else if (this.composeMessageDefaultData[0].recipientInfoViewModels.length > 1) {
          this.editCommActivityModel.commActivity.commActivityParticipants = tempArr;
        // }
        this.isLoading = true;
        this.communicationService.editCommActivity(this.editCommActivityModel).subscribe(res=>{
          this.isLoading = false;
          if (res) {
            if (res._failure) {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
            } else {
              this.snackbar.open(res._message, '', {
                duration: 5000
              });
              this.dialogRef.close('submited');
            }
          } else {
            this.snackbar.open(res._message, '', {
              duration: 5000
            });
          }
        })
      } else {
        this.formNotes.markAllAsTouched();
        this.formPhone.markAllAsTouched();
        this.formMeeting.markAllAsTouched();
      }
    }
  }
  }

  userIsSelected(userId: number, userType: string) {
    if (userType == 'student') {
      let foundUser = this.composeMessageDefaultData[0].recipientInfoViewModels.find(studentData => {
        return studentData.participantStudentId == userId;
      });

      return (foundUser ? true : false);
    }
    else if (userType == 'parent') {
      let foundUser = this.selectedParents.find(parentData => {
        return parentData.participantParentId == userId;
      });

      return (foundUser ? true : false);
    }
  }

  sendUserValue(event: MatAutocompleteSelectedEvent): void {    
    this.addRecipientData(event.option.value, 'userList', 'isTo')
    this.toChipList.nativeElement.value = '';
    this.toSearchCtrl.setValue(null);
    this.userList = [];
  }

  setParentToActivity(event: MatAutocompleteSelectedEvent) {
    let data = event.option.value;

    this.selectedParents.push({
      type: data.type,
      id: 0,
      participantParentId: data.id,
      title: data.title,
      initiatorId: this.defaultValuesService.getUserId(),
      createdBy: this.defaultValuesService.getUserGuidId()
    });
    
    this.parentToChipList.nativeElement.value = '';
    this.parentSearchCtrl.setValue(null);
    this.parentsList = [];
  }

  viewingHistory(event) {
    if (event.checked) {
      if (!this.studentActivityListFetched) {
        this.getAllActivityList();
      }
      else {
        this.setHistoryForCurrentTab();
      }
    }
  }

  getAllActivityList() {
    this.allActivityListModel = new AllActivityListModel();

    if ((this.defaultValuesService.getUserMembershipType() === this.profiles.SuperAdmin) ||
      (this.defaultValuesService.getUserMembershipType() === this.profiles.Parent) ||
      (this.defaultValuesService.getUserMembershipType() === this.profiles.Student)) {
      this.allActivityListModel.initiatorId = null;
    }
    else {
      this.allActivityListModel.initiatorId = this.defaultValuesService.getUserId();
    }

    this.allActivityListModel.studentId = this.data?.selectedStudents[0].studentId;

    this.isLoading = true;
    
    this.communicationService.getAllActivityList(this.allActivityListModel).subscribe((data: any) => {
      this.isLoading = false;
      if (data) {
        if (data._failure) {
          this.snackbar.open(data._message, '', {
            duration: 5000
          });
          this.studentActivityData = new MatTableDataSource([]);
        } else {
          this.studentActivityListFetched = true;

          data?.commActivities.sort((a, b) => {
            // Compare dates
            const dateA = new Date(a.activityDate);
            const dateB = new Date(b.activityDate);
            if (dateA < dateB) {
              return 1;
            } else if (dateA > dateB) {
              return -1;
            } else {
              // If dates are equal,   
              // compare times in reverse chronological order
              const timeA: any = new Date(`1970-01-01 ${a.activityTime}`);
              const timeB: any = new Date(`1970-01-01 ${b.activityTime}`);
              return timeB - timeA;
            }
          });

          data?.commActivities.map(oneActivity => {
            oneActivity.meetingPurpose = (oneActivity.meetingPurpose ? oneActivity.meetingPurpose.trim() : oneActivity.meetingPurpose),
            oneActivity.callNature = (oneActivity.callNature ? oneActivity.callNature.trim() : oneActivity.callNature)
          })

          data?.commActivities.forEach(oneActivity => {
            switch (oneActivity.activityType) {
              case "Notes":
                this.studentActivityHistoryNotes.push(oneActivity);
                break;
            
              case "Phone":
                this.studentActivityHistoryPhoneCalls.push(oneActivity);
                break;

              case "Meeting":
                this.studentActivityHistoryMeetings.push(oneActivity);
                break;
            }
          });

          this.setHistoryForCurrentTab();
        }
      } else {
        this.snackbar.open(data._message, '', {
          duration: 5000
        });
        this.studentActivityData = new MatTableDataSource([]);
      }
    })
  }

  get colspanLength() {
    return this.displayedColumns.filter(column => column.visible).length;
  }

  get visibleCols() {
    return this.displayedColumns.filter(column => column.visible).map(column => column.property);
  }

  setHistoryForCurrentTab() {
    let currentTabData = [];

    switch (this.currentTab) {
      case "notes":
        currentTabData = this.studentActivityHistoryNotes;
        break;
    
      case "phoneCall":
        currentTabData = this.studentActivityHistoryPhoneCalls;
        break;

      case "meeting":
        currentTabData = this.studentActivityHistoryMeetings;
        break;
    }

    this.studentActivityData = new MatTableDataSource(currentTabData);
  }

  ngOnDestroy(): void {
    this.allUsersFromSchoolSubject$.unsubscribe();
  }

}