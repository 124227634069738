<div class="">
    <div class="flex">
        <div class="flex-shrink-0">
            <!-- <div class="orb"></div> -->
            <div class="orb">
                <!-- <div class="shine"></div> -->
                <div class="text-white text-center pt-9">
                    <p class="text-2xl font-medium">00:{{count<10?'0'+count:count}}</p>
                    <span class="text-xs" style="opacity: 0.5;">{{'seconds' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="pt-4 pb-8">
            <p class="text-warn text-base font-medium mb-4"><i class="icon icon-warning2 mr-1"></i>{{"yourSessionIsAboutToExpire" | translate }}</p>
            <p class="text-base">{{ "youWillBeAutomaticallyLoggedOutFromYourCurrentSessionSoon" | translate}}</p>
        </div>
    </div>
    <div class="text-right">
        <button mat-flat-button color="primary" (click)="continue()" [disabled]="sessionRenewLoader">
            <mat-icon class="ml-2" *ngIf="sessionRenewLoader">
                <mat-spinner color="primary" diameter="20">
                </mat-spinner>
              </mat-icon>
            {{'keepMeLoggedIn' | translate}}
        </button>
        <button mat-flat-button color="primary" class="ml-2" (click)="logout()">{{'logout' | translate}}</button>
    </div>
</div>


