import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'UserNameFormatPipe'
})
export class UserNameFormatPipe implements PipeTransform {
    transform(user: any): string {
        let userName: string = '-';
        if (!!(user?.lastFamilyName && user?.firstGivenName)) {
            if (!!user?.middleName) {
                userName = user?.lastFamilyName + ' ' + user?.middleName + ', ' + user?.firstGivenName;
            }
            else {
                userName = user?.lastFamilyName + ', ' + user?.firstGivenName;
            }
        }
        else if (!!user?.staffName) {
            let splitName = user?.staffName.trim().split(" ");
            let lastName = splitName[splitName.length - 1];
            let firstName = splitName[0];
            let middleName = "";
            if (splitName.length > 2) {
                middleName = splitName[splitName.length - 2];
                userName = lastName + ' ' + middleName + ', ' + firstName;
            }
            else {
                userName = lastName + ', ' + firstName;
            }
        }
        else {
            if (user?.staffLastFamilyName || user?.staffFirstGivenName) {
                if (!!user?.staffMiddleName) {
                    userName = user?.staffLastFamilyName + ' ' + user?.staffMiddleName + ', ' + user?.staffFirstGivenName;
                }
                else {
                    userName = user?.staffLastFamilyName + ', ' + user?.staffFirstGivenName;
                }
            }
            else userName = "-";
        }
        return userName;
    }
}