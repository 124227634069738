import { CommonField } from "./common-field.model";
import { PermissionGroup } from "./roll-based-access.model";

export class SendMailForResetPassword {
    public urlKey: string;
    public _tenantName:string;
    public password:string;
}

export class UserViewModel extends CommonField {
    
    public password : string;
    public email: string;
    public name: string;
    public middleName: string;
    public lastFamilyName: string;
    public membershipName : string;
    public membershipType: string;
    public membershipId:number;
    public onlinePaymentService: boolean;
    public userId?:  number;
    public userMasterId?: number;
    public userGuid:  string;
    public tenantId: string;
    public userPhoto: string;
    public schoolId:number;
    public permissionList: PermissionGroup[] | any;
    public createdBy: string;
    public createdOn: string;
    public updatedOn: string;
    public updatedBy: string;
    public firstGivenName:string;
    public lastUsedSchoolId:number;
    public userAccessLog: UserAccessLogModel;
    public ssoEnabled: boolean;
    constructor() {
        super();
        // this.tenantId ="1E93C7BF-0FAE-42BB-9E09-A1CEDC8C0355";
        this.userId = 0;
        this.userAccessLog=new UserAccessLogModel();
    }
}
class UserAccessLogModel{
    ipaddress:string;
    Emailaddress: string;
}
export class CheckUserEmailAddressViewModel extends CommonField {
    public tenantId: string;
    public emailAddress: string;
    public isValidEmailAddress: boolean;
}

export class DataAvailablity{
    schoolLoaded:boolean;
    schoolChanged:boolean;
    dataFromUserLogin:boolean;
    academicYearChanged: boolean;
    academicYearLoaded: boolean;
}

export class MarkingPeriodAvailability{
    markingPeriodLoaded:boolean;
    markingPeriodChanged:boolean;
}

export class UserLogoutModel extends CommonField {
    email: string;
    userMasterId:number;
    constructor() {
        super();
    }
}

export class UserImpersonationModel extends CommonField {
    password: string;
    email: string;
    name: string;
    middleName: string;
    lastFamilyName: string;
    membershipName: string;
    membershipType: string;
    membershipId: number;
    userId?: number;
    userMasterId?:number;
    userGuid: string;
    tenantId: string;
    userPhoto: string;
    schoolId: number;
    permissionList: PermissionGroup[] | any;
    createdBy: string;
    createdOn: string;
    updatedOn: string;
    updatedBy: string;
    firstGivenName: string;
    lastUsedSchoolId: number;
    userAccessLog: UserAccessLogModel;
    ssoEnabled: any;
    constructor() {
        super();
        this.password = null;
        this.userAccessLog = null;
        this.userId = 0;
    }
}