import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'courseRequestFilter'
})
export class CourseRequestFilterPipe implements PipeTransform {

    transform(value: any, searchTerm: any, filterFlag: boolean) {
        if (value?.length === 0) {
            return value;
        }
        if (searchTerm) {
            if (filterFlag) {
                return value?.map(item => {
                    return {
                        ...item,
                        getCourseSectionForView: item?.getCourseSectionForView?.filter(subItem =>

                            subItem?.courseSection?.courseSectionName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||

                            subItem?.courseFixedSchedule?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            subItem?.courseCalendarSchedule?.some(p => p?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            subItem?.courseBlockSchedule?.some(p => p?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            subItem?.courseVariableSchedule?.some(p => p?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase())) ||

                            subItem?.courseFixedSchedule?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            subItem?.courseCalendarSchedule?.some(p => p?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            subItem?.courseBlockSchedule?.some(p => p?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                            subItem?.courseVariableSchedule?.some(p => p?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase()))

                        )
                    };
                })?.filter(item =>
                    item.courseCategory?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.courseTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.courseSubject?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.courseProgram?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item?.getCourseSectionForView?.length > 0
                );
            }
            else {
                return value?.filter(subItem =>
                    subItem?.courseSection?.courseSectionName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||

                    subItem?.courseFixedSchedule?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    subItem?.courseCalendarSchedule?.some(p => p?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    subItem?.courseBlockSchedule?.some(p => p?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    subItem?.courseVariableSchedule?.some(p => p?.blockPeriod?.periodTitle?.toLowerCase().includes(searchTerm.toLowerCase())) ||

                    subItem?.courseFixedSchedule?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    subItem?.courseCalendarSchedule?.some(p => p?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    subItem?.courseBlockSchedule?.some(p => p?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    subItem?.courseVariableSchedule?.some(p => p?.rooms?.title?.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
        } else {
            return value;
        }


    }

}