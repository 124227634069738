import { Component, OnInit } from '@angular/core';
import { DefaultValuesService } from 'src/app/common/default-values.service';

@Component({
  selector: 'vex-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit {
  websiteUrl: string;

  constructor(
    public defaultValueService: DefaultValuesService
  ) { }

  ngOnInit(): void {    
   this.websiteUrl = window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2];
  }

}
