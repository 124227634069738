<div class="footer md:bg-white md:shadow border-t opacity-50 md:opacity-100">
  <div class="flex justify-between pt-3 items-center">
    <div [innerHTML]="tenantFooter"></div>
    <!-- <div *ngIf="releaseNumberAddViewModel?.releaseNumber?.releaseNumber1">
      <p class="text-primary">
        <span>Version {{releaseNumberAddViewModel?.releaseNumber?.releaseNumber1}}</span>
      </p>
    </div> -->
    <a href="https://www.os4ed.com/" target="_blank" class="footer-logo"><img src="assets/img/OS4ED_logo.png" alt="" class="w-8"></a>
  </div>
</div>