import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DefaultValuesService } from '../common/default-values.service';
import { CommonField } from '../models/common-field.model';
import { AddOfflinePayment, DowngradeSubscriptionModel, GetCardListModel, GetInvoiceListModel, GetSubscriptionDetailsModel, UpdateCardModel, UpdateSubscriptionModel, UpdateSubscritonDataInDatabaseModel , ACHPaymentModel , GetProratedAmount, UpdateStripeACHPaymentInZohoModel, GetInvoiceDetailsModel, UpdatePaymentModeModel, ACHPaymentStatusModel, CancelSubscriptionModel} from '../models/subscription.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  apiUrl: string = environment.apiURL;
  httpOptions: { headers: HttpHeaders; };

  constructor(private http: HttpClient, private defaultValuesService: DefaultValuesService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      })
    }
   }

   getCustomerDetails(customerDetailsModel){
    customerDetailsModel = this.defaultValuesService.getAllMandatoryVariable(customerDetailsModel);
    let apiurl = this.apiUrl + customerDetailsModel._tenantName + "/Zoho/getCustomerDetails";
    return this.http.post(apiurl, customerDetailsModel,this.httpOptions);
  }

  updateClientProfile(updateClientProfileModel){
    updateClientProfileModel = this.defaultValuesService.getAllMandatoryVariable(updateClientProfileModel);
    let apiurl = this.apiUrl + updateClientProfileModel._tenantName + "/Zoho/updateClientProfile";
    return this.http.post(apiurl, updateClientProfileModel,this.httpOptions);
  }

  updateZohoSubscription(obj: UpdateSubscriptionModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/updateSubscription';
    return this.http.post<UpdateSubscriptionModel>(apiurl, obj,this.httpOptions);
  }

  getAllPlanList() {
    const obj = this.defaultValuesService.getAllMandatoryVariable(new CommonField());
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/getPlanList';
    return this.http.post<any>(apiurl, obj,this.httpOptions);
  }

  updateSubscritonDataInDatabase(obj:UpdateSubscritonDataInDatabaseModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/updateSubscritonDataInDatabase';
    return this.http.post<UpdateSubscritonDataInDatabaseModel>(apiurl, obj,this.httpOptions);
  }

  downgradeSubscription(obj:DowngradeSubscriptionModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/downgradeSubscription';
    return this.http.post<DowngradeSubscriptionModel>(apiurl, obj,this.httpOptions);
  }

  getUSerCount() {
    const obj = this.defaultValuesService.getAllMandatoryVariable(new CommonField());
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/getUSerCount';
    return this.http.post(apiurl, obj,this.httpOptions);
  }

  getInvoiceList(obj: GetInvoiceListModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.customerId = this.defaultValuesService.getTenantData().zohoClientId;
    let apiurl = this.apiUrl + obj._tenantName + "/Zoho/getInvoiceList";
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  getInvoiceDetails(obj: GetInvoiceDetailsModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Zoho/getInvoiceDetails";
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  getCardList(obj: GetCardListModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.customerId = this.defaultValuesService.getTenantData().zohoClientId;
    let apiurl = this.apiUrl + obj._tenantName + "/Zoho/getCardList";
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  updateCard(obj: UpdateCardModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.subscriptionId = this.defaultValuesService.getTenantData().zohoSubscriptionId;
    let apiurl = this.apiUrl + obj._tenantName + "/Zoho/updateCard";
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  updatePaymentMode(obj: UpdatePaymentModeModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Zoho/updatePaymentMode";
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  getSubscriptionDetails(obj: GetSubscriptionDetailsModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.subscriptionId = this.defaultValuesService.getTenantData().zohoSubscriptionId;
    let apiurl = this.apiUrl + obj._tenantName + "/Zoho/getSubscriptionDetails";
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  getProratedAmount(obj: GetProratedAmount) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.subscriptionId = this.defaultValuesService.getTenantData().zohoSubscriptionId;
    obj.customerId = this.defaultValuesService.getTenantData().zohoClientId;
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/getProratedAmount';
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  achPayment(obj: ACHPaymentModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.subscriptionId = this.defaultValuesService.getTenantData().zohoSubscriptionId
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/achPayment';
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  achPaymentStatus(obj: ACHPaymentStatusModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/ACHPaymentStatus';
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  updateStripeACHPaymentInZoho(obj: UpdateStripeACHPaymentInZohoModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.subscriptionId = this.defaultValuesService.getTenantData().zohoSubscriptionId;
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/updateStripeACHPaymentInZoho';
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  addOfflinePayment(obj: AddOfflinePayment) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    // obj.subscriptionId = this.defaultValuesService.getTenantData().zohoSubscriptionId;
    obj.offlinePaymentDetail.tenantId =this.defaultValuesService.getTenantID();
    let apiurl = this.apiUrl + obj._tenantName + "/Zoho/addOfflinePayment";
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

  cancelSubscription(obj: CancelSubscriptionModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.subscriptionId = this.defaultValuesService.getTenantData().zohoSubscriptionId;
    const apiurl = this.apiUrl + obj._tenantName + '/Zoho/CancelSubscription';
    return this.http.post<any>(apiurl, obj, this.httpOptions);
  }

}
