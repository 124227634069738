import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icHelp from '@iconify/icons-ic/help';
import icSearch from '@iconify/icons-ic/search';
import icDropdown from '@iconify/icons-ic/arrow-drop-down';
import { DefaultValuesService } from 'src/app/common/default-values.service';
import { TranslateService } from '@ngx-translate/core';
import { CryptoService } from 'src/app/services/Crypto.service';
import { PageDataModel } from 'src/app/models/common.model';
import { MatDialog } from '@angular/material/dialog';
import { PlanUpgradeComponent } from 'src/app/common/plan-upgrade/plan-upgrade.component';
import { ProfilesTypes } from 'src/app/enums/profiles.enum';
declare function openWidget();

@Component({
  selector: 'vex-secondary-toolbar',
  templateUrl: './secondary-toolbar.component.html',
  styleUrls: ['./secondary-toolbar.component.scss']
})
export class SecondaryToolbarComponent implements OnInit {

  icHelp = icHelp;
  icSearch = icSearch;
  icDropdown = icDropdown;
  @Input() pages: PageDataModel[] = [];
  @Input() schoolSettings: boolean = false;
  @Output() selectedPage = new EventEmitter<string>();
  @Input() current: string;
  @Input() crumbs: string[];
  selectedValue:string;
  isAdmin: boolean = false;
  ishide=false;
  fixed$ = this.configService.config$.pipe(
    map(config => config.toolbar.fixed)
  );

  constructor(private configService: ConfigService, public defaultValuesService: DefaultValuesService,
    public translateService:TranslateService,private cryptoService:CryptoService,    
    private dialog: MatDialog
    ) {
      if (this.defaultValuesService.getUserMembershipType() === ProfilesTypes.SuperAdmin || 
      this.defaultValuesService.getUserMembershipType() === ProfilesTypes.AdminAssitant ||
      this.defaultValuesService.getUserMembershipType() === ProfilesTypes.SchoolAdmin) {
        this.isAdmin = true;
      }
      else{
        this.isAdmin = false;
      }
   }
  
  ngOnInit() {
    this.selectedValue = this.defaultValuesService.getPageData()?.displayName;
    let url =window.location.href;
    if(url.includes('school/dashboards'))
      this.ishide=true
    else 
      this.ishide=false

  }
  
  changePage(page) {
    let permissions = this.defaultValuesService.getPermissionList().permissionList;
    permissions.forEach(element => {
      element.permissionGroup.permissionCategory.forEach((item, index) => {
          item.permissionSubcategory.forEach((i, index) => {
            if(i.path === page.path){
              if(i.isAccess === false){
                this.dialog.open(PlanUpgradeComponent, {
                  width: '500px',
                  disableClose: true
                });
              } else {
                this.selectedValue = page.displayName;
                this.defaultValuesService.setPageData(page);
                this.selectedPage.emit(page);
              }
            } 
          });
      })
    });



    
  }

  openHelpDesk() {
    openWidget();
  }

  openWhatsApp() {
    let link = 'https://wa.me/+14074136500?text=Hello,%20I%20am%20'+this.defaultValuesService.getFullUserName()+'.%20My%20subdomain%20is%20'+ this.defaultValuesService.getTenantName()+'.';
    window.open(link, '_blank')
  }
}
