import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard as AuthGuard } from '../app/common/auth.guard';
import { RolePermissionGuard } from './common/role-permission.guard';
import { SchoolSetupGuard } from './common/school-setup.guard';
import { SubscriptionGuard } from './common/subcription.guard';
import { StaticPageComponent } from './pages/static-page/static-page.component';
import { LoginComponent } from './pages/auth/login/login.component';
import path from 'path';
const routes: Routes = [
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/auth/logout/logout.module').then(m => m.LogoutModule),
  },
  {
    path: 'admsn',
    loadChildren: () => import('./pages/admission-form/admission-form.module').then(m => m.AdmissionFormModule),
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/billing-and-fees/get-payment/get-payment.module').then(m => m.GetPaymentModule),
  },
  {
    path: 'cancel-subscription',
    component: StaticPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule),
  },

  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },

  {
    path: 'impersonate',
    loadChildren: () => import('./pages/auth/impersonate/impersonate.module').then(m => m.ImpersonateModule),
  },

  {
    path: 'manager-login',
    loadChildren: () => import('./pages/super-admin/login/login.module').then(m => m.LoginModule),
  },  
  {
    path: 'school',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate: [AuthGuard, RolePermissionGuard , SubscriptionGuard]
      },
      {
        path: 'error',
        loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule),
      },
      {
        path: 'teacher',
        children: [
          {
            path: 'dashboards',
            loadChildren: () => import('./pages/dashboards/teacher-dashboard/teacher-dashboard.module').then(m => m.TeacherDashboardModule),
            canActivate: [AuthGuard]
          }
        ]

      },
      {
        path: 'student',
        children: [
          {
            path: 'dashboards',
            loadChildren: () => import('./pages/dashboards/student-dashboard/student-dashboard.module').then(m => m.StudentDashboardModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'fees-and-charges',
            loadChildren: () => import('./pages/billing-and-fees/student-fees-and-charges/student-fees-and-charges.module').then(m => m.StudentFeesAndChargesModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          },
          {
            path: 'my-subscriptions',
            loadChildren: () => import('./pages/billing-and-fees/student-my-subscriptions/student-my-subscriptions.module').then(m => m.StudentMySubscriptionsModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]

      },
      {
        path: 'parent',
        children: [
          {
            path: 'dashboards',
            loadChildren: () => import('./pages/dashboards/parent-dashboard/parent-dashboard.module').then(m => m.ParentDashboardModule),
            canActivate: [AuthGuard]
          }
        ]

      },
      {
        path: '',
        children: [
          {
            path: 'schoolinfo',
            loadChildren: () => import('./pages/school/school-details/school-details/school-details.module').then(m => m.SchoolDetailsModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'inactive-module',
            loadChildren: () => import('./pages/inactive-module/inactive-module.module').then(m => m.InactiveModuleModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'marking-periods',
            loadChildren: () => import('./pages/school/marking-periods/marking-periods.module').then(m => m.MarkingPeriodsModule),
            canActivate: [AuthGuard, RolePermissionGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'schoolcalendars',
            loadChildren: () => import('./pages/school/calendar/calendar.module').then(m => m.CalendarModule),
            canActivate: [AuthGuard, RolePermissionGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'notices',
            loadChildren: () => import('./pages/school/notices/notices.module').then(m => m.NoticesModule),
            canActivate: [AuthGuard, RolePermissionGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'students',
            loadChildren: () => import('./pages/student/student.module').then(m => m.StudentModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'parents',
            loadChildren: () => import('./pages/parent/parentinfo/parentinfo.module').then(m => m.ParentinfoModule),
            canActivate: [AuthGuard, RolePermissionGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'staff',
            loadChildren: () => import('./pages/staff/saff.module').then(m => m.StaffModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'course-manager',
            loadChildren: () => import('./pages/courses/course-manager/course-manager.module').then(m => m.CourseManagerModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: 'billing-and-fees',
        children: [
          {
            path: 'coming-soon',
            loadChildren: () => import('./pages/billing-and-fees/billing-demo/billing-demo.module').then(m => m.BillingDemoModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./pages/billing-and-fees/billing-dashboard/billing-dashboard.module').then(m => m.BillingDashboardModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          },
          {
            path: 'assign-fee-and-request-money',
            loadChildren: () => import('./pages/billing-and-fees/transactions/assign-fee-and-request-money/assign-fee-and-request-money.module').then(m => m.AssignFeeAndRequestMoneyModule),
          },
          {
            path: 'assign-subscriptions',
            loadChildren: () => import('./pages/billing-and-fees/subscriptions-associate/subscriptions-associate-routing.module').then(m => m.SubscriptionsAssociateRoutingModule),
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'schedule-teacher',
            loadChildren: () => import('./pages/scheduling/schedule-teacher/schedule-teacher.module').then(m => m.ScheduleTeacherModule),
            canActivate: [AuthGuard, RolePermissionGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'settings',
            loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
            canActivate: [AuthGuard, RolePermissionGuard, SubscriptionGuard]

          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'schedule-student',
            loadChildren: () => import('./pages/scheduling/schedule-student/schedule-student.module').then(m => m.ScheduleStudentModule),
            canActivate: [AuthGuard, RolePermissionGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'group-drop',
            loadChildren: () => import('./pages/scheduling/group-drop/group-drop.module').then(m => m.GroupDropModule),
            canActivate: [AuthGuard, RolePermissionGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'teacher-reassignment',
            loadChildren: () => import('./pages/scheduling/teacher-reassignment/teacher-reassignment.module').then(m => m.TeacherReassignmentModule),
            canActivate: [AuthGuard, RolePermissionGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          // {
          //   path: 'take-attendance',
          //   loadChildren: () => import('./pages/attendance/teacher-function/take-attendance/take-attendance.module').then(m => m.TakeAttendanceModule),
          //   // canActivate: [AuthGuard]            
          // }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'student/student-re-enroll',
            loadChildren: () => import('./pages/student/student-re-enroll/student-re-enroll.module').then(m => m.StudentReEnrollModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          // {
          //   path: 'staff/teacher-functions',
          //   loadChildren: () => import('./pages/attendance/teacher-function/teacher-function.module').then(m => m.TeacherFunctionModule),
          // canActivate: [AuthGuard]         
          // }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'student-course-schedule',
            loadChildren: () => import('./pages/scheduling/student-my-schedule/student-my-schedule.module').then(m => m.StudentMyScheduleModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: 'attendance',
        children: [
          {
            path: 'administration',
            loadChildren: () => import('./pages/attendance/administration/administration.module').then(m => m.AdministrationModule),
          },
          {
            path: 'add-absences',
            loadChildren: () => import('./pages/attendance/add-absences/add-absences.module').then(m => m.AddAbsencesModule),
          },
          {
            path: 'recalculate-daily-attendance',
            loadChildren: () => import('./pages/attendance/recalculate-daily-attendance/recalculate-daily-attendance.module').then(m => m.RecalculateDailyAttendanceModule),
          },
          {
            path: 'missing-attendance',
            loadChildren: () => import('./pages/attendance/missing-attendance/missing-attendance.module').then(m => m.MissingAttendanceModule),
          },
          {
            path: 'daily-attendance',
            loadChildren: () => import('./pages/attendance/daily-attendance-student/daily-attendance-student.module').then(m => m.DailyAttendanceStudentModule),
          }
        ],
        canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
      },
      {
        path: '',
        children: [
          {
            path: 'teacher-dashboard',
            loadChildren: () => import('./pages/dashboards/teacher-dashboard/teacher-dashboard.module').then(m => m.TeacherDashboardModule),
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'class',
            loadChildren: () => import('./pages/class/class.module').then(m => m.ClassModule),
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'grades',
        children: [
          {
            path: 'report-cards',
            loadChildren: () => import('./pages/grades/report-cards/report-cards.module').then(m => m.ReportCardsModule),
          },
          {
            path: 'student-report-card',
            loadChildren: () => import('./pages/grades/report-cards-student/report-cards-student.module').then(m => m.ReportCardsStudentModule),
          },
          {
            path: 'student-transcript',
            loadChildren: () => import('./pages/grades/transcripts-student/transcripts-student.module').then(m => m.TranscriptsStudentModule),
          },
          {
            path: 'certificates',
            loadChildren: () => import('./pages/grades/certificates/certificates.module').then(m => m.CertificatesModule),
          },
          {
            path: 'transcripts',
            loadChildren: () => import('./pages/grades/transcripts/transcripts.module').then(m => m.TranscriptsModule),
          },
          {
            path: 'input-effort-grades',
            loadChildren: () => import('./pages/grades/input-effort-grades/input-effort-grades.module').then(m => m.InputEffortGradesModule),
          },
          {
            path: 'administration',
            loadChildren: () => import('./pages/grades/administration/administration.module').then(m => m.AdministrationModule),
          },
          {
            path: 'progress-reports',
            loadChildren: () => import('./pages/grades/progress-reports/progress-reports.module').then(m => m.ProgressReportsModule),
          },
          {
            path: 'anomalous-grades',
            loadChildren: () => import('./pages/grades/anomalous-grades/anomalous-grades.module').then(m => m.AnomalousGradesModule),
          },
          {
            path: 'gradebook-grades',
            loadChildren: () => import('./pages/grades/gradebook-grades/gradebook-grades.module').then(m => m.GradebookGradesModule),
          }
        ],
        canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
      },
      {
        path: '',
        children: [
          {
            path: 'teacher/schedule',
            loadChildren: () => import('./pages/scheduling/teacher-view-schedule/teacher-view-schedule.module').then(m => m.TeacherViewScheduleModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'reports',
            loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: 'tools',
        loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsModule),
        canActivate: [AuthGuard, SubscriptionGuard]
      },
      {
        path: '',
        children: [
          {
            path: 'student/group-assign-student-info',
            loadChildren: () => import('./pages/student/group-assign-student-info/group-assign-student-info.module').then(m => m.GroupAssignStudentInfoModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'attendance/teacher-missing-attendance',
            loadChildren: () => import('./pages/attendance/teacher-missing-attendance/teacher-missing-attendance.module').then(m => m.TeacherMissingAttendanceModule),
            canActivate: [AuthGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'courses/course-catalog',
            loadChildren: () => import('./pages/courses/course-catalog/course-catalog.module').then(m => m.CourseCatalogModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'courses/my-classes',
            loadChildren: () => import('./pages/courses/my-classes/my-classes.module').then(m => m.MyClassesModule),
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'coming-soon',
            loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
            canActivate: [AuthGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'attendance/absence-summary',
            loadChildren: () => import('./pages/attendance/absence-summary/absence-summary.module').then(m => m.AbsenceSummaryModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'attendance/daily-attendance',
            loadChildren: () => import('./pages/attendance/daily-attendance/daily-attendance.module').then(m => m.DailyAttendanceModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: 'scheduling',
        children: [
          {
            path: 'group-delete',
            loadChildren: () => import('./pages/scheduling/group-delete/group-delete.module').then(m => m.GroupDeleteModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          },
          {
            path: 'student-reassignment',
            loadChildren: () => import('./pages/scheduling/student-reassignment/student-reassignment.module').then(m => m.StudentReassignmentModule),
            canActivate: [AuthGuard, SchoolSetupGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'communication',
            loadChildren: () => import('./pages/communication/messaging-box/messaging-box.module').then(m => m.MessagingBoxModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'district-enterprise/system-hierarchy',
            loadChildren: () => import('./pages/district-enterprise/system-hierarchy/system-hierarchy.module').then(m => m.SystemHierarchyModule),
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'clients',
            loadChildren: () => import('./pages/super-admin/clients/clients.module').then(m => m.ClientsModule),
            canActivate: [AuthGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'site-managers',
            loadChildren: () => import('./pages/super-admin/site-managers/site-managers.module').then(m => m.SiteManagersModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'sendGrid-settings',
            loadChildren: () => import('./pages/super-admin/sendgrid-settings/sendgrid-settings.module').then(m => m.SendgridSettingsModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'my-subscription',
            loadChildren: () => import('./pages/my-subscription/my-subscription.module').then(m => m.MySubscriptionModule),
            canActivate: [AuthGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'mental-health-info',
            loadChildren: () => import('./pages/school/mental-health-info/mental-health-info.module').then(m => m.MentalHealthInfoModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'behaviour/behaviour-fields',
            loadChildren: () => import('./pages/behaviour/behaviour-fields/behaviour-fields.module').then(m => m.BehaviourFieldsModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'behavior/referrals',
            loadChildren: () => import('./pages/behaviour/referrals/referrals.module').then(m => m.ReferralsModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'student-behavior',
            loadChildren: () => import('./pages/behaviour/behaviour-student/behaviour-student.module').then(m => m.BehaviourStudentModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'courses/course-request-list',
            loadChildren: () => import('./pages/courses/course-request-student-list/course-request-student-list.module').then(m => m.CourseRequestStudentListModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ],
      },

      {
        path: '',
        children: [
          {
            path: 'courses/course-request',
            loadChildren: () => import('./pages/courses/course-request/course-request.module').then(m => m.CourseRequestModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'courses/course-enrollment-status',
            loadChildren: () => import('./pages/courses/course-enrollment-status/course-enrollment-status.module').then(m => m.CourseEnrollmentStatusModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'courses/student-course-requests',
            loadChildren: () => import('./pages/courses/student-course-requests/student-course-requests.module').then(m => m.StudentCourseRequestsModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },

      {
        path: '',
        children: [
          {
            path: 'lesson-plan',
            loadChildren: () => import('./pages/lesson-plan/lesson-plan.module').then(m => m.LessonPlanModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'billing-and-fees',
            loadChildren: () => import('./pages/settings/billing-and-fees-settings/billing-and-fees-settings.module').then(m => m.BillingAndFeesSettingsModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path:'',
        children:[
          {
            path:'lms',
            loadChildren: () => import('./pages/lms/lms.module').then(m => m.LmsModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'settings/lms-settings',
            loadChildren: () => import('./pages/settings/learning-management-system-settings/learning-management-system-settings.module').then(m => m.LearningManagementSystemSettingsModule),
            canActivate: [AuthGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: 'settings/admissions-settings',
            loadChildren: () => import('./pages/settings/admissions-settings/admissions-settings.module').then(m => m.AdmissionsSettingsModule),
            canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
          }
        ]
      },
      {
        path: 'admissions',
        children: [
          {
            path: 'admissions-dashboard',
            loadChildren: () => import('./pages/admissions/admissions-dashboard/admissions-dashboard.module').then(m => m.AdmissionsDashboardModule),
          },
          {
            path: 'applicants',
            loadChildren: () => import('./pages/admissions/applicants/applicants.module').then(m => m.ApplicantsModule),
          },
          {
            path: 'email-templates',
            loadChildren: () => import('./pages/admissions/email-templates/email-templates.module').then(m => m.EmailTemplatesModule),
          },
          {
            path: 'application-forms',
            loadChildren: () => import('./pages/admissions/application-forms/application-forms.module').then(m => m.ApplicationFormsModule),
          }
        ],
        canActivate: [AuthGuard, SchoolSetupGuard, SubscriptionGuard]
      },
      
    ]
  },
  { path: '**', redirectTo: '/error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
