import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mentalHealthFilter'
})
export class MentalHealthFilterPipe implements PipeTransform {

    transform(value: any, searchTerm: any) {
    if (value?.length === 0) {
      return value;
    } else {
      if (searchTerm && value?.length) {
        return value.filter((term) => {
          return term.body?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            || term.targetMembershipIds?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            || term.title?.toString().indexOf(searchTerm.toString()) > -1
        })
      } else {
        return value;
      }
    }
  }

}
