<div class="toolbar w-full px-gutter flex justify-between items-center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a [routerLink]="['/school/dashboards']"
     class="ltr:ml-2 rtl:ml-2 small-mobile-logo">
    <!-- <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1> -->
    <img [src]="'data:image/jpeg;base64,'+tenantLogoIcon" class="w-8 select-none" [alt]="tenantName+' Logo'">
    <!-- <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none"><img src="assets/img/opensistext.png"></h1> -->
  </a>

  <div class="items-right">
      <!-- <vex-select-bar></vex-select-bar> -->
      <!-- <vex-select-search-bar></vex-select-search-bar> -->
      <vex-select-bar @stagger (schoolChanged)="schoolChanged($event)"></vex-select-bar>
  </div>

  <div #megaMenuOriginRef class="rtl:mr-2">
    <div class="-mx-1 flex items-center">
      <button class="border border-gray-light rounded-full h-9 px-4 leading-9 flex items-center justify-center shadow-md"
              type="button" matTooltip="{{'backTo' | translate}} {{impersonateServices.getAdminStaffCredentials() ? (impersonateServices.getAdminStaffCredentials().userMembershipType | translate): (impersonateServices.getSuperAdminCredentials().userMembershipType | translate) }} " *ngIf="defaultValuesService.getImpersonateButton()" (click)="backToSuperAdmin()" ><mat-icon class="inline-block" >undo</mat-icon>
              <span class="hidden md:inline-block ml-2">{{'back' | translate}}</span>
      </button>
      <!-- Back to Super Admin -->
      <button class="border border-normal rounded-full h-9 w-9 leading-9 flex items-center justify-center shadow-md ml-3 relative notifications"
      type="button" (click)="openNotifications('announcements')" *ngIf="defaultValuesService.getUserMembershipType()!='Student' && defaultValuesService.getUserMembershipType() != 'Parent'">
        <mat-icon [icIcon]="icCampaign" class="inline-block"></mat-icon>
      </button>
      <button class="border border-normal rounded-full h-9 w-9 leading-9 flex items-center justify-center shadow-md ml-3 relative notifications"
      type="button" (click)="openNotifications('general-notifications')">
        <mat-icon [icIcon]="icNotifications" class="inline-block"></mat-icon>
        <span class="absolute inset-auto bg-red h-4 min-w-4 px-1 text-white inline-block rounded-full text-xxs leading-normal -top-2 -right-1" *ngIf="generalNotificationUnreadCount > 0">{{generalNotificationUnreadCount}}</span>
        <!-- <span class="absolute inset-auto bg-red h-3 w-3 text-white inline-block rounded-full text-xxs leading-normal top-0 -right-1" [ngClass]="notificationAnimation?'notification-animation':''" *ngIf="hasRecentNotification"></span> -->
      </button> 
      <!-- <button class="border border-gray-light rounded-full h-9 w-9 leading-9 flex items-center justify-center shadow-md ml-3"
      type="button"><mat-icon class="inline-block">search</mat-icon>
      </button>  -->
     <div *ngIf="isSuperAdmin || defaultValuesService.getUserMembershipType() === profile.SchoolAdmin || defaultValuesService.getUserMembershipType() === profile.AdminAssitant" >
      <button *ngIf="isHorizontalLayout$ | async"
              (click)="openMegaMenu(megaMenuOriginRef)"
              class="global-add-button shadow-md"
              type="button" ><ic-icon [icon]="icAdd" class="ic-inline inline-block" inline="true"></ic-icon>
      </button>
    </div>
    <div [matMenuTriggerFor]="addNewMenu" class="cursor-pointer">
      <img class="h-9 w-9 rounded-full ml-3 shadow-md border-2 border-white" [src]="userPhoto" alt="Profile picture">
    </div>
    <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
      <div class="px-4 pt-1 pb-3">
        <h3 class="text-blue font-semibold">{{this.defaultValuesService.getFullUserName()}}</h3>
        <p class="text-gray">{{defaultValuesService.getuserMembershipName() | translate}}</p>
      </div>
      <div class="mt-2 mb-4 px-4" *ngIf="defaultValuesService.getTenantData().workEmailId === defaultValuesService.getEmailId()">
        ({{this.defaultValuesService.getStaffCount()}} / {{this.defaultValuesService.getTenantData().quantity}}) {{'staffInUse' | translate}}
        <mat-progress-bar value={{percentageCalculate()}} class="w-full"></mat-progress-bar>
      </div>
      <hr>
      <!-- <button mat-menu-item *ngIf="defaultValuesService.getUserMembershipType() === profile.SuperAdmin || defaultValuesService.getUserMembershipType() === profile.SchoolAdmin" (click)="showPreference()">
        <mat-icon class="material-icons-two-tone">manage_accounts</mat-icon>
        <span>{{'preferences' | translate}}</span>
      </button> -->

      <button mat-menu-item (click)="showMySubscription()" 
        *ngIf="defaultValuesService.getTenantData().workEmailId === defaultValuesService.getEmailId(); else elseButton">
        <mat-icon class="material-icons-two-tone">manage_accounts</mat-icon>
        <span>{{'myAccountAndSubscription' | translate}}</span>
      </button>

      <ng-template #elseButton>
        <button mat-menu-item (click)="showMyAccount()">
          <mat-icon>account_circle</mat-icon>
          <span>{{'myAccount' | translate}}</span>
        </button>
      </ng-template>

      <button mat-menu-item (click)="openChangePassword()">
        <mat-icon>lock_open</mat-icon>
        <span>{{'changePassword' | translate}}</span>
      </button>
      
      <button mat-menu-item (click)="logOut()">
        <mat-icon>logout</mat-icon>
        <span>{{'logout' | translate}}</span>
      </button>
    </mat-menu>
      <!-- <div class="px-1 ml-3">
        <vex-toolbar-notifications></vex-toolbar-notifications>
      </div> -->
    </div>
  </div>

  <!-- <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="start center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div> -->
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>

<div class="fixed top-0 right-0 w-full h-full z-40" *ngIf="showNotificationsPanel">
  <div class="notification-panel absolute right-0 bg-white w-full sm:w-4/6 md:w-3/6 lg:w-4/12 xl:w-3/12 h-full z-20 shadow-md" @fadeInRight>
      <vex-notifications [notificationsList]="notificationsList" [notificationType]="notificationType" [generalNotificationsList]="generalNotificationsList" [pageNumber]="pageNumber" [pageSize]="pageSize" [totalCount]="totalCount" (showHideNotifications)="hideNotifications($event)" (hasRecentNotification)="removeRecentNotificationFlag()" (udpateNotificationUnreadCount)="udpateNotificationUnreadCount($event)"></vex-notifications>
  </div>
</div>

<div class="general-notification-popup fixed top-12 right-5 rounded z-50 pr-6 border border-gray-light bg-white shadow px-4 py-3" *ngIf="latestNotificationText">
  <a (click)="closeNotificationPopup()" href="javascript:void(0);" class="absolute top-2 right-0 inline-block cursor-pointer"><mat-icon class="text-sm">close</mat-icon></a>
  <div class="flex items-center">
    <div class="mr-3">
        <img src="assets/img/icons/icon-notification-bell.svg" alt="Upgrade Icon" class="w-8"/>
    </div>
    <div class="flex-1 cursor-pointer whitespace-normal">
      <h3 class="text-green">{{"newNotification" | translate}}!</h3>
      <p>{{latestNotificationText}}</p>
      <a class="text-blue cursor-pointer font-bold mt-2" (click)="openNotifications('general-notifications'); closeNotificationPopup();">{{"viewAll" | translate}}</a>
        <!-- <h5>{{popupNotification.releaseTitle}}</h5>
        <p class="text-xs opacity-50">{{popupNotification.releaseDate | date:defaultValuesService.getSchoolDateFormat()}}</p> -->
    </div>
  </div>
</div>

<div class="release-notification-popup fixed top-3 right-5 rounded z-50 pr-6 border border-gray-light bg-white px-4 py-3" *ngIf="popupNotification && this.defaultValuesService.getUserMembershipType()!='Student' && this.defaultValuesService.getUserMembershipType() != 'Parent'">
  <a (click)="closeReleasePopup(popupNotification.releaseDate)" href="javascript:void(0);" class="absolute top-2 right-0 inline-block cursor-pointer"><mat-icon class="text-sm">close</mat-icon></a>
  <div class="flex items-center">
    <div class="mr-3">
        <img src="assets/img/icon-upgrade.svg" alt="Upgrade Icon"/>
    </div>
    <div class="flex-1 cursor-pointer whitespace-normal">
      <h3 class="text-green">{{"newUpdate" | translate}}!</h3>
      <p>{{"weHaveUpdatedOpensisWhileYouWereOffline" | translate}}</p>
      <a class="text-blue cursor-pointer font-bold mt-2" (click)="openNotificationDetails(popupNotification)">{{"seeWhatsNew" | translate}}</a>
        <!-- <h5>{{popupNotification.releaseTitle}}</h5>
        <p class="text-xs opacity-50">{{popupNotification.releaseDate | date:defaultValuesService.getSchoolDateFormat()}}</p> -->
    </div>
</div>
</div>