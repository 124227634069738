<div class="flex justify-between px-6 py-4">
    <h4 class="font-medium text-lg">{{(notificationType === 'general-notifications'?'notifications':'announcements') | translate}}</h4>
    <a (click)="hideNotifications()" class="cursor-pointer">
        <mat-icon>close</mat-icon>
    </a>
</div>
<mat-divider></mat-divider>
<!-- <div class="custom-tab border-b border-t border-gray-light" *ngIf="this.defaultValuesService.getUserMembershipType()!='Student' && this.defaultValuesService.getUserMembershipType() != 'Parent'">
    <ul class="flex flex-row text-gray font-semibold">
        <li class="px-4 py-3 cursor-pointer flex"
            [ngClass]="currentTab === 'systemNotifications' ? 'text-black border-b-2 border-primary' : '' "
            (click)="changeTab('systemNotifications')">
            {{'systemNotifications' | translate}}
            <span class="bg-red text-white rounded text-xs py-1 px-2 inline-block ml-2">{{generalNotificationUnreadCount}}</span> 
        </li>
        <li class="px-4 py-3 cursor-pointer" (click)="changeTab('releaseNotes')"
            [ngClass]="currentTab === 'releaseNotes' ? 'text-black border-b-2 border-primary' : '' ">
            {{'releaseNotes' | translate}}
        </li>
    </ul>
</div> -->
<div class="notification-body" [ngClass]="this.defaultValuesService.getUserMembershipType() === 'Student' || this.defaultValuesService.getUserMembershipType() === 'Parent' ? 'student-portal':''">
    <div *ngIf="notificationType === 'general-notifications' ">
        <cdk-virtual-scroll-viewport #scroller [itemSize]="10" class="general-notifications flex flex-col h-full" [ngClass]="this.defaultValuesService.getUserMembershipType() === 'Student' || this.defaultValuesService.getUserMembershipType() === 'Parent' ? 'student-portal':''">
        <ul>
            <li *ngFor="let item of generalNotificationsList; let i = index" [ngClass]="item.isPinned?'bg-skyblue-light pinned':item.isRead?'bg-white':'bg-gray-verylight'">
                <div class="notification-item">
                    <div class="flex justify-between items-center">
                        <span class="module-badge text-white" [ngClass]="getClassName(item?.notificationMaster?.module)">{{item?.notificationMaster?.module}}</span>
                        <ng-container *ngIf="item.loading; else showIcon">
                            <img src="assets/img/icons/loading-process.svg" class="animate-spin w-5 mr-2 mt-1">
                        </ng-container>
                        <ng-template #showIcon>
                            <div class="flex items-center">
                                <span class="inline-block mr-1" *ngIf="item.isPinned" [matTooltip]="'thisNotificationIsPinned' | translate"><img src="assets/img/icons/notification-pushpin.svg"></span>
                                <button mat-icon-button [matMenuTriggerFor]="menu" class="-mr-3">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </div>
                        </ng-template>
                    </div>                    
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="pinUnpinNotification(item, i)" *ngIf="!item.isPinned" class="flex items-center">
                            <span class="ml-2">{{"pinNotification" | translate}}</span>
                        </button>
                        <button mat-menu-item (click)="pinUnpinNotification(item, i)" *ngIf="item.isPinned" class="flex items-center">
                            <span class="ml-2">{{"unpinNotification" | translate}}</span>
                        </button>
                        <button mat-menu-item (click)="deleteNotification(item, i)" class="flex items-center">
                            <span class="ml-2">{{"delete" | translate}}</span>
                        </button>
                    </mat-menu>
                    <div>                        
                        <h5 [ngClass]="item.isRead?'':'font-semibold'">{{item?.notificationMaster?.message}}</h5>
                        <p class="text-xs opacity-50">{{item?.notificationMaster?.timestamp | date:defaultValuesService.getSchoolDateFormat()}}
                    </div>
                </div>
            </li>
        </ul>
        <div>
            <div class="flex items-center justify-center py-4" *ngIf="totalCount > pageSize && generalNotificationsList.length < totalCount"><span class="flex items-center bg-skyblue-light rounded px-4 py-2"><img src="assets/img/icons/loading-process.svg" class="animate-spin w-5 mr-2" alt="Loading icon">{{'loading' | translate}}</span></div>
        </div>
        </cdk-virtual-scroll-viewport>
    </div>

    <div class="system-updates px-6 flex flex-col h-full" *ngIf="notificationType === 'announcements' && this.defaultValuesService.getUserMembershipType()!='Student' && this.defaultValuesService.getUserMembershipType() != 'Parent'">
        <div class="pt-4">
            <ng-container *ngFor="let list of notificationsList">
                <div class="flex items-center">
                    <div class="flex-shrink-0 mr-6">
                        <img src="assets/img/icon-upgrade.svg" alt="Upgrade Icon" />
                    </div>
                    <div class="cursor-pointer" (click)="openNotificationDetails(this.list)">
                        <h5 class="whitespace-normal">{{list.releaseTitle}}</h5>
                        <p class="text-xs opacity-50">{{list.releaseDate | date:defaultValuesService.getSchoolDateFormat()}}
                        </p>
                    </div>
                </div>
                <mat-divider class="my-2 -mx-6"></mat-divider>
            </ng-container>
        </div>
    </div>
</div>