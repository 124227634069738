import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import icClose from '@iconify/icons-ic/close';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultValuesService } from 'src/app/common/default-values.service';

@Component({
  selector: 'vex-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {

  icClose = icClose;


  constructor(
    public translateService: TranslateService, 
    private dialogRef: MatDialogRef<NotificationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public defaultValuesService: DefaultValuesService
    ) { }

  ngOnInit(): void {
   

  }

}
