import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[vexAnimatedCheckbox]'
})
export class AnimatedCheckboxDirective {

  @HostListener('mouseenter') onMouseEnter() {

    let toggleImage:ElementRef = this.el.nativeElement.querySelector(".toggle-image-div");
    this.renderer.addClass(toggleImage, 'hidden' );

    let toggleCheckbox:ElementRef = this.el.nativeElement.querySelector(".toggle-checkbox-div");
    this.renderer.removeClass(toggleCheckbox, 'hidden' );
    this.renderer.addClass(toggleCheckbox, 'block' );
  }
  
  @HostListener('mouseleave') onMouseLeave() {

    let toggleImage:ElementRef = this.el.nativeElement.querySelector(".toggle-image-div");
    this.renderer.removeClass(toggleImage, 'hidden' );
    this.renderer.addClass(toggleImage, 'block' );

    let toggleCheckbox:ElementRef = this.el.nativeElement.querySelector(".toggle-checkbox-div");
    this.renderer.removeClass(toggleCheckbox, 'block' );
    this.renderer.addClass(toggleCheckbox, 'hidden' );
  }

  constructor(private el: ElementRef, private renderer:Renderer2) {
    
 }

}
