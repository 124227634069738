<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="flex justify-between">
    <a *ngIf="defaultValuesService.getSourceName()" class="back-to-manager bg-black text-white cursor-pointer" (click)="logOut()"><mat-icon>chevron_left</mat-icon></a>
  <div class="sidenav-toolbar flex-none flex items-center justify-between">
    <!-- <img [src]="imageUrl$ | async" class="w-6 select-none flex-none">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">{{ title$ | async }}</h2> -->
    <!-- <img src="assets/img/logo-icon.png" class="w-10 select-none flex-none">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto text-white font-normal text-2xl">openSIS</h2> -->
    
      <img *ngIf="collapsed" [src]="'data:image/jpeg;base64,'+tenantLogoIcon" class="select-none logo-small flex-none" [alt]="tenantName+' Logo'">
      <img *ngIf="collapsed" [src]="'data:image/jpeg;base64,'+tenantSidenavLogo" class="select-none logo-full flex-none" style="width: 131.39px !important;" [alt]="tenantName+' Logo'">
      <img *ngIf="!collapsed" [src]="'data:image/jpeg;base64,'+tenantSidenavLogo" class="select-none logo-full flex-none" [alt]="tenantName+' Logo'">
   
   
    <button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
      class="expand-btn w-9 h-9 -mr-2 leading-none flex-none hidden lg:block text-black" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icCollapseSidebar" size="20px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icExpandSidebar" size="20px"></mat-icon>
    </button>
  </div>
</div>
  
  <div class="sidebar-blur" *ngIf="defaultValuesService?.getSchoolSetupProgress()">
    <div class="why-disabled">The system menu will become accessible after you complete <span class="text-amber">steps 1 through 4</span></div>
  </div>
  
  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items pb-10">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
<button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
  class="collapse-btn w-8 h-8 -mr-2 leading-none flex-none hidden lg:block text-black" mat-icon-button type="button">
  <mat-icon *ngIf="!collapsed" [icIcon]="icCollapseSidebar" size="20px"></mat-icon>
  <mat-icon *ngIf="collapsed" [icIcon]="icExpandSidebar" size="20px"></mat-icon>
</button>