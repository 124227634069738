<vex-mat-spinner-overlay *ngIf="loading"></vex-mat-spinner-overlay>
<div fxLayout="row" fxLayoutAlign="start center" class="mb-2" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">
        {{ "resetPassword" | translate }}
    </h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</div>
<mat-divider class="-mx-6"></mat-divider>
<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-dialog-content>
        <ul class="mt-4">
            <li class="mb-0">
                <mat-form-field class="w-full mb-0" appearance="outline" color="primary">
                    <mat-label>{{ "newPassword" | translate }}</mat-label>
                    <input [type]="inputType" formControlName="newPassword" name="newPassword" required
                        matInput />
                    <mat-error *ngIf="form.get('newPassword').hasError('whitespace')">
                        {{'newPasswordIsRequired'|translate}}
                    </mat-error>
                    <!-- For password visibility on/off -->
                    <button (click)="toggleVisibility('inputType')" mat-icon-button matSuffix
                        matTooltip="{{'toggleVisibility' | translate}}" type="button">
                        <mat-icon *ngIf="inputType === 'password'" [icIcon]="icVisibilityOff"></mat-icon>
                        <mat-icon *ngIf="inputType === 'text'" [icIcon]="icVisibility"></mat-icon>
                    </button>
                </mat-form-field>
                <!-- For auto generate password -->
                <p class="flex justify-end mb-3"><a class="text-primary cursor-pointer"
                        (click)="generatePassword()">{{'autoGeneratePassword' | translate}}</a></p>
            </li>
            <li>
                <mat-form-field class="w-full" appearance="outline" color="primary">
                    <mat-label>{{ "confirmNewPassword" | translate }}</mat-label>
                    <input [type]="cnfInputType" formControlName="confirmNewPassword" name="confirmNewPassword" required
                        matInput />
                    <mat-error *ngIf="form.get('confirmNewPassword').hasError('whitespace')">
                        {{'confirmNewPasswordIsRequired'|translate}}
                    </mat-error>
                    <mat-error *ngIf="form.get('confirmNewPassword').hasError('mustMatch')">
                        {{'passwordsMustMatch'|translate}}
                    </mat-error>
                    <!-- For password visibility on/off -->
                    <button (click)="toggleVisibility('cnfInputType')" mat-icon-button matSuffix
                        matTooltip="{{'toggleVisibility' | translate}}" type="button">
                        <mat-icon *ngIf="cnfInputType === 'password'" [icIcon]="icVisibilityOff"></mat-icon>
                        <mat-icon *ngIf="cnfInputType === 'text'" [icIcon]="icVisibility"></mat-icon>
                    </button>
                </mat-form-field>
            </li>
        </ul>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="pb-4">
        <button mat-flat-button mat-dialog-close type="button">{{ "cancel" | translate}}</button>
        <button color="primary" mat-raised-button type="submit">{{ "submit" |
            translate }}</button>
    </mat-dialog-actions>
</form>