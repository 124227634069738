import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DefaultValuesService } from '../common/default-values.service';
import { AddCommActivityModel, AddCommGroupModel, AddCommunicationSettingsModel, AddComposeMessageModel, AddUserInGroupModel, AllActivityListModel, AllUsersFromGroupModel, AllUsersFromSchoolModel, DeleteCommActivityModel, DeleteCommGroupModel, EditCommActivityModel, EditCommunicationSettingsModel, GetCommMenuModel, GetMessagingInboxModel, GetSentMessagingModel, RemoveUserFromGroupModel, UnreadMessageCountModel, UpdateCommGroupModel, ViewCommunicationSettingsModel, ViewInboxMessageModel } from '../models/communication.model';
import { CryptoService } from './Crypto.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  httpOptions: { headers: any; };
  apiUrl: string = environment.apiURL;

  unreadMessageCount = new BehaviorSubject<number>(0);
  unreadMessageCount$ = this.unreadMessageCount.asObservable();
  constructor(private defaultValuesService: DefaultValuesService,
    private http: HttpClient,
    private cryptoService: CryptoService,
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      })
    }
  }

  addCommGroup(obj: AddCommGroupModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.commGroupMaster.tenantId = this.defaultValuesService.getTenantID();
    obj.commGroupMaster.schoolId = this.defaultValuesService.getSchoolID();
    obj.commGroupMaster.createdBy = this.defaultValuesService.getUserGuidId();
    obj.commGroupMaster.isActive = true;
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/addCommGroup";
    return this.http.post<AddCommGroupModel>(apiurl, obj, this.httpOptions);
  }

  updateCommGroup(obj: UpdateCommGroupModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.commGroupMaster.tenantId = this.defaultValuesService.getTenantID();
    obj.commGroupMaster.schoolId = this.defaultValuesService.getSchoolID();
    obj.commGroupMaster.updatedBy = this.defaultValuesService.getUserGuidId();
    obj.commGroupMaster.isActive = true;
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/updateCommGroup";
    return this.http.put<UpdateCommGroupModel>(apiurl, obj, this.httpOptions);
  }

  deleteCommGroup(obj: DeleteCommGroupModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.commGroupMaster.tenantId = this.defaultValuesService.getTenantID();
    obj.commGroupMaster.schoolId = this.defaultValuesService.getSchoolID();
    obj.commGroupMaster.createdBy = this.defaultValuesService.getUserGuidId();
    obj.commGroupMaster.isActive = true;
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/deleteCommGroup";
    return this.http.put<DeleteCommGroupModel>(apiurl, obj, this.httpOptions);
  }

  addUserInGroup(obj: AddUserInGroupModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/addUserInGroup";
    return this.http.post<AddUserInGroupModel>(apiurl, obj, this.httpOptions);
  }

  getCommMenu(obj: GetCommMenuModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/getCommMenu";
    return this.http.post<GetCommMenuModel>(apiurl, obj, this.httpOptions);
  }

  allUsersFromGroup(obj: AllUsersFromGroupModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/allUsersFromGroup";
    return this.http.post<AllUsersFromGroupModel>(apiurl, obj, this.httpOptions);
  }

  removeUserFromGroup(obj: RemoveUserFromGroupModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/removeUserFromGroup";
    return this.http.put<RemoveUserFromGroupModel>(apiurl, obj, this.httpOptions);
  }

  allUsersFromSchool(obj: AllUsersFromSchoolModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/allUsersFromSchool";
    return this.http.post<AllUsersFromSchoolModel>(apiurl, obj, this.httpOptions);
  }

  addComposeMessage(obj: AddComposeMessageModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.commMessage.tenantId = this.defaultValuesService.getTenantID();
    obj.commMessage.schoolId = this.defaultValuesService.getSchoolID();
    obj.commMessage.creatorId = +this.defaultValuesService.getUserId();
    obj.commMessage.profileType = this.defaultValuesService.getUserMembershipType();
    obj.commMessage.createdBy = this.defaultValuesService.getUserGuidId();
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/addComposeMessage";
    return this.http.post<AddComposeMessageModel>(apiurl, obj, this.httpOptions);
  }

  getMessagingInbox(obj: GetMessagingInboxModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    // obj.userId = +this.defaultValuesService.getUserId();
    // obj.profileType = this.defaultValuesService.getUserMembershipType();
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/getMessagingInbox";
    return this.http.post<GetMessagingInboxModel>(apiurl, obj, this.httpOptions);
  }

  getSentMessaging(obj: GetSentMessagingModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    // obj.userId = +this.defaultValuesService.getUserId();
    // obj.profileType = this.defaultValuesService.getUserMembershipType();
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/getSentMessaging";
    return this.http.post<GetSentMessagingModel>(apiurl, obj, this.httpOptions);
  }

  viewInboxMessage(obj: ViewInboxMessageModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.userId = +this.defaultValuesService.getUserId();
    obj.profileType = this.defaultValuesService.getUserMembershipType();
    let apiurl = this.apiUrl + obj._tenantName + "/Communication/viewInboxMessage";
    return this.http.post<ViewInboxMessageModel>(apiurl, obj, this.httpOptions);
  }
  //==================================>
  addCommunicationSettings(obj: AddCommunicationSettingsModel) {
    obj._tenantName = this.defaultValuesService.getDefaultTenant();
    obj._userName = this.defaultValuesService.getUserName();
    obj._token = this.defaultValuesService.getToken();
    obj.commSchoolSettingEmail.password = obj.commSchoolSettingEmail.password?this.cryptoService.encrypt(obj.commSchoolSettingEmail.password):null;
    obj.commSchoolSettingEmail.schoolId = this.defaultValuesService.getSchoolID();
    obj.commSchoolSettingEmail.tenantId = this.defaultValuesService.getTenantID();
    obj.commSchoolSettingEmail.createdBy = this.defaultValuesService.getUserGuidId();
    // obj.commSchoolSettingEmail.updatedBy = this.defaultValuesService.getUserGuidId();
    if (this.defaultValuesService.getUserMembershipType() === "School Administrator" || this.defaultValuesService.getUserMembershipType() === "Admin Assistant" || this.defaultValuesService.getUserMembershipType() === "Teacher" || this.defaultValuesService.getUserMembershipType() === "Homeroom Teacher"){
      obj.commSchoolSettingEmail.staffId =  this.defaultValuesService.getUserId();
    }
    let apiurl = this.apiUrl + obj._tenantName + '/Communication/addCommSettingEmail';
    return this.http.post<AddCommunicationSettingsModel>(apiurl, obj, this.httpOptions);
  }

  viewCommunicationSettings(obj: ViewCommunicationSettingsModel) {
    obj._tenantName = this.defaultValuesService.getDefaultTenant();
    obj._userName = this.defaultValuesService.getUserName();
    obj._token = this.defaultValuesService.getToken();
    obj.commSchoolSettingEmail.schoolId = this.defaultValuesService.getSchoolID();
    obj.commSchoolSettingEmail.tenantId = this.defaultValuesService.getTenantID();
    if (this.defaultValuesService.getUserMembershipType() === "School Administrator" || this.defaultValuesService.getUserMembershipType() === "Admin Assistant" || this.defaultValuesService.getUserMembershipType() === "Teacher" || this.defaultValuesService.getUserMembershipType() === "Homeroom Teacher"){
      obj.commSchoolSettingEmail.staffId =  this.defaultValuesService.getUserId();
    }
    let apiurl = this.apiUrl + obj._tenantName + '/Communication/viewCommSettingEmail';
    return this.http.post<ViewCommunicationSettingsModel>(apiurl, obj, this.httpOptions);
  }

  editCommunicationSettings(obj: EditCommunicationSettingsModel) {
    obj._tenantName = this.defaultValuesService.getDefaultTenant();
    obj._userName = this.defaultValuesService.getUserName();
    obj._token = this.defaultValuesService.getToken();
    obj.commSchoolSettingEmail.password = obj.commSchoolSettingEmail.password?this.cryptoService.encrypt(obj.commSchoolSettingEmail.password):null;
    obj.commSchoolSettingEmail.schoolId = this.defaultValuesService.getSchoolID();
    obj.commSchoolSettingEmail.tenantId = this.defaultValuesService.getTenantID();
    obj.commSchoolSettingEmail.updatedBy = this.defaultValuesService.getUserGuidId();
    if (this.defaultValuesService.getUserMembershipType() === "School Administrator" || this.defaultValuesService.getUserMembershipType() === "Admin Assistant" || this.defaultValuesService.getUserMembershipType() === "Teacher" || this.defaultValuesService.getUserMembershipType() === "Homeroom Teacher"){
      obj.commSchoolSettingEmail.staffId =  this.defaultValuesService.getUserId();
    }
    let apiurl = this.apiUrl + obj._tenantName + '/Communication/editCommSettingEmail';
    return this.http.put<EditCommunicationSettingsModel>(apiurl, obj, this.httpOptions);
  }

      // Activity
      addCommActivity(obj: AddCommActivityModel) {
        // obj = this.defaultValuesService.getAllMandatoryVariable(obj);
        obj._tenantName = this.defaultValuesService.getDefaultTenant();
        obj._userName = this.defaultValuesService.getUserName();
        obj._token = this.defaultValuesService.getToken();
        obj.commActivity.schoolId = this.defaultValuesService.getSchoolID();
        obj.commActivity.tenantId = this.defaultValuesService.getTenantID();
        obj.commActivity.createdBy = this.defaultValuesService.getUserGuidId();
        obj.commActivity.initiatorId = this.defaultValuesService.getUserId();
        obj.commActivity.profileType = this.defaultValuesService.getUserMembershipType();
        let apiurl = this.apiUrl + obj._tenantName + '/Communication/addCommActivity';
        return this.http.post<EditCommunicationSettingsModel>(apiurl, obj, this.httpOptions);
      }
  
      getAllActivityList(obj: AllActivityListModel) {
        obj = this.defaultValuesService.getAllMandatoryVariable(obj);
        let apiurl = this.apiUrl + obj._tenantName + '/Communication/allActivityList';
        return this.http.post<EditCommunicationSettingsModel>(apiurl, obj, this.httpOptions);
      }
  
      editCommActivity(obj: EditCommActivityModel) {
        obj = this.defaultValuesService.getAllMandatoryVariable(obj);
        obj.commActivity.schoolId = this.defaultValuesService.getSchoolID();
        obj.commActivity.tenantId = this.defaultValuesService.getTenantID();
        obj.commActivity.updatedBy = this.defaultValuesService.getUserGuidId();
        obj.commActivity.initiatorId = this.defaultValuesService.getUserId();
        obj.commActivity.profileType = this.defaultValuesService.getUserMembershipType();
        let apiurl = this.apiUrl + obj._tenantName + '/Communication/editCommActivity';
        return this.http.put<EditCommunicationSettingsModel>(apiurl, obj, this.httpOptions);
      }
  
      deleteCommActivity(obj: DeleteCommActivityModel) {
        obj = this.defaultValuesService.getAllMandatoryVariable(obj);
        obj.commActivity.schoolId = this.defaultValuesService.getSchoolID();
        obj.commActivity.tenantId = this.defaultValuesService.getTenantID();
        obj.commActivity.createdBy = this.defaultValuesService.getUserGuidId();
        let apiurl = this.apiUrl + obj._tenantName + '/Communication/deleteCommActivity';
        return this.http.post<EditCommunicationSettingsModel>(apiurl, obj, this.httpOptions);
      }

      getUnreadMessageCount(obj: UnreadMessageCountModel) {
        obj = this.defaultValuesService.getAllMandatoryVariable(obj);
        obj.userId = this.defaultValuesService.getUserId();
        obj.profileType = this.defaultValuesService.getUserMembershipType();
        let apiurl = this.apiUrl + obj._tenantName + '/Communication/getUnreadMessageCount';
        return this.http.post<UnreadMessageCountModel>(apiurl, obj, this.httpOptions);
      }

      sendUnreadMsgCount(data) {
        this.unreadMessageCount.next(data);
      }
}
