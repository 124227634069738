export let MY_FORMATS = {
    parse: {
      dateInput: JSON.parse(sessionStorage.getItem('schoolDateFormat'))=='M/d/yy'?'MM/DD/YYYY':JSON.parse(sessionStorage.getItem('schoolDateFormat'))=='MMM d, y'?'MMM D, y':JSON.parse(sessionStorage.getItem('schoolDateFormat'))=='MMMM d, y'?'MMMM D, y':'DD/MM/YYYY',
    },
    display: {
      dateInput: JSON.parse(sessionStorage.getItem('schoolDateFormat'))=='M/d/yy'?'MM/DD/YYYY':JSON.parse(sessionStorage.getItem('schoolDateFormat'))=='MMM d, y'?'MMM D, y':JSON.parse(sessionStorage.getItem('schoolDateFormat'))=='MMMM d, y'?'MMMM D, y':'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };