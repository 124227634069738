<div class="relative">    
    <button class="text-secondary absolute -right-3 -top-3" mat-icon-button mat-dialog-close type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</div>
<div class="text-center">
    <img src="assets/lottie/lock.gif" class="inline-block mb-4 w-24">
    <h1 class="headline m-0" fxFlex="auto">
        {{'accessDenied' | translate}}
    </h1>
    <ng-container *ngIf="membership === profiles.SuperAdmin || membership === profiles.SchoolAdmin || membership === profiles.AdminAssitant">
        <h5 class="text-center">{{'yourCurrentSubscriptionDoesNotSupportThisFeature' | translate}}</h5>
        <div class="text-center mt-5">
            <button color="primary" mat-raised-button type="button" (click)="upgradeNow()">{{'upgradeNow' | translate}}</button>
        </div>
    </ng-container>
</div>
