<div class="secondary-toolbar " fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <div fxFlex.xs="auto" class="flex items-center">
      <a [routerLink]="['/school/dashboards']" class="flex align-center border rounded-full bg-white mr-4 border-primary px-2 py-1" *ngIf="defaultValuesService?.getSchoolSetupProgress() && !ishide && !defaultValuesService?.checkSubscriptionExpiredOrNot() && !defaultValuesService.getImpersonateButton() && (defaultValuesService.getUserMembershipType() === 'Super Administrator' || defaultValuesService.getUserMembershipType() === 'School Administrator')">
        <mat-icon class="mr-1">undo</mat-icon> <span class="font-medium">{{'back' | translate}}</span>
      </a>
    <h1 *ngIf="current && !schoolSettings"
      class="subheading-2 font-medium m-0 ltr:pr-3 rtl:pl-3 ltr:border-r rtl:border-l ltr:mr-3 rtl:ml-3">{{ current|translate }}</h1>
      <h1 *ngIf="schoolSettings" class="flex items-center subheading-2 font-medium m-0 ltr:pr-3 rtl:pl-3 ltr:border-r rtl:border-l ltr:mr-3 rtl:ml-3 cursor-pointer" [matMenuTriggerFor]="menu">{{selectedValue | translate }}<mat-icon class="ml-2" [icIcon]="icDropdown"></mat-icon></h1>
    </div>
    <ng-content></ng-content>
    <a (click)="openWhatsApp()" *ngIf="isAdmin" matTooltip="{{'openSISSupportandInformationServices' | translate}} " class="cursor-pointer" target="_blank"><img src="../../../assets/img/WhatsAppButtonGreenSmall.svg"></a>
    <!-- <button class="flex" color="primary" type="button"><mat-icon [icIcon]="icSearch"></mat-icon> <span class="hidden md:inline-block ml-1">Search</span></button> -->
    <a class="ml-6 flex cursor-pointer" (click)="openHelpDesk()"><mat-icon [icIcon]="icHelp"></mat-icon> <span class="hidden md:inline-block ml-1">{{'help' | translate}}</span></a>
  </div>
</div>

<mat-menu #menu="matMenu">
  <a mat-menu-item *ngFor="let page of pages" [ngClass]="{'selected-item':selectedValue==page.displayName}" (click)="changePage(page)">{{page.displayName |translate}}</a>
</mat-menu>