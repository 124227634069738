import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import icClose from '@iconify/icons-ic/twotone-close';
import { MatPasswordStrengthComponent } from "@angular-material-extensions/password-strength";
import { ChangePasswordViewModel } from '../../../../app/models/common.model';
import { CommonService } from '../../../../app/services/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icCheck from '@iconify/icons-ic/check';
import icWarning from '@iconify/icons-ic/twotone-warning';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedFunction } from '../../../../app/pages/shared/shared-function';
import { DefaultValuesService } from '../../../../app/common/default-values.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'vex-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
  icClose = icClose;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  icCheck = icCheck;
  icWarning = icWarning;
  changePasswordViewModel: ChangePasswordViewModel = new ChangePasswordViewModel();
  inputType = 'password';
  form: FormGroup;
  passRegex = {
    mustBeAtLeast8CharactersLong: false,
    mustContain1UppercaseLetter: false,
    mustContain1LowercaseLetter: false,
    mustContain1Number: false,
    mustContain1SpecialCharacter: false
  }

  constructor(public translateService: TranslateService,
    private commonService: CommonService,
    private snackbar: MatSnackBar,
    private commonFunction: SharedFunction,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private defaultValuesService: DefaultValuesService,
    private fb: FormBuilder) {
    // translateService.use("en");
    this.form = fb.group({
      currentPasswordHash: [''],
      newPasswordHash: [''],
      confirmPasswordHash: ['']

    })
  }

  ngOnInit(): void {
  }

  // This toggleVisibility method is used for password visibility on/off.
  toggleVisibility(key) {
    if (this[key] === "text") {
      this[key] = 'password';
      this.cd.markForCheck();
    } else {
      this[key] = 'text';
      this.cd.markForCheck();
    }
  }

  checkPass() {
    if(this.form.controls.newPasswordHash.value){
      this.passRegex.mustBeAtLeast8CharactersLong = this.form.controls.newPasswordHash.value.length >= 8;
      this.passRegex.mustContain1UppercaseLetter = this.form.controls.newPasswordHash.value.search(/[A-Z]/) !== -1;
      this.passRegex.mustContain1LowercaseLetter = this.form.controls.newPasswordHash.value.search(/[a-z]/) !== -1;
      this.passRegex.mustContain1Number = this.form.controls.newPasswordHash.value.search(/[0-9]/) !== -1;
      this.passRegex.mustContain1SpecialCharacter = this.form.controls.newPasswordHash.value.search(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/) !== -1;
    } else {
      this.passRegex.mustBeAtLeast8CharactersLong = false;
      this.passRegex.mustContain1UppercaseLetter = false;
      this.passRegex.mustContain1LowercaseLetter = false;
      this.passRegex.mustContain1Number = false;
      this.passRegex.mustContain1SpecialCharacter = false;
    }
  }

  generate() {
    this.inputType = 'text';
    let autoGeneratePassword = this.commonFunction.autoGeneratePassword();
    this.form.controls.newPasswordHash.setValue(autoGeneratePassword);
    this.form.controls.confirmPasswordHash.setValue(autoGeneratePassword);
    this.changePasswordViewModel.newPasswordHash = autoGeneratePassword;
    this.checkPass();
  }

  submit() {
    this.form.markAllAsTouched();
    let allPassRegexValidation=false;
    for (const val in this.passRegex){
      if (this.passRegex[val] === false) allPassRegexValidation = true;
    }
    if (this.form.valid && !allPassRegexValidation) {
      if (this.form.controls.confirmPasswordHash.value === this.form.controls.newPasswordHash.value) {
        this.changePasswordViewModel.currentPasswordHash = this.form.controls.currentPasswordHash.value;
        this.changePasswordViewModel.newPasswordHash = this.form.controls.newPasswordHash.value;
        this.commonService.changePassword(this.changePasswordViewModel).subscribe(
          (res) => {
            if (res) {
            if(res._failure){
        
                this.snackbar.open(res._message, '', {
                  duration: 10000
                });
              }
              else {
                this.snackbar.open(res._message, '', {
                  duration: 10000
                });
                this.dialog.closeAll();
              }
            }
            else {
              this.snackbar.open(res._message, '', {
                duration: 10000
              });
            }
          }
        );
      }
      else {
        this.snackbar.open(this.defaultValuesService.translateKey('confirmNewPasswordNotMathedWithNewPassword'), '', {
          duration: 10000
        });
      }

    }
  }

}
