import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetMoodleVersionInfoModel, GetMoodleConfigModel, ModifyMoodleConfigModel, AddUserInMoodle, AddCoursesInMoodle, GetUsersMoodleCourses, GetEnrolledUsersMoodleCourses, GetMoodleFinalGrades, AddMoodleAssignments, GetMoodlUserGradeReport, GetMoodleUsersRoleModel, MoodleSSOModel, GetMoodleUsersInfoModel, GetMoodleCourseInfoModel, GetMoodleUserSchedulingModel, GetUserCategoriesAndFieldsModel, EditCustomFieldMoodleMappingModel, GetMoodlePageUrlModel } from '../models/moodle.model';
import { LanguageModel } from '../models/language.model';
import { GetAccessLogInfoModel } from '../models/get-access-log.model'
import { DefaultValuesService } from '../common/default-values.service';

@Injectable({
  providedIn: 'root'
})
export class MoodleService {
  ipAdd: any;
  apiUrl: string = environment.apiURL;
  httpOptions: { headers: any; };

  constructor(
    private http: HttpClient,
    private defaultValuesService: DefaultValuesService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      })
    }
  }

  getMoodleVersionInfo(modleURL: string, obj: GetMoodleVersionInfoModel) {
    let apiurl = modleURL + "/webservice/rest/server.php";
    let apiurlExtended = apiurl + "?wstoken=" + obj.wsToken + "&moodlewsrestformat=" + obj.moodlewsrestformat + "&wsfunction=" + obj.wsfunction;

    return this.http.post<GetMoodleVersionInfoModel>(apiurlExtended, null);
  }

  getMoodleConfigurations(obj: GetMoodleConfigModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodleConfig";

    return this.http.post<GetMoodleConfigModel>(apiurl, obj, this.httpOptions);
  }

  modifyMoodleConfigurations(obj: ModifyMoodleConfigModel, eventType: string) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.updatedBy = this.defaultValuesService.getUserGuidId();
    
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/" + (eventType == 'add' ? "addMoodleConfig" : "updateMoodleConfig");

    if (eventType == 'add')
      return this.http.post<ModifyMoodleConfigModel>(apiurl, obj, this.httpOptions);
    else
      return this.http.put<ModifyMoodleConfigModel>(apiurl, obj, this.httpOptions);
  }

  addUserInMoodle(obj: AddUserInMoodle) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/addUserInMoodle";

    return this.http.post<AddUserInMoodle>(apiurl, obj, this.httpOptions);
  }

  addCourseInMoodle(obj: AddCoursesInMoodle) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/addCourseInMoodle";

    return this.http.post<AddCoursesInMoodle>(apiurl, obj, this.httpOptions);
  }

  getUsersMoodleCourses(obj: GetUsersMoodleCourses) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getUsersMoodleCourses";

    return this.http.post<GetUsersMoodleCourses>(apiurl, obj, this.httpOptions);
  }

  getEnrolledUsersMoodleCourses(obj: GetEnrolledUsersMoodleCourses) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getEnrolledUsersMoodleCourses";

    return this.http.post<GetEnrolledUsersMoodleCourses>(apiurl, obj, this.httpOptions);
  }

  getMoodleFinalGrades(obj: GetMoodleFinalGrades) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodleFinalGrades";

    return this.http.post<GetMoodleFinalGrades>(apiurl, obj, this.httpOptions);
  }

  MoodleSSO(modleURL: string, obj: MoodleSSOModel) {
    let apiurl = modleURL + "/webservice/rest/server.php";
    let apiurlExtended = apiurl + "?wstoken=" + obj.ssoToken + "&moodlewsrestformat=" + obj.moodlewsrestformat + "&wsfunction=" + obj.wsfunction + "&user[email]=" + obj.user;

    return this.http.post<MoodleSSOModel>(apiurlExtended, null);
  }

  addMoodleAssignments(obj: AddMoodleAssignments) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/addMoodleAssignments";

    return this.http.post<AddMoodleAssignments>(apiurl, obj, this.httpOptions);
  }

  getMoodlUserGradeReport(obj: GetMoodlUserGradeReport) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodlUserGradeReport";

    return this.http.post<GetMoodlUserGradeReport>(apiurl, obj, this.httpOptions);
  }

  getMoodleUsersRole(obj: GetMoodleUsersRoleModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodleUsersRole";

    return this.http.post<GetMoodleUsersRoleModel>(apiurl, obj, this.httpOptions);
  }

  getMoodleUsersInfo(obj: GetMoodleUsersInfoModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodleUsersInfo";

    return this.http.post<GetMoodleUsersInfoModel>(apiurl, obj, this.httpOptions);
  }

  getMoodleCourseInfo(obj: GetMoodleCourseInfoModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodleCourseInfo";

    return this.http.post<GetMoodleCourseInfoModel>(apiurl, obj, this.httpOptions);
  }

  getMoodleUserScheduling(obj: GetMoodleUserSchedulingModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodleUserScheduling";

    return this.http.post<GetMoodleUserSchedulingModel>(apiurl, obj, this.httpOptions);
  }

  getMoodleHistrocialCoureseGrades(obj: GetMoodleCourseInfoModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodleHistrocialCoureseGrades";

    return this.http.post<GetMoodleCourseInfoModel>(apiurl, obj, this.httpOptions);
  }

  getopenSISAndMoodleCustomFields(obj: GetUserCategoriesAndFieldsModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getUserCategoriesAndFields";

    return this.http.post<GetUserCategoriesAndFieldsModel>(apiurl, obj, this.httpOptions);
  }

  editCustomFieldMoodleMapping(obj: EditCustomFieldMoodleMappingModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/editCustomFieldMoodleMapping";

    return this.http.put<EditCustomFieldMoodleMappingModel>(apiurl, obj, this.httpOptions);
  }

  getMoodlePageUrl(obj: GetMoodlePageUrlModel) {
    obj = this.defaultValuesService.getAllMandatoryVariable(obj);
    obj.schoolId = this.defaultValuesService.getSchoolID();
    obj.tenantId = this.defaultValuesService.getTenantID();

    let apiurl = this.apiUrl + obj._tenantName + "/Moodle/getMoodlePageUrl";

    return this.http.post<GetMoodlePageUrlModel>(apiurl, obj, this.httpOptions);
  }
}
