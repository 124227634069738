import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
// import { NotificationService } from 'src/app/pages/shared/notification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { UpdateNotificationModel } from 'src/app/models/notifications.model';
import { DefaultValuesService } from 'src/app/common/default-values.service';
import { GeneralNotifications, Notifications } from 'src/app/models/notifications.model';
import { ConfirmDialogComponent } from 'src/app/pages/shared-module/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { filter, map, pairwise, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'vex-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() notificationsList = [];
  @Input() notificationType:string;
  @Input() generalNotificationsList = [];
  @Input() pageNumber: number;
  @Input() pageSize: number;
  @Input() totalCount: number;
  @Output() showHideNotifications = new EventEmitter<any>();
  @Output() hasRecentNotification = new EventEmitter<any>();
  @Output() udpateNotificationUnreadCount = new EventEmitter<any>();
  @ViewChild(CdkVirtualScrollViewport) scroller: CdkVirtualScrollViewport; 
  updateNotificationModel: UpdateNotificationModel = new UpdateNotificationModel();
  generalNotifications: GeneralNotifications = new GeneralNotifications();
  Notifications: Notifications = new Notifications();
  currentTab: string='systemNotifications';
  generalNotificationUnreadCount: number;
  loadMoreNotification: boolean = false;

  modules = [
    {
      module: "Marking Period",
      class: "bg-orange"
    },
    {
      module: "Grade Scale",
      class: "bg-teal"
    },
    {
      module: "Behavior",
      class: "bg-green"
    },
    {
      module: "Attendance",
      class: "bg-red"
    },
    {
      module: "Scheduling",
      class: "bg-accent"
    },
    {
      module: "Attendance Code",
      class: "bg-deep-orange"
    },
    {
      module: "Course Registration",
      class: "bg-amber"
    },
    {
      module: "Student",
      class: "bg-red"
    },
    {
      module: "Admission",
      class: "bg-green"
    },
    {
      module: "Billing",
      class: "bg-purple"
    },
    {
      module: "Custom Field",
      class: "bg-cyan"
    },
    {
      module: "Enrollment Code",
      class: "bg-deep-purple"
    }
  ]

  constructor(
    public translateService: TranslateService,
    private dialog: MatDialog,
    private notify: NotificationService,
    private snackbar: MatSnackBar,
    public defaultValuesService: DefaultValuesService
  ) {
  }

  ngOnInit(): void {
    if(this.generalNotificationsList.length > 0){
      this.generalNotificationUnreadCount = (this.generalNotificationsList.filter(item => item.isRead === false))?.length;
    }
    if(this.generalNotificationsList){
      if(this.notificationType === 'general-notifications'){
        this.readAllNotifications();
      }
      this.arrangeNotifications(); 
    }
  }

  ngAfterViewInit() {
    if(this.notificationType === 'general-notifications'){
      this.scroller.elementScrolled().pipe(
        map(() => this.scroller.measureScrollOffset("bottom")),
        pairwise(),
        filter(([y1, y2]) => (y2 < y1) && (y2 < 140)),
        throttleTime(500)
      ).subscribe(() => {
        this.onScroll();      
      })
    }
  }

  readAllNotifications() {
    this.updateNotificationModel.schoolId = +this.defaultValuesService.getSchoolID();
    this.updateNotificationModel.profileType = this.defaultValuesService.getUserMembershipType();
    this.updateNotificationModel.userId = +this.defaultValuesService.getUserId();
    this.notify.updateNotification(this.updateNotificationModel).subscribe((res) => {
      if(res._failure){
        // do nothing
      } else {
        this.generalNotificationUnreadCount = null;
        this.hasRecentNotification.emit();
        this.udpateNotificationUnreadCount.emit(this.generalNotificationUnreadCount);
      }
    });
  }

  hideNotifications() {
    this.showHideNotifications.emit();
  }

  openNotificationDetails(details: any) {
    this.Notifications.releaseDetailId = details.releaseId;
    this.notify.GetAllNotifications(this.Notifications).subscribe(notificationData=>{
      if (notificationData?._failure) {
        this.snackbar.open(notificationData._message, '', {
          duration: 1000
        });
      } else {
        this.notify.showNotificationsPanel.next(false);
        this.dialog.open(NotificationDetailsComponent, {
          width: '600px',
          data: notificationData.featureReleaseList
        })
      }
    });


    
  }

  getClassName(module) {
    const detail =  this.modules.find(x => x.module === module);
    if(detail){
      return detail.class;
    }
  }

  changeTab(tab: string) {
    this.currentTab = tab;
  }

  pinUnpinNotification(item, index) {
    this.generalNotificationsList[index].loading = true;
    this.updateNotificationModel = new UpdateNotificationModel();
    this.updateNotificationModel.schoolId = +this.defaultValuesService.getSchoolID();
    this.updateNotificationModel.profileType = this.defaultValuesService.getUserMembershipType();
    this.updateNotificationModel.userId = +this.defaultValuesService.getUserId();
    this.updateNotificationModel.notificationId = item.notificationId;
    if(item.isPinned){
      this.updateNotificationModel.notificationList = [{isPinned: false, isDeleted: false}];
    } else {
      this.updateNotificationModel.notificationList = [{isPinned: true, isDeleted: false}];
    }
    
    this.notify.updateNotification(this.updateNotificationModel).subscribe((res) => {
      if(res._failure){
        // do nothing
      } else {
        // do nothing
        this.getAllGeneralNotifications();
      }
    });    
  }

  deleteNotification(item, index) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: this.defaultValuesService.translateKey('areYouSure'),
        message: this.defaultValuesService.translateKey('youWantToDeleteThisNotification')
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.generalNotificationsList[index].loading = true;
        this.updateNotificationModel = new UpdateNotificationModel();
        this.updateNotificationModel.schoolId = +this.defaultValuesService.getSchoolID();
        this.updateNotificationModel.profileType = this.defaultValuesService.getUserMembershipType();
        this.updateNotificationModel.userId = +this.defaultValuesService.getUserId();
        this.updateNotificationModel.notificationId = item.notificationId;
        this.updateNotificationModel.notificationList = [{isPinned: false, isDeleted: true}];
        
        this.notify.updateNotification(this.updateNotificationModel).subscribe((res) => {
          if(res._failure){
            // do nothing
          } else {
            // do nothing
            this.getAllGeneralNotifications();
          }
        });
      }
    });
  }

  arrangeNotifications() {
    if(this.generalNotificationsList){
      this.generalNotificationsList = this.generalNotificationsList.filter(n => n.isDeleted === false );
      let pinnedNotifications = [];
      let unpinnedNotifications = [];
      this.generalNotificationsList.forEach((item) => {
        if(item.isPinned){
          pinnedNotifications.push({...item, loading: false});
        } else {
          unpinnedNotifications.push({...item, loading: false});
        }
      });
      this.generalNotificationsList = [];
      this.generalNotificationsList.push(...pinnedNotifications, ...unpinnedNotifications);
    }
  }

  getAllGeneralNotifications(append?:boolean) { 
    this.generalNotifications.profileType = this.defaultValuesService.getUserMembershipType();
    this.generalNotifications.userId = this.generalNotifications.profileType === 'Student'?+this.defaultValuesService.getStudentId():+this.defaultValuesService.getUserId();
    if(this.generalNotifications.userId){
      this.generalNotifications.schoolId = +this.defaultValuesService.getSchoolID();
      this.generalNotifications.pageResult = {pageNumber: this.pageNumber, pageSize: this.pageSize};
      this.notify.getAllGeneralNotifications(this.generalNotifications).subscribe((notificationData: any)=>{
        if (notificationData?._failure) {
          // this.generalNotificationsList = [];
        } else {
          if(append){
            this.generalNotificationsList = [...this.generalNotificationsList, ...notificationData?.notificationList];
          } else {
            this.generalNotificationsList = notificationData?.notificationList;
            this.arrangeNotifications();
          }
          this.loadMoreNotification = false;
        }
      });
    }
  }

  onScroll() {
    if(this.generalNotificationsList.length < this.totalCount){
      this.loadMoreNotification = true;
      this.pageNumber = this.pageNumber + 1;
      this.getAllGeneralNotifications(true);
    }
  }
  
}
