<div mat-dialog-title class="flex flex-col -mx-6 -mt-6 mb-0 text-white relative border-green border-b-8">
    <div class="profile-pic">
        <div class="rounded-full overflow-hidden border-4 border-white">
            <img [src]="hasProfileImg ? 'data:image/jpeg;base64,' + profileImg : profileImg" alt="Profile Photo" class="rounded-full w-full" />
        </div>
    </div>
    <div class="bg-primary profile-info-highlight rounded-tl rounded-tr">
        <div class="flex justify-end items-center">
            <div class="cursor-pointer mr-2" (click)="showStudentDetails(studentInfo)" matTooltip="{{'showStudentDetails' | translate}}">
                <mat-icon class="align-middle text-white opacity-75 text-lg font-medium w-auto h-auto">open_in_new
                </mat-icon>
            </div>
            <button class="text-white opacity-75" mat-dialog-close mat-icon-button type="button" matTooltip="{{'close' | translate}}">
                <mat-icon [icIcon]="icClose"></mat-icon>
            </button>
        </div>
        <div class="text-center pt-12">
            <h4 class="text-2xl font-semibold">{{studentInfo.studentMaster.firstGivenName}}{{studentInfo.studentMaster.middleName != null ? " "+studentInfo.studentMaster.middleName:''}}{{" "+studentInfo.studentMaster.lastFamilyName}}</h4>
            <div class="bg-green rounded-full text-sm px-2 inline-block font-medium leading-relaxed my-2">
                {{'gradeLevel' | translate }} : {{studentInfo?.currentGradeLevel}}
            </div>
            <p class="text-sm pb-4">{{studentInfo?.loginEmail}}</p>
        </div>
    </div>
</div>
<mat-dialog-content class="student-idetification-details pt-6 rounded-bl rounded-br">
    <div class="flex justify-between mb-6">
        <label class="text-gray text-base">{{ 'school' | translate }}</label>
        <p class="text-base">{{defaultService.getSchoolName()}}</p>
    </div>
    <div class="flex justify-between mb-6">
        <label class="text-gray text-base">{{ 'studentId' | translate }}</label>
        <p class="text-base">{{studentInfo.studentMaster.studentId}}</p>
    </div>
    <div class="flex justify-between mb-6">
        <label class="text-gray text-base">{{ 'alternateId' | translate }}</label>
        <p class="text-base">{{studentInfo.studentMaster.alternateId == null ? "" : studentInfo.studentMaster.alternateId}}</p>
    </div>
    <div class="flex justify-between mb-6">
        <label class="text-gray text-base">{{ 'rollNumber' | translate }}</label>
        <p class="text-base">{{studentInfo.studentMaster.rollNumber == null ? "" : studentInfo.studentMaster.rollNumber}}</p>
    </div>
    <div class="flex justify-between mb-6">
        <label class="text-gray text-base">{{ 'section' | translate }}</label>
        <p class="text-base">{{studentInfo.studentMaster.sections == null ? "" : studentInfo.studentMaster.sections.name}}</p>
    </div>
    <div class="flex justify-between mb-6">
        <label class="text-gray text-base">{{ 'phone' | translate }}</label>
        <p class="text-base">{{studentInfo.studentMaster.homePhone == null ? "" : studentInfo.studentMaster.homePhone}}</p>
    </div>
</mat-dialog-content>