import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { DefaultValuesService } from '../common/default-values.service';
import { ProfilesTypes } from '../enums/profiles.enum';
import { RolePermissionListViewModel } from '../models/roll-based-access.model';
import { CommonService } from './common.service';
import { RollBasedAccessService } from './roll-based-access.service';
import { SchoolService } from './school.service';
import { SubscriptionService } from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateServices {
  permissionListViewModel: RolePermissionListViewModel = new RolePermissionListViewModel();
  profiles = ProfilesTypes;
  public impersonateSubjectForSelectBar = new BehaviorSubject(false);
  public impersonateSubjectForToolBar = new BehaviorSubject(false);
  constructor(
    private defaultValuesService: DefaultValuesService,
    public rollBasedAccessService: RollBasedAccessService,
    private schoolService: SchoolService,
    private router: Router,
    private commonService: CommonService,
    private subscriptionService: SubscriptionService,
    private snackbar: MatSnackBar
    ) { }

  impersonateStoreData() {
    let impersonateCread = {
      userGuiId: this.defaultValuesService.getUserGuidId(),
      userPhoto: this.defaultValuesService.getuserPhoto(),
      userId: this.defaultValuesService.getUserId(),
      userMembershipId: this.defaultValuesService.getuserMembershipID(),
      userMembershipType: this.defaultValuesService.getUserMembershipType(),
      userFullUserName: this.defaultValuesService.getFullUserName(),
      userName: this.defaultValuesService.getUserName(),
      userEmail: this.defaultValuesService.getEmailId(),
      userMembershipName: this.defaultValuesService.getuserMembershipName(),
      userSchoolId: this.defaultValuesService.getSchoolID()
    }
    if( this.defaultValuesService.getUserMembershipType() === "Super Administrator" )
      this.setSuperAdminCredentials(impersonateCread);
    else
      this.setAdminStaffCredentials(impersonateCread);
    this.defaultValuesService.setImpersonateButton(true)
  }

  backToSuperAdmin() {
    let value = this.getAdminStaffCredentials() ? this.getAdminStaffCredentials() : this.getSuperAdminCredentials();
    this.defaultValuesService.setFullUserName(value.userFullUserName);
    this.defaultValuesService.setUserMembershipType(value.userMembershipType);
    this.defaultValuesService.setEmailId(value.userEmail);
    this.defaultValuesService.setUserGuidId(value.userGuiId);
    this.defaultValuesService.setUserName(value.userName);
    this.defaultValuesService.setUserId(value.userId);
    this.defaultValuesService.setUserPhoto(value.userPhoto);
    this.defaultValuesService.setUserMembershipID(value.userMembershipId);
    this.defaultValuesService.setuserMembershipName(value.userMembershipName);
    this.defaultValuesService.setSchoolID(value.userSchoolId);
    sessionStorage.removeItem(this.defaultValuesService.getUserMembershipType() === "Super Administrator" ? 'superAdminCredentials' : 'adminProfileCredentials');
    if(this.defaultValuesService.getUserMembershipType() === "Super Administrator" ||
     ((this.defaultValuesService.getUserMembershipType() === "School Administrator" || this.defaultValuesService.getUserMembershipType() === "Admin Assistant") && !this.getSuperAdminCredentials())) 
      sessionStorage.removeItem('impersonateButton' );
  }

  setSuperAdminCredentials(superAdminCredentials: object) {
    sessionStorage.setItem("superAdminCredentials", JSON.stringify(superAdminCredentials));
  }
  getSuperAdminCredentials() {
    return JSON.parse(sessionStorage.getItem("superAdminCredentials"));
  }

  setAdminStaffCredentials(adminProfileCredentials: object) {
    sessionStorage.setItem("adminProfileCredentials", JSON.stringify(adminProfileCredentials));
  }
  getAdminStaffCredentials() {
    return JSON.parse(sessionStorage.getItem("adminProfileCredentials"));
  }

  callRolePermissions(impersonate?:any) {
    let rolePermissionListView: RolePermissionListViewModel = new RolePermissionListViewModel();
    rolePermissionListView.permissionList = [];
    this.rollBasedAccessService.getAllRolePermission(rolePermissionListView).subscribe((res: RolePermissionListViewModel) => {
      if (res) {
        if (!res._failure) {
          if (this.defaultValuesService.getIsForImpersonate()) sessionStorage.removeItem('isForImpersonate'); // For manager impersonate
          res.permissionList = this.commonService.checkAndModifyPermissionData(res.permissionList)
          this.defaultValuesService.setPermissionList(res);
          if(impersonate !== 'payment'){  // payment = for just set latest permission value
            this.schoolService.changeSchoolListStatus({ schoolLoaded: false, schoolChanged: false, dataFromUserLogin: true, academicYearChanged: false, academicYearLoaded: false });
            if (this.defaultValuesService.getUserMembershipType() === this.profiles.HomeroomTeacher || this.defaultValuesService.getUserMembershipType() === this.profiles.Teacher) {
              this.router.navigateByUrl("/school/teacher/dashboards").then(() => {
                this.commonService.setUserActivity(true);
              });
            } else if (this.defaultValuesService.getUserMembershipType() === this.profiles.Student) {
              this.router.navigateByUrl("/school/student/dashboards").then(() => {
                this.commonService.setUserActivity(true);
              });
            }
            else if (this.defaultValuesService.getUserMembershipType() === this.profiles.Parent) {
              this.router.navigateByUrl("/school/parent/dashboards").then(() => {
                this.commonService.setUserActivity(true);
              });
            }
            else {
              this.getUserCountForTenant().then(()=>{
                this.router.navigateByUrl("/school/dashboards",{state:{state:true}}).then(() => {
                  this.commonService.setUserActivity(true);
                });
              })
            }
          }
        } else {
          if (this.defaultValuesService.getIsForImpersonate()) this.router.navigateByUrl('/error/impersonation-error?error=api-failure'); // For manager impersonate
        }
      } else {
        if (this.defaultValuesService.getIsForImpersonate()) this.router.navigateByUrl('/error/impersonation-error?error=api-failure'); // For manager impersonate
      }
      if(impersonate === true){     // impersonate === true = for impersonate service
        this.impersonateSubjectForToolBar.next(true);
        this.impersonateSubjectForSelectBar.next(true)
      }   
    });
  }

  getUserCountForTenant() {
    return new Promise((resolve, reject)=>{
      this.subscriptionService.getUSerCount().toPromise().then((data: any) => {
        if (data._failure) {
          this.snackbar.open('' + data.message, '', {
            duration: 10000
          });
        } else {
          this.defaultValuesService.setStudentCount(data.studentCount);
          this.defaultValuesService.setStaffCount(data.staffCount);
        }
        resolve('');
      })
    })
  }
}
