import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[customMaxLength]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomMaxLengthDirective, multi: true }]
})
export class CustomMaxLengthDirective {

  @Input() customMaxLength: number;

  constructor() { }

  validate(control: FormControl): any {
    let value = control.value;
    return (value?.length > this.customMaxLength) ? { customMaxLengthErr: true, requiredLength: this.customMaxLength, currentLength: value?.length } : null;
  }
}