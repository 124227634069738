import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DefaultValuesService } from '../common/default-values.service';
import { AddParentInfoModel } from "../models/parent-info.model";
import { OnlySchoolListModel } from "../models/get-all-school.model";

@Injectable({
    providedIn: 'root'
})
export class ParentPortalService {
    httpOptions: { headers: any; };
    apiUrl: string = environment.apiURL;

    constructor(private defaultValuesService: DefaultValuesService,
        private http: HttpClient) {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
            })
        }
    }


    getStudentListForParent(parentInfo: AddParentInfoModel) {
        parentInfo = this.defaultValuesService.getAllMandatoryVariable(parentInfo);
        parentInfo.parentInfo.schoolId = this.defaultValuesService.getSchoolID();
        parentInfo.parentInfo.tenantId = this.defaultValuesService.getTenantID();
        let apiurl = this.apiUrl + parentInfo._tenantName + "/ParentPortal/getStudentListForParent";
        return this.http.post<AddParentInfoModel>(apiurl, parentInfo, this.httpOptions);
    }

    getAllSchoolsByStudentId(schoolList: OnlySchoolListModel) {
        schoolList = this.defaultValuesService.getAllMandatoryVariable(schoolList);
        let apiurl = this.apiUrl + schoolList._tenantName + "/ParentPortal/getAllSchoolsByStudentId";
        return this.http.post<OnlySchoolListModel>(apiurl, schoolList, this.httpOptions);
    }
}