import { CommonField } from "./common-field.model";


export class SignalRConnectionInfo {
    endpoint: string;
    accessToken: string;
    url: string;
  }

  export class ConnectUserInSignalRModel extends CommonField {
    email: string;
    tenantId: string;
    schoolId: number;
    userMasterId:number;
    signalrConnectionId: string;
    constructor(){
      super();
    }
  }