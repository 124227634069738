import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DefaultValuesService } from '../common/default-values.service';

type applicantFilterInfo = {
  type: string,
  startDate: string,
  endDate: string,
  query?: string,
  archive?: boolean,
  searchKey?: string
}

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {

  private applicantDetails: any;
  private applicantImage;
  private applicantThumbnailImage;
  public cloneApplicantImage;
  public dashboardSearchControl;
  public applicantSearchParams: applicantFilterInfo;

  constructor(
    private defaultValuesService: DefaultValuesService
  ) { }

  setApplicantDetails(applicantInfo: any) {
    this.applicantDetails =  applicantInfo;
  }

  getApplicantDetails() {
    return this.applicantDetails;
  }

  setApplicantImage(imageInBase64) {
    this.applicantImage = imageInBase64;
  }

  getApplicantImage() {
    return this.applicantImage;
  }

  setApplicantThumbnailImage(imageInBase64) {
    this.applicantThumbnailImage = imageInBase64;
  }

  getApplicantThumbnailImage() {
    return this.applicantThumbnailImage;
  }

  setApplicantCloneImage(image) {
    this.cloneApplicantImage = image;
  }

  getApplicantCloneImage() {
    return this.cloneApplicantImage;
  }

  setDashboardSearchControl(status: string) {
    this.dashboardSearchControl = status;
  }

  getDashboardSearchControl() {
    return this.dashboardSearchControl;
  }

  setApplicantSearchParams(filterInfo: applicantFilterInfo) {
    this.applicantSearchParams = filterInfo;
  }

  getApplicantSearchParams() {
    return this.applicantSearchParams;
  }
}
