import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import icClose from '@iconify/icons-ic/close';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultValuesService } from 'src/app/common/default-values.service';
import { StudentService } from 'src/app/services/student.service';
import { ImageCropperService } from '../../../services/image-cropper.service';
import { Permissions, RolePermissionListViewModel } from '../../../models/roll-based-access.model';
import { RollBasedAccessService } from '../../../services/roll-based-access.service';
import { PageRolesPermission } from '../../../common/page-roles-permissions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModuleIdentifier } from '../../../enums/module-identifier.enum';
import { SchoolCreate } from '../../../enums/school-create.enum';

@Component({
  selector: 'vex-student-identification',
  templateUrl: './student-identification.component.html',
  styleUrls: ['./student-identification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentIdentificationComponent implements OnInit {

  icClose = icClose;
  studentInfo: any = {};
  profileImg: string;
  hasProfileImg: boolean = false;
  moduleIdentifier = ModuleIdentifier;
  createMode = SchoolCreate;

  constructor(
    @Inject(MAT_DIALOG_DATA) public studentMasterData: { studentData: any },
    private dialogRef: MatDialogRef<StudentIdentificationComponent>,
    private router: Router,
    public translateService: TranslateService,
    public defaultService: DefaultValuesService,
    private studentService: StudentService,
    private imageCropperService: ImageCropperService,
    private pageRolePermissions: PageRolesPermission,
    private rollBasedAccessService: RollBasedAccessService,
    private snackbar: MatSnackBar,
  ) 
  { 
    this.profileImg = 'assets/img/profilePic.jpg'; 
  }

  ngOnInit(): void {
    this.studentInfo = this.studentMasterData.studentData;
    if (this.studentMasterData.studentData.studentMaster.studentPhoto !== null) {
      this.hasProfileImg = true;
      this.studentService.setStudentCloneImage(this.studentMasterData.studentData.studentMaster.studentPhoto);
      this.profileImg = this.studentService.getStudentCloneImage();
    }
  }

  showStudentDetails(element) {    
    this.imageCropperService.enableUpload({ module: this.moduleIdentifier.STUDENT, upload: true, mode: this.createMode.VIEW });
    this.studentService.setStudentId(element.studentMaster.studentId);
    this.defaultService.setSchoolID(element.studentMaster.schoolId, true);
    this.getPermissionForStudent();
  }

  getPermissionForStudent() {
    let rolePermissionListView: RolePermissionListViewModel = new RolePermissionListViewModel();
    this.rollBasedAccessService.getAllRolePermission(rolePermissionListView).subscribe((res: RolePermissionListViewModel) => {
      if(res._failure){
        
      } else{
        let permittedDetails = this.pageRolePermissions.getPermittedSubCategories('/school/students', res);
       if (permittedDetails.length) {
            this.studentService.setCategoryId(0);
            this.studentService.setCategoryTitle(permittedDetails[0].title);
            // this.router.navigate([permittedDetails[0].path], {state: {type: 2}});
            this.router.navigate([permittedDetails[0].path], { state: { permissions: res, type: SchoolCreate.VIEW } });
            this.dialogRef.close();
        } else {
          this.defaultService.setSchoolID(undefined);
          this.snackbar.open('Student did not have permission to view details.', '', {
            duration: 10000
          });
        }
      }
    });
  }

}
