export enum currencyEnum {
  USD = 'United States Dollar',
  EUR = 'Euro',
  GBP = 'British Pound',
  AUD = 'Australian Dollar',
  BRL = 'Brazilian Real',
  CAD = 'Canadian Dollar',
  CNY = 'Chinese Yuan',
  EGP = 'Egyptian Pound',
  HKD = 'Hong Kong Dollar',
  INR = 'Indian Rupee',
  IDR = 'Indonesian Rupiah',
  JPY = 'Japanese Yen',
  MYR = 'Malaysian Ringgit',
  MXN = 'Mexican Peso',
  NZD = 'New Zealand Dollar',
  NOK = 'Norwegian Krone',
  PHP = 'Philippine Peso',
  SGD = 'Singapore Dollar',
  SEK = 'Swedish Krona',
  CHF = 'Swiss Franc',
  THB = 'Thai Baht',
  AED = 'UAE Dirham',
  VND = 'Vietnamese Dong',
  NGN = 'Nigerian Naira',
  KES = 'Kenyan Shilling',
  AFN = 'Afghan Afghani',
  ALL = 'Albanian Lek',
  AMD = 'Armenian Dram',
  ANG = 'Netherlands Antillean Guilder',
  ARS = 'Argentine Peso',
  AWG = 'Aruban Florin',
  AZN = 'Azerbaijani Manat',
  BAM = 'Bosnia-Herzegovina Convertible Mark',
  BDT = 'Bangladeshi Taka',
  BGN = 'Bulgarian Lev',
  BND = 'Brunei Dollar',
  BOB = 'Bolivian Boliviano',
  BWP = 'Botswanan Pula',
  BYN = 'Belarusian Ruble',
  BZD = 'Belize Dollar',
  CLP = 'Chilean Peso',
  COP = 'Colombian Peso',
  CRC = 'Costa Rican Colón',
  CZK = 'Czech Koruna',
  CVE = 'Cape Verdean Escudo',
  DKK = 'Danish Krone',
  DOP = 'Dominican Peso',
  DZD = 'Algerian Dinar',
  FJD = 'Fijian Dollar',
  GEL = 'Georgian Lari',
  HNL = 'Honduran Lempira',
  HUF = 'Hungarian Forint',
  ILS = 'Israeli New Sheqel',
  ISK = 'Icelandic Króna',
  JMD = 'Jamaican Dollar',
  KGS = 'Kyrgystani Som',
  KRW = 'South Korean Won',
  KYD = 'Cayman Islands Dollar',
  KZT = 'Kazakhstani Tenge',
  TZS = 'Tanzanian Shilling',
  LAK = 'Laotian Kip',
  LBP = 'Lebanese Pound',
  LKR = 'Sri Lankan Rupee',
  MAD = 'Moroccan Dirham',
  MDL = 'Moldovan Leu',
  MKD = 'Macedonian Denar',
  MMK = 'Myanma Kyat',
  MOP = 'Macanese Pataca',
  MUR = 'Mauritian Rupee',
  MVR = 'Maldivian Rufiyaa',
  NAD = 'Namibian Dollar',
  NIO = 'Nicaraguan Córdoba',
  NPR = 'Nepalese Rupee',
  PAB = 'Panamanian Balboa',
  PEN = 'Peruvian Nuevo Sol',
  PKR = 'Pakistani Rupee',
  PLN = 'Polish Zloty',
  PYG = 'Paraguayan Guarani',
  QAR = 'Qatari Rial',
  RON = 'Romanian Leu',
  RSD = 'Serbian Dinar',
  RUB = 'Russian Ruble',
  SAR = 'Saudi Riyal',
  SCR = 'Seychellois Rupee',
  TTD = 'Trinidad and Tobago Dollar',
  TWD = 'New Taiwan Dollar',
  UAH = 'Ukrainian Hryvnia',
  UGX = 'Ugandan Shilling',
  UYU = 'Uruguayan Peso',
  UZS = 'Uzbekistan Som',
  WST = 'Samoan Tala',
  XCD = 'East Caribbean Dollar',
  XOF = 'CFA Franc BCEAO',
  XPF = 'CFP Franc',
  YER = 'Yemeni Rial',
  ZAR = 'South African Rand'
}

export enum currencySymbol {
  USD = '$',
  AUD = 'A$',
  BRL = 'R$',
  GBP = '£',
  CAD = 'CA$',
  CNY = 'CN¥',
  EGP = 'EGP',
  EUR = '€',
  HKD = 'HK$',
  INR = '₹',
  IDR = 'Rp',
  JPY = '¥',
  MYR = 'MYR',
  MXN = 'MX$',
  NZD = 'NZ$',
  NOK = 'NOK',
  PHP = '₱',
  SGD = 'SGD',
  SEK = 'SEK',
  CHF = 'CHF',
  THB = 'THB',
  AED = 'AED',
  VND = '₫',
  NGN = 'NGN',
  KES = 'KES',
  AFN = '؋;',
  ALL = 'ALL',
  AMD = 'Դ',
  ANG = 'ANG',
  ARS = 'ARS',
  AWG = 'AWG',
  AZN = '₼',
  BAM = 'KM',
  BDT = '৳',
  BGN = 'лв',
  BND = 'B$',
  BOB = 'Bs.',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZ$',
  CLP = 'CLP',
  COP = 'COL$',
  CRC = '₡',
  CZK = 'Kč',
  CVE = 'CVE',
  DKK = 'kr.',
  DOP = 'RD$',
  DZD = 'دج',
  FJD = 'FJ$',
  GEL = 'GEL',
  HNL = 'L',
  HUF = 'Ft',
  ILS = '₪',
  ISK = 'Íkr',
  JMD = 'JA$',
  KGS = 'лв',
  KRW = '₩',
  KYD = 'CI$',
  KZT = '₸',
  TZS = 'TZS',
  LAK = '₭',
  LBP = 'L£',
  LKR = 'LKR',
  MAD = 'د.م.',
  MDL = 'MDL',
  MKD = 'ден',
  MMK = 'MMK',
  MOP = 'MOP',
  MUR = 'MUR',
  MVR = 'MVR',
  NAD = 'N$',
  NIO = 'C$',
  NPR = 'NPR',
  PAB = '฿',
  PEN = 'S/.',
  PKR = 'PKR',
  PLN = 'zł',
  PYG = '₲',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'РСД',
  RUB = '₽',
  SAR = 'SAR',
  SCR = 'SCR',
  TTD = 'TT$',
  TWD = 'NT$',
  UAH = '₴',
  UGX = 'UGX',
  UYU = '$U',
  UZS = 'лв',
  WST = 'WS$',
  XCD = 'EC$',
  XOF = 'CFA',
  XPF = 'F',
  YER = 'YER',
  ZAR = 'R'
}