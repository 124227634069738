import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'EmtyValueCheckPipe'
})
export class EmtyValueCheckPipe implements PipeTransform {

  transform(value: any,type?: string): string {
    if(type==="0.00"){
      if(value!==null && value!==undefined){
        if(value.trim()!==""){
          return value.trim();
        }else{
          return "0.00";
        }
      }
      else{
        return "0.00"
      }
    }
    else{
      if (value !== null && value !== undefined) {
        if (typeof value === 'number') {
          if (value !== null && value !== undefined) {
            return value.toString();
          } else {
            return '-';
          }
        } else {
          if (value.trim() !== "") {
            return value.trim();
          } else {
            return "-";
          }
        }
      }
      else{
        return "-"
      }
    }
  }
}
