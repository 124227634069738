export const weeks = [
    { name: 'Sunday', id: 0 },
    { name: 'Monday', id: 1 },
    { name: 'Tuesday', id: 2 },
    { name: 'Wednesday', id: 3 },
    { name: 'Thursday', id: 4 },
    { name: 'Friday', id: 5 },
    { name: 'Saturday', id: 6 }
];

export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const color = [
    {
        label: 'label-pink',
        backgroundColor: 'bg-pink'
    },
    {
        label: 'label-cyan',
        backgroundColor: 'bg-cyan'
    },
    {
        label: 'label-teal',
        backgroundColor: 'bg-teal'
    },
    {
        label: 'label-teal',
        backgroundColor: 'bg-teal'
    },
    {
        label: 'label-cyan',
        backgroundColor: 'bg-cyan'
    },
    {
        label: 'label-teal',
        backgroundColor: 'bg-teal'
    },
    {
        label: 'label-teal',
        backgroundColor: 'bg-teal'
    }
];

export const reportCardType = [
  {
    reportCardType: 'default',
    reportCardTypeName: 'Default'
  },
  {
    reportCardType: 'chuuk',
    reportCardTypeName: 'Chuuk'
  },
  {
    reportCardType: 'kosrae',
    reportCardTypeName: 'Kosrae'
  },
  {
    reportCardType: 'pohnpei',
    reportCardTypeName: 'Pohnpei'
  },
  {
    reportCardType: 'yap',
    reportCardTypeName: 'Yap'
  },
];

export const uniqueColors = [
    {
        id: null,
        backgroundColor: 'bg-red',
        textColorInHex:'#f44336',
        textColor: 'text-red',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-green',
        textColorInHex:'#4caf50',
        textColor: 'text-green',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-amber',
        textColor: 'text-amber',
        textColorInHex:'#ffc107',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-pink',
        textColor: 'text-pink',
        textColorInHex:'#e91e63',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-deep-orange',
        textColor: 'text-orange',
        textColorInHex:'#ff5722',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-purple',
        textColor: 'text-purple',
        textColorInHex:'#9c27b0',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-teal',
        textColor: 'text-teal',
        textColorInHex:'#009688',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-deep-purple',
        textColor: 'text-purple',
        textColorInHex:'#673ab7',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-cyan',
        textColor: 'text-cyan',
        textColorInHex:'#00bcd4',
        used: false
    },
    {
        id: null,
        backgroundColor: 'bg-gray',
        textColor: 'text-gray',
        textColorInHex:'#9e9e9e',
        used: false
    }
];

export const classColor = [
  {
      text: 'text-gray',
      borderColor: 'border-gray'
  },
  {
      text: 'text-cyan',
      borderColor: 'border-cyan'
  },
  {
      text: 'text-teal',
      borderColor: 'border-teal'
  },
  {
      text: 'text-green',
      borderColor: 'border-green'
  },
  {
      text: 'text-red',
      borderColor: 'border-red'
  },
  {
      text: 'text-orange',
      borderColor: 'border-orange'
  },
  {
      text: 'text-purple',
      borderColor: 'border-purple'
  },
  {
      text: 'text-deep-purple',
      borderColor: 'border-deep-purple'
  },
  {
      text: 'text-pink',
      borderColor: 'border-pink'
  },
  {
      text: 'text-primary',
      borderColor: 'border-primary'
  },
  {
      text: 'text-warn',
      borderColor: 'border-warn'
  }
];


export const permissions = {
    "permissionList": [
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 1,
          "permissionGroupName": "Dashboard",
          "shortName": "db",
          "isActive": true,
          "isSystem": true,
          "title": "Dashboard",
          "icon": "icLayers",
          "iconType": "class",
          "sortOrder": 1,
          "type": "link",
          "path": "/school/dashboards",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 1,
              "membershipId": 1,
              "permissionGroupId": 1,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 2,
          "permissionGroupName": "School",
          "shortName": "schl",
          "isActive": true,
          "isSystem": true,
          "title": "School",
          "icon": "icschool",
          "iconType": "class",
          "sortOrder": 2,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 1,
              "permissionGroupId": 2,
              "permissionCategoryName": "School Information",
              "shortCode": "si",
              "path": "/school/schoolinfo",
              "title": "School Information",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 1,
                  "permissionSubcategoryId": 1,
                  "permissionGroupId": 2,
                  "permissionSubcategoryName": "General Information",
                  "shortCode": "gi",
                  "path": "/school/schoolinfo/generalinfo",
                  "title": "General Information",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 4,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 1,
                      "canView": false,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 1,
                  "permissionSubcategoryId": 2,
                  "permissionGroupId": 2,
                  "permissionSubcategoryName": "WASH Information",
                  "shortCode": "wi",
                  "path": "/school/schoolinfo/washinfo",
                  "title": "WASH Information",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 5,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 2,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 1,
                  "permissionSubcategoryId": 57,
                  "permissionGroupId": 2,
                  "permissionSubcategoryName": "Custom Category",
                  "shortCode": null,
                  "path": "/school/schoolinfo/custom/custom-category",
                  "title": "Custom Category",
                  "type": null,
                  "isActive": null,
                  "enableView": false,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 468,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 57,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 3,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 1,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 2,
              "permissionGroupId": 2,
              "permissionCategoryName": "Marking Periods",
              "shortCode": "mp",
              "path": "/school/marking-periods",
              "title": "Marking Periods",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 6,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 2,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 3,
              "permissionGroupId": 2,
              "permissionCategoryName": "Calendars",
              "shortCode": "cal",
              "path": "/school/schoolcalendars",
              "title": "Calendars",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 7,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 3,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 4,
              "permissionGroupId": 2,
              "permissionCategoryName": "Notices",
              "shortCode": "not",
              "path": "/school/notices",
              "title": "Notices",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 8,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 4,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 2,
              "membershipId": 1,
              "permissionGroupId": 2,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 3,
          "permissionGroupName": "Students",
          "shortName": "stud",
          "isActive": true,
          "isSystem": true,
          "title": "Students",
          "icon": "icstudents",
          "iconType": "class",
          "sortOrder": 3,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 5,
              "permissionGroupId": 3,
              "permissionCategoryName": "Student Information",
              "shortCode": "si",
              "path": "/school/students",
              "title": "Student Information",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 3,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "General Info",
                  "shortCode": "gi",
                  "path": "/school/students/student-generalinfo",
                  "title": "General Info",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 11,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 3,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 4,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Enrollment Info",
                  "shortCode": "ei",
                  "path": "/school/students/student-enrollmentinfo",
                  "title": "School & Enrollment Info",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 12,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 4,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 5,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Address & Contact",
                  "shortCode": "ac",
                  "path": "/school/students/student-address-contact",
                  "title": "Address & Contact",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 13,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 5,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 6,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Family Info",
                  "shortCode": "fi",
                  "path": "/school/students/student-familyinfo",
                  "title": "Family Info",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 14,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 6,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 7,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Medical Info",
                  "shortCode": "mi",
                  "path": "/school/students/student-medicalinfo",
                  "title": "Medical Info",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 15,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 7,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 8,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Comments",
                  "shortCode": "cmnts",
                  "path": "/school/students/student-comments",
                  "title": "Comments",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 16,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 8,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 9,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Documents",
                  "shortCode": "doc",
                  "path": "/school/students/student-documents",
                  "title": "Documents",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 17,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 9,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 10,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Course Schedule",
                  "shortCode": "cs",
                  "path": "/school/students/student-course-schedule",
                  "title": "Course Schedule",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 18,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 10,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 11,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Attendance",
                  "shortCode": "att",
                  "path": "/school/students/student-attendance",
                  "title": "Attendance",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 19,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 11,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 12,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Grades",
                  "shortCode": "grd",
                  "path": "",
                  "title": "Grades",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 20,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 12,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": 56,
                  "permissionGroupId": 3,
                  "permissionSubcategoryName": "Test Category",
                  "shortCode": null,
                  "path": null,
                  "title": "Test Category",
                  "type": null,
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 467,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 56,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 10,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 5,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 6,
              "permissionGroupId": 3,
              "permissionCategoryName": "Group Assign Student Info",
              "shortCode": "gasi",
              "path": "disabled",
              "title": "Group Assign Student Info",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 21,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 6,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 7,
              "permissionGroupId": 3,
              "permissionCategoryName": "Student Re Enroll",
              "shortCode": "sre",
              "path": "/school/student/student-re-enroll",
              "title": "Student Re Enroll",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 22,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 7,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 9,
              "membershipId": 1,
              "permissionGroupId": 3,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 4,
          "permissionGroupName": "Parents",
          "shortName": "prnt",
          "isActive": true,
          "isSystem": true,
          "title": "Parents",
          "icon": "icparents",
          "iconType": "class",
          "sortOrder": 4,
          "type": "link",
          "path": "/school/parents",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 8,
              "permissionGroupId": 4,
              "permissionCategoryName": "General Info",
              "shortCode": "gi",
              "path": "/school/parents/parent-generalinfo",
              "title": "General Info",
              "type": "",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 24,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 8,
                  "permissionSubcategoryId": null,
                  "canView": false,
                  "canAdd": false,
                  "canEdit": false,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 9,
              "permissionGroupId": 4,
              "permissionCategoryName": "Address info",
              "shortCode": "ai",
              "path": "/school/parents/parent-addressinfo",
              "title": "Address info",
              "type": "",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 25,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 9,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 23,
              "membershipId": 1,
              "permissionGroupId": 4,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 5,
          "permissionGroupName": "Staff",
          "shortName": "stf",
          "isActive": true,
          "isSystem": true,
          "title": "Staff",
          "icon": "icusers",
          "iconType": "class",
          "sortOrder": 5,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 10,
              "permissionGroupId": 5,
              "permissionCategoryName": "Staff Info",
              "shortCode": "si",
              "path": "/school/staff",
              "title": "Staff Info",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 10,
                  "permissionSubcategoryId": 13,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "General Info",
                  "shortCode": "gi",
                  "path": "/school/staff/staff-generalinfo",
                  "title": "General Info",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 28,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 13,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 10,
                  "permissionSubcategoryId": 14,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "School Info",
                  "shortCode": "si",
                  "path": "/school/staff/staff-schoolinfo",
                  "title": "School Info",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 29,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 14,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 10,
                  "permissionSubcategoryId": 15,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Address & Contact",
                  "shortCode": "a&c",
                  "path": "/school/staff/staff-addressinfo",
                  "title": "Address & Contact",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 30,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 15,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 10,
                  "permissionSubcategoryId": 16,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Certification Info",
                  "shortCode": "ci",
                  "path": "/school/staff/staff-certificationinfo",
                  "title": "Certification Info",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 31,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 16,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 27,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 10,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 11,
              "permissionGroupId": 5,
              "permissionCategoryName": "Teacher Functions",
              "shortCode": "tf",
              "path": "/school/staff/teacher-functions/input-final-grade",
              "title": "Teacher Functions",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 11,
                  "permissionSubcategoryId": 50,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Input Final Grade",
                  "shortCode": "ifg",
                  "path": "",
                  "title": "Input Final Grade",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 424,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 50,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 11,
                  "permissionSubcategoryId": 51,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Input Effort Grade",
                  "shortCode": "ieg",
                  "path": "",
                  "title": "Input Effort Grade",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 425,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 51,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 11,
                  "permissionSubcategoryId": 52,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Gradebook Grades",
                  "shortCode": "gg",
                  "path": "",
                  "title": "Gradebook Grades",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 426,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 52,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 11,
                  "permissionSubcategoryId": 53,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Progress Reports",
                  "shortCode": "pr",
                  "path": "",
                  "title": "Progress Reports",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 427,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 53,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 11,
                  "permissionSubcategoryId": 54,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Take Attendance",
                  "shortCode": "ta",
                  "path": "",
                  "title": "Take Attendance",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 428,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 54,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 11,
                  "permissionSubcategoryId": 55,
                  "permissionGroupId": 5,
                  "permissionSubcategoryName": "Missing Attendance",
                  "shortCode": "ma",
                  "path": "",
                  "title": "Missing Attendance",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 429,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 55,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 32,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 11,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 26,
              "membershipId": 1,
              "permissionGroupId": 5,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 6,
          "permissionGroupName": "Courses",
          "shortName": "cou",
          "isActive": true,
          "isSystem": true,
          "title": "Courses",
          "icon": "icbook",
          "iconType": "class",
          "sortOrder": 6,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 12,
              "permissionGroupId": 6,
              "permissionCategoryName": "Course Manager",
              "shortCode": "cm",
              "path": "/school/course-manager",
              "title": "Course Manager",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 34,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 12,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 13,
              "permissionGroupId": 6,
              "permissionCategoryName": "Course Catalog",
              "shortCode": "cc",
              "path": "disabled",
              "title": "Course Catalog",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 35,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 13,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 33,
              "membershipId": 1,
              "permissionGroupId": 6,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 7,
          "permissionGroupName": "Scheduling",
          "shortName": "sch",
          "isActive": true,
          "isSystem": true,
          "title": "Scheduling",
          "icon": "icschedule",
          "iconType": "class",
          "sortOrder": 7,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 14,
              "permissionGroupId": 7,
              "permissionCategoryName": "Teacher Reassignment",
              "shortCode": "tr",
              "path": "/school/teacher-reassignment",
              "title": "Teacher Reassignment",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 36,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 14,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 15,
              "permissionGroupId": 7,
              "permissionCategoryName": "Schedule Students",
              "shortCode": "ss",
              "path": "/school/schedule-student",
              "title": "Schedule Students",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 38,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 15,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 16,
              "permissionGroupId": 7,
              "permissionCategoryName": "Schedule Teacher",
              "shortCode": "st",
              "path": "/school/schedule-teacher",
              "title": "Schedule Teacher",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 39,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 16,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 17,
              "permissionGroupId": 7,
              "permissionCategoryName": "Run Scheduler",
              "shortCode": "rs",
              "path": "disabled",
              "title": "Run Scheduler",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 40,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 17,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 18,
              "permissionGroupId": 7,
              "permissionCategoryName": "Group Drop",
              "shortCode": "gd",
              "path": "/school/group-drop",
              "title": "Group Drop",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 41,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 18,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 37,
              "membershipId": 1,
              "permissionGroupId": 7,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 8,
          "permissionGroupName": "Grades",
          "shortName": "grd",
          "isActive": true,
          "isSystem": true,
          "title": "Grades",
          "icon": "icgrade",
          "iconType": "class",
          "sortOrder": 8,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 19,
              "permissionGroupId": 8,
              "permissionCategoryName": "Progress Reports",
              "shortCode": "pr",
              "path": "disabled",
              "title": "Progress Reports",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 43,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 19,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 33,
              "permissionGroupId": 8,
              "permissionCategoryName": "Report Cards",
              "shortCode": "rc",
              "path": "/school/grades/report-cards",
              "title": "Report Cards",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 455,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 33,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 34,
              "permissionGroupId": 8,
              "permissionCategoryName": "Transcripts",
              "shortCode": "trns",
              "path": "/school/grades/transcripts",
              "title": "Transcripts",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 456,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 34,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 42,
              "membershipId": 1,
              "permissionGroupId": 8,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 9,
          "permissionGroupName": "Attendance",
          "shortName": "atdns",
          "isActive": true,
          "isSystem": true,
          "title": "Attendance",
          "icon": "icattendance",
          "iconType": "class",
          "sortOrder": 9,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 20,
              "permissionGroupId": 9,
              "permissionCategoryName": "Administration",
              "shortCode": "adms",
              "path": "/school/attendance/administration",
              "title": "Administration",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 45,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 20,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 30,
              "permissionGroupId": 9,
              "permissionCategoryName": "Add Absences",
              "shortCode": "adabs",
              "path": "/school/attendance/add-absences",
              "title": "Add Absences",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 442,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 30,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 31,
              "permissionGroupId": 9,
              "permissionCategoryName": "Recalculate Daily Attendance",
              "shortCode": "rda",
              "path": "/school/attendance/recalculate-daily-attendance",
              "title": "Recalculate Daily Attendance",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 449,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 31,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 32,
              "permissionGroupId": 9,
              "permissionCategoryName": "Missing Attendance",
              "shortCode": "ma",
              "path": "/school/attendance/missing-attendance",
              "title": "Missing Attendance",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 450,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 32,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 44,
              "membershipId": 1,
              "permissionGroupId": 9,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 11,
          "permissionGroupName": "Reports",
          "shortName": "rpt",
          "isActive": true,
          "isSystem": true,
          "title": "Reports",
          "icon": "icreports",
          "iconType": "class",
          "sortOrder": 11,
          "type": "link",
          "path": "disabled",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 48,
              "membershipId": 1,
              "permissionGroupId": 11,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 12,
          "permissionGroupName": "Settings",
          "shortName": "stng",
          "isActive": true,
          "isSystem": true,
          "title": "Settings",
          "icon": "icsettings",
          "iconType": "class",
          "sortOrder": 12,
          "type": "link",
          "path": "/school/settings",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 22,
              "permissionGroupId": 12,
              "permissionCategoryName": "School",
              "shortCode": "schl",
              "path": "/school/settings/school-settings",
              "title": "School",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": 17,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Periods",
                  "shortCode": "prd",
                  "path": "",
                  "title": "Periods",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 51,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 17,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": 18,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Grade Levels",
                  "shortCode": "gl",
                  "path": "",
                  "title": "Grade Levels",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 52,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 18,
                      "canView": false,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": 19,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Sections",
                  "shortCode": "sec",
                  "path": "",
                  "title": "Sections",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 53,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 19,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": 20,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Rooms",
                  "shortCode": "rms",
                  "path": "",
                  "title": "Rooms",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 54,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 20,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": 21,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "School Fields",
                  "shortCode": "sf",
                  "path": "",
                  "title": "School Fields",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 55,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 21,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": 22,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Hierarchy",
                  "shortCode": "hrcy",
                  "path": "",
                  "title": "Hierarchy",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 56,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 22,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": 23,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Preference",
                  "shortCode": "prfns",
                  "path": "",
                  "title": "Preference",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 57,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 23,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 50,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 22,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 23,
              "permissionGroupId": 12,
              "permissionCategoryName": "Student",
              "shortCode": "stud",
              "path": "/school/settings/student-settings",
              "title": "Student",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 23,
                  "permissionSubcategoryId": 24,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Student Fields",
                  "shortCode": "sf",
                  "path": "",
                  "title": "Student Fields",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 59,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 24,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 23,
                  "permissionSubcategoryId": 25,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Enrollment Codes",
                  "shortCode": "ec",
                  "path": "",
                  "title": "Enrollment Codes",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 60,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 25,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 23,
                  "permissionSubcategoryId": 45,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Student Bulk Data Import",
                  "shortCode": "stubdi",
                  "path": "",
                  "title": "Student Bulk Data Import",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 409,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 45,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 58,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 23,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 24,
              "permissionGroupId": 12,
              "permissionCategoryName": "Staff",
              "shortCode": "stf",
              "path": "/school/settings/staff-settings",
              "title": "Staff",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 24,
                  "permissionSubcategoryId": 26,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Staff Fields",
                  "shortCode": "sf",
                  "path": "",
                  "title": "Staff Fields",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 62,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 26,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 24,
                  "permissionSubcategoryId": 46,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Staff Bulk Data Import",
                  "shortCode": "stabdi",
                  "path": "",
                  "title": "Staff Bulk Data Import",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 410,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 46,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 61,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 24,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 25,
              "permissionGroupId": 12,
              "permissionCategoryName": "Attendance",
              "shortCode": "atten",
              "path": "/school/settings/attendance-settings",
              "title": "Attendance",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 25,
                  "permissionSubcategoryId": 27,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Attendance Codes",
                  "shortCode": "ac",
                  "path": "",
                  "title": "Attendance Codes",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 64,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 27,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 63,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 25,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 26,
              "permissionGroupId": 12,
              "permissionCategoryName": "Grades",
              "shortCode": "grds",
              "path": "/school/settings/grade-settings",
              "title": "Grades",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 26,
                  "permissionSubcategoryId": 28,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Standard Grade Setup",
                  "shortCode": "sgs",
                  "path": "",
                  "title": "Standard Grade Setup",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 66,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 28,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 26,
                  "permissionSubcategoryId": 29,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Effort Grade Setup",
                  "shortCode": "egs",
                  "path": "",
                  "title": "Effort Grade Setup",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 67,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 29,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 26,
                  "permissionSubcategoryId": 30,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Report Card Grades",
                  "shortCode": "rcg",
                  "path": "",
                  "title": "Report Card Grades",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 68,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 30,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 26,
                  "permissionSubcategoryId": 31,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Honor Roll Setup",
                  "shortCode": "hrs",
                  "path": "",
                  "title": "Honor Roll Setup",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 69,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 31,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 26,
                  "permissionSubcategoryId": 47,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Report Card Comments",
                  "shortCode": "rcc",
                  "path": "",
                  "title": "Report Card Comments",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 411,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 47,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 65,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 26,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 27,
              "permissionGroupId": 12,
              "permissionCategoryName": "Administration",
              "shortCode": "adms",
              "path": "/school/settings/administration-settings",
              "title": "Administration",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 27,
                  "permissionSubcategoryId": 32,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Profiles & Permissions",
                  "shortCode": "p&p",
                  "path": "",
                  "title": "Profiles & Permissions",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 71,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 32,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 70,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 27,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            },
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 28,
              "permissionGroupId": 12,
              "permissionCategoryName": "List of Values",
              "shortCode": "lov",
              "path": "/school/settings/lov-settings",
              "title": "List of Values",
              "type": "link",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 33,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "School Level",
                  "shortCode": "sl",
                  "path": "",
                  "title": "School Level",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 73,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 33,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 34,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "School Classification",
                  "shortCode": "scl",
                  "path": "",
                  "title": "School Classification",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 74,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 34,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 35,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Countries",
                  "shortCode": "cntry",
                  "path": "",
                  "title": "Countries",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 75,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 35,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 36,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Female Toilet Type",
                  "shortCode": "fty",
                  "path": "",
                  "title": "Female Toilet Type",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 76,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 36,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 37,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Female Toilet Accessibility",
                  "shortCode": "fta",
                  "path": "",
                  "title": "Female Toilet Accessibility",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 77,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 37,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 38,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Male Toilet Type",
                  "shortCode": "mtt",
                  "path": "",
                  "title": "Male Toilet Type",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 78,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 38,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 39,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Male Toilet Accessibility",
                  "shortCode": "mta",
                  "path": "",
                  "title": "Male Toilet Accessibility",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 79,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 39,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 40,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Common Toilet Type",
                  "shortCode": "ctt",
                  "path": "",
                  "title": "Common Toilet Type",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 80,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 40,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 41,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Common Toilet Accessibility",
                  "shortCode": "cta",
                  "path": "",
                  "title": "Common Toilet Accessibility",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 81,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 41,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 42,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Race",
                  "shortCode": "rce",
                  "path": "",
                  "title": "Race",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 82,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 42,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 43,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Ethnicity",
                  "shortCode": "ecty",
                  "path": "",
                  "title": "Ethnicity",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 83,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 43,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                },
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": 44,
                  "permissionGroupId": 12,
                  "permissionSubcategoryName": "Language",
                  "shortCode": "lng",
                  "path": "",
                  "title": "Language",
                  "type": "",
                  "isActive": null,
                  "enableView": true,
                  "enableAdd": true,
                  "enableEdit": true,
                  "enableDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "permissionCategory": null,
                  "rolePermission": [
                    {
                      "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                      "schoolId": 1,
                      "rolePermissionId": 84,
                      "membershipId": 1,
                      "permissionGroupId": null,
                      "permissionCategoryId": null,
                      "permissionSubcategoryId": 44,
                      "canView": true,
                      "canAdd": true,
                      "canEdit": true,
                      "canDelete": true,
                      "createdBy": null,
                      "createdOn": null,
                      "updatedBy": null,
                      "updatedOn": null,
                      "membership": null,
                      "permissionGroup": null,
                      "permissionCategory": null,
                      "permissionSubcategory": null
                    }
                  ]
                }
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 72,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 28,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 49,
              "membershipId": 1,
              "permissionGroupId": 12,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      },
      {
        "permissionGroup": {
          "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
          "schoolId": 1,
          "permissionGroupId": 13,
          "permissionGroupName": "Tools",
          "shortName": "tls",
          "isActive": true,
          "isSystem": true,
          "title": "Tools",
          "icon": "ictools",
          "iconType": "class",
          "sortOrder": 13,
          "type": "sub",
          "path": "",
          "badgeType": "",
          "badgeValue": "",
          "active": true,
          "schoolMaster": null,
          "permissionCategory": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "permissionCategoryId": 29,
              "permissionGroupId": 13,
              "permissionCategoryName": "Access Log",
              "shortCode": "acs lg",
              "path": "",
              "title": "Access Log",
              "type": "",
              "isActive": null,
              "enableView": true,
              "enableAdd": true,
              "enableEdit": true,
              "enableDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "permissionGroup": null,
              "permissionSubcategory": [
                
              ],
              "rolePermission": [
                {
                  "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
                  "schoolId": 1,
                  "rolePermissionId": 86,
                  "membershipId": 1,
                  "permissionGroupId": null,
                  "permissionCategoryId": 29,
                  "permissionSubcategoryId": null,
                  "canView": true,
                  "canAdd": true,
                  "canEdit": true,
                  "canDelete": true,
                  "createdBy": null,
                  "createdOn": null,
                  "updatedBy": null,
                  "updatedOn": null,
                  "membership": null,
                  "permissionGroup": null,
                  "permissionCategory": null,
                  "permissionSubcategory": null
                }
              ]
            }
          ],
          "rolePermission": [
            {
              "tenantId": "1e93c7bf-0fae-42bb-9e09-a1cedc8c0355",
              "schoolId": 1,
              "rolePermissionId": 85,
              "membershipId": 1,
              "permissionGroupId": 13,
              "permissionCategoryId": null,
              "permissionSubcategoryId": null,
              "canView": true,
              "canAdd": true,
              "canEdit": true,
              "canDelete": true,
              "createdBy": null,
              "createdOn": null,
              "updatedBy": null,
              "updatedOn": null,
              "membership": null,
              "permissionGroup": null,
              "permissionCategory": null,
              "permissionSubcategory": null
            }
          ]
        },
        "_userName": null,
        "_tenantName": null,
        "_token": null,
        "_tokenExpiry": "0001-01-01T00:00:00+00:00",
        "_failure": false,
        "_message": null
      }
    ]
  }




