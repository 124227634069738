export enum Profiles{
    SuperAdmin=1,
    SchoolAdmin,
    AdminAssitant,
    Teacher,
    HomeroomTeacher,
    Parent,
    Student
 }

 export enum ProfilesTypes {
    SuperAdmin='Super Administrator',
    SchoolAdmin='School Administrator',
    AdminAssitant='Admin Assistant',
    Teacher='Teacher',
    HomeroomTeacher='Homeroom Teacher',
    Parent='Parent',
    Student='Student'
    
}

export enum ProfilesName {
    Student = 'Student',
    Parent = 'Parent',
    Staff = 'Staff'
}