import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { DefaultValuesService } from './default-values.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { PlanUpgradeComponent } from './plan-upgrade/plan-upgrade.component';

@Injectable({ providedIn: 'root' })



export class SubscriptionGuard implements CanActivate {
    
    constructor(
        private router: Router,
        private defaultValuesService: DefaultValuesService,
        private locationStrategy: LocationStrategy,
        private dialog: MatDialog
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let permissions = this.defaultValuesService.getPermissionList().permissionList;
        let finalList = [];

        if(state.url === '/school/inactive-module'){
          this.dialog.open(PlanUpgradeComponent, {
            width: '500px',
            disableClose: true
          });
          return false;
        } else {
          finalList = permissions.filter(function (item) {
            return item.permissionGroup.path == state.url;
          });          
        
          if(finalList.length > 0 && finalList[0].permissionGroup.active === false){
            this.dialog.open(PlanUpgradeComponent, {
              width: '500px',
              disableClose: true
            });
            return false;
          } else {
            permissions.forEach(element => {
							element.permissionGroup.permissionCategory.forEach((item, index) => {
								if(item.path === state.url && item.isAccess === false){
									this.dialog.open(PlanUpgradeComponent, {
										width: '500px',
										disableClose: true
									});
									return false;
								} else {
									item.permissionSubcategory.forEach((i, index) => {
										if(i.path === state.url && i.isAccess === false){
											this.dialog.open(PlanUpgradeComponent, {
												width: '500px',
												disableClose: true
											});
											return false;
										}
									})
								}
							})
						});
						
          }
        }

        if (this.defaultValuesService.checkSubscriptionExpiredOrNot()) {
        // this.router.navigate(['/school', 'my-subscription', 'subscription-plan']);
        this.defaultValuesService.checkProfileAndRedirectToDashboard(true).then(()=>{
          this.dialog.open(PlanUpgradeComponent, {
            width: '500px',
            disableClose: true
          });
        });
          return false;
        }
        return true;
      }
}